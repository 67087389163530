require("material-components-web-elm/dist/material-components-web-elm.js");
require("material-components-web-elm/dist/material-components-web-elm.css");

import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

var app = Elm.Main.init({
  node: document.getElementById('root')
});

var storageKey = "loginData";
app.ports.save.subscribe(function(value) {
  localStorage.setItem(storageKey, value);
});
app.ports.doLoad.subscribe(function() {
  app.ports.load.send(
      localStorage.getItem(storageKey) || ""
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
