(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.dv.bf === region.ek.bf)
	{
		return 'on line ' + region.dv.bf;
	}
	return 'on lines ' + region.dv.bf + ' through ' + region.ek.bf;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gV,
		impl.h3,
		impl.hD,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ag: func(record.ag),
		dw: record.dw,
		dc: record.dc
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ag;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dw;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dc) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gV,
		impl.h3,
		impl.hD,
		function(sendToApp, initialModel) {
			var view = impl.h9;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gV,
		impl.h3,
		impl.hD,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dn && impl.dn(sendToApp)
			var view = impl.h9;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cn);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hS) && (_VirtualDom_doc.title = title = doc.hS);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.hd;
	var onUrlRequest = impl.he;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dn: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.fe === next.fe
							&& curr.ey === next.ey
							&& curr.e5.a === next.e5.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gV: function(flags)
		{
			return A3(impl.gV, flags, _Browser_getUrl(), key);
		},
		h9: impl.h9,
		h3: impl.h3,
		hD: impl.hD
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gL: 'hidden', gj: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gL: 'mozHidden', gj: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gL: 'msHidden', gj: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gL: 'webkitHidden', gj: 'webkitvisibilitychange' }
		: { gL: 'hidden', gj: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		fr: _Browser_getScene(),
		fX: {
			ig: _Browser_window.pageXOffset,
			f$: _Browser_window.pageYOffset,
			f_: _Browser_doc.documentElement.clientWidth,
			ew: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		f_: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ew: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			fr: {
				f_: node.scrollWidth,
				ew: node.scrollHeight
			},
			fX: {
				ig: node.scrollLeft,
				f$: node.scrollTop,
				f_: node.clientWidth,
				ew: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			fr: _Browser_getScene(),
			fX: {
				ig: x,
				f$: y,
				f_: _Browser_doc.documentElement.clientWidth,
				ew: _Browser_doc.documentElement.clientHeight
			},
			gz: {
				ig: x + rect.left,
				f$: y + rect.top,
				f_: rect.width,
				ew: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.cz.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.cz.b, xhr)); });
		$elm$core$Maybe$isJust(request.h1) && _Http_track(router, xhr, request.h1.a);

		try {
			xhr.open(request.cS, request.h5, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.h5));
		}

		_Http_configureRequest(xhr, request);

		request.cn.a && xhr.setRequestHeader('Content-Type', request.cn.a);
		xhr.send(request.cn.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.gK; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.hQ.a || 0;
	xhr.responseType = request.cz.d;
	xhr.withCredentials = request.f8;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		h5: xhr.responseURL,
		hA: xhr.status,
		hB: xhr.statusText,
		gK: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			hv: event.loaded,
			O: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			hm: event.loaded,
			O: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.s) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.w),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.w);
		} else {
			var treeLen = builder.s * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.x) : builder.x;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.s);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.w) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.w);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{x: nodeList, s: (len / $elm$core$Array$branchFactor) | 0, w: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {et: fragment, ey: host, e1: path, e5: port_, fe: protocol, ff: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$App$User$Messages$DoLoad = {$: 4};
var $author$project$App$Messages$UserMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Models$App$Auth = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$App$Project$Model$init = function (maybeAccess) {
	return {dR: maybeAccess, ed: $elm$core$Maybe$Nothing, ba: $elm$core$Maybe$Nothing, eO: false, eP: $elm$core$Dict$empty, eU: $elm$core$Maybe$Nothing, e_: $elm$core$Dict$empty, fa: $elm$core$Maybe$Nothing, fb: $elm$core$Dict$empty, fd: _List_Nil, hK: $elm$core$Dict$empty, ia: $elm$core$Maybe$Nothing};
};
var $author$project$App$Social$Model$init = function (maybeAccess) {
	return {dR: maybeAccess, dy: $elm$core$Maybe$Nothing, fE: _List_Nil, dz: $elm$core$Maybe$Nothing, fF: _List_Nil};
};
var $author$project$App$User$Model$LoggedOut = {$: 1};
var $author$project$App$User$LogIn$Model$GetUserId = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$Snackbar$MessageId = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Snackbar$Queue = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Snackbar$initialQueue = {av: _List_Nil, bg: 0};
var $author$project$App$User$LogIn$Model$init = function (isToSignUp) {
	return {
		ba: $author$project$App$User$LogIn$Model$GetUserId(
			{gB: ''}),
		fw: $aforemny$material_components_web_elm$Material$Snackbar$initialQueue,
		fC: $elm$core$Maybe$Nothing,
		fL: false,
		fV: $elm$core$Maybe$Nothing
	};
};
var $author$project$App$User$Model$init = {
	eN: $author$project$App$User$LogIn$Model$init(false),
	fC: $author$project$App$User$Model$LoggedOut,
	fM: $elm$core$Maybe$Nothing,
	fT: $elm$core$Maybe$Nothing,
	fU: $elm$core$Maybe$Nothing
};
var $author$project$App$Model$init = {
	dR: $elm$core$Maybe$Nothing,
	eg: true,
	fa: $author$project$App$Project$Model$init($elm$core$Maybe$Nothing),
	fy: $author$project$App$Social$Model$init($elm$core$Maybe$Nothing),
	fz: $elm$core$Maybe$Nothing,
	h5: $author$project$Models$App$Auth,
	fT: $author$project$App$User$Model$init
};
var $author$project$App$Project$Messages$Chose = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$App$Default = {$: 0};
var $author$project$App$Messages$ProjectMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Messages$SocialMsg = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {fl: reqs, fD: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.h1;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.fl));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.fD)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					f8: r.f8,
					cn: r.cn,
					cz: A2(_Http_mapExpect, func, r.cz),
					gK: r.gK,
					cS: r.cS,
					hQ: r.hQ,
					h1: r.h1,
					h5: r.h5
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{f8: false, cn: r.cn, cz: r.cz, gK: r.gK, cS: r.cS, hQ: r.hQ, h1: r.h1, h5: r.h5}));
};
var $author$project$Api$timeout = 10000;
var $author$project$Api$urlPrefix = 'api';
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Api$getHttpReq = F2(
	function (_v0, _v1) {
		var token = _v0.fM;
		var userId = _v0.fU;
		var method = _v1.cS;
		var urlRem = _v1.fS;
		var body = _v1.cn;
		var expect = _v1.cz;
		var url = $author$project$Api$urlPrefix + ('/user/' + ($elm$core$String$fromInt(userId) + urlRem));
		return $elm$http$Http$request(
			{
				cn: A2(
					$elm$core$Maybe$withDefault,
					$elm$http$Http$emptyBody,
					A2($elm$core$Maybe$map, $elm$http$Http$jsonBody, body)),
				cz: expect,
				gK: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				cS: method,
				hQ: $elm$core$Maybe$Just($author$project$Api$timeout),
				h1: $elm$core$Maybe$Nothing,
				h5: url
			});
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Api$dealWithMaybeAccess = function (maybeAccess) {
	return A2(
		$elm$core$Maybe$withDefault,
		function (_v0) {
			return $elm$core$Platform$Cmd$none;
		},
		A2($elm$core$Maybe$map, $author$project$Api$getHttpReq, maybeAccess));
};
var $author$project$App$Project$Messages$Got = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Project$Messages$ProjectMsg = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.hA));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Models$Project$ProjectsWithStats = F3(
	function (projects, projectStats, projectTimedCounts) {
		return {fb: projectStats, hi: projectTimedCounts, fd: projects};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Models$Project$ProjectStat = F4(
	function (projectId, taskCount, taskDoneCount, consistentDays) {
		return {gm: consistentDays, de: projectId, hF: taskCount, hH: taskDoneCount};
	});
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Decoders$consistentDayDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$int));
var $author$project$Decoders$consistentDaysDecoder = $elm$json$Json$Decode$list($author$project$Decoders$consistentDayDecoder);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Decoders$projectStatDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'consistentDays',
	$author$project$Decoders$consistentDaysDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'taskDoneCount',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'taskCount',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'projectId',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Models$Project$ProjectStat)))));
var $author$project$Decoders$projectStatsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$projectStatDecoder);
var $author$project$Models$Project$Project = F6(
	function (id, title, userId, createdAt, updatedAt, status) {
		return {am: createdAt, eC: id, fC: status, hS: title, a0: updatedAt, fU: userId};
	});
var $author$project$Models$Project$Active = 0;
var $author$project$Models$Project$Archived = 1;
var $author$project$Models$Project$FuturePotential = 2;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Decoders$statusDecoder = function () {
	var partial = function (string) {
		switch (string) {
			case 'Active':
				return $elm$json$Json$Decode$succeed(0);
			case 'Archived':
				return $elm$json$Json$Decode$succeed(1);
			case 'FuturePotential':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid Status variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$projectDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$author$project$Decoders$statusDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdAt',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'userId',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'title',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Models$Project$Project)))))));
var $author$project$Decoders$projectsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$projectDecoder);
var $author$project$Models$Project$ProjectTimedCounts = F2(
	function (projectId, timedCounts) {
		return {de: projectId, fK: timedCounts};
	});
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Models$Metric$TimedCounts = F3(
	function (frequency, counts, percentiles) {
		return {d8: counts, gI: frequency, e3: percentiles};
	});
var $author$project$Models$Metric$Daily = 0;
var $author$project$Models$Metric$Weekly = 1;
var $author$project$Decoders$frequencyDecoder = function () {
	var toFrequency = function (string) {
		switch (string) {
			case 'Daily':
				return $elm$json$Json$Decode$succeed(0);
			case 'Weekly':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('invalid frequency variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, toFrequency, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Decoders$percentileDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$float));
var $author$project$Models$Metric$TimedCount = F2(
	function (count, relativeTimeAgo) {
		return {d7: count, fj: relativeTimeAgo};
	});
var $author$project$Decoders$timedCountDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'relativeTimeAgo',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'count',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Models$Metric$TimedCount)));
var $author$project$Decoders$timedCountsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'percentiles',
	$elm$json$Json$Decode$list($author$project$Decoders$percentileDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'counts',
		$elm$json$Json$Decode$list($author$project$Decoders$timedCountDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'frequency',
			$author$project$Decoders$frequencyDecoder,
			$elm$json$Json$Decode$succeed($author$project$Models$Metric$TimedCounts))));
var $author$project$Decoders$projectTimedCountsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'timedCounts',
	$elm$json$Json$Decode$maybe($author$project$Decoders$timedCountsDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'projectId',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Models$Project$ProjectTimedCounts)));
var $author$project$Decoders$projectsTimedCountsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$projectTimedCountsDecoder);
var $author$project$Decoders$projectsWithStatsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'projectTimedCounts',
	$author$project$Decoders$projectsTimedCountsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'projectStats',
		$author$project$Decoders$projectStatsDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'projects',
			$author$project$Decoders$projectsDecoder,
			$elm$json$Json$Decode$succeed($author$project$Models$Project$ProjectsWithStats))));
var $author$project$App$Project$Commands$getProjects = {
	cn: $elm$core$Maybe$Nothing,
	cz: A2(
		$elm$http$Http$expectJson,
		function (res) {
			return $author$project$App$Project$Messages$ProjectMsg(
				$author$project$App$Project$Messages$Got(res));
		},
		$author$project$Decoders$projectsWithStatsDecoder),
	cS: 'GET',
	fS: '/projects'
};
var $author$project$App$Social$Messages$GotSupporteeSocial = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$Social$SupporteeSocial = F3(
	function (watcherUsers, projects, reactions) {
		return {fd: projects, fi: reactions, id: watcherUsers};
	});
var $author$project$Models$Social$ReactionExp = F9(
	function (id, ownerId, projectId, objectiveId, taskId, logId, watcherId, action, reaction) {
		return {f3: action, eC: id, g1: logId, g9: objectiveId, e0: ownerId, de: projectId, hk: reaction, hI: taskId, ib: watcherId};
	});
var $author$project$Decoders$maybeStringDecoder = $elm$json$Json$Decode$maybe($elm$json$Json$Decode$string);
var $author$project$Models$Project$CompletedTask = 2;
var $author$project$Models$Project$StartedEffort = 0;
var $author$project$Models$Project$StoppedEffort = 1;
var $author$project$Decoders$taskActionDecoder = function () {
	var partial = function (string) {
		switch (string) {
			case 'StartedEffort':
				return $elm$json$Json$Decode$succeed(0);
			case 'StoppedEffort':
				return $elm$json$Json$Decode$succeed(1);
			case 'CompletedTask':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid TaskAction variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$reactionExpDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'reaction',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'action',
		$author$project$Decoders$taskActionDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'watcherId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'logId',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'taskId',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'objectiveId',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'projectId',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'ownerId',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$Models$Social$ReactionExp))))))))));
var $author$project$Decoders$reactionExpsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$reactionExpDecoder);
var $author$project$Models$User$User = F4(
	function (id, email, firstName, lastName) {
		return {gB: email, er: firstName, eC: id, g$: lastName};
	});
var $author$project$Decoders$userDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastName',
	$author$project$Decoders$maybeStringDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'email',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Models$User$User)))));
var $author$project$Decoders$usersDecoder = $elm$json$Json$Decode$list($author$project$Decoders$userDecoder);
var $author$project$Decoders$supporteeSocialDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'reactions',
	$author$project$Decoders$reactionExpsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'projects',
		$author$project$Decoders$projectsDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'watcherUsers',
			$author$project$Decoders$usersDecoder,
			$elm$json$Json$Decode$succeed($author$project$Models$Social$SupporteeSocial))));
var $author$project$App$Social$Commands$getSupporteeSocial = {
	cn: $elm$core$Maybe$Nothing,
	cz: A2($elm$http$Http$expectJson, $author$project$App$Social$Messages$GotSupporteeSocial, $author$project$Decoders$supporteeSocialDecoder),
	cS: 'GET',
	fS: '/supportee_social'
};
var $author$project$App$Social$Messages$GotSupporterSocial = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Social$SupporterSocial = F4(
	function (projectedUsers, watcherProjects, reactionOpportunities, projectStats) {
		return {fb: projectStats, fc: projectedUsers, hl: reactionOpportunities, ic: watcherProjects};
	});
var $author$project$Models$Social$ProjectedUser = F2(
	function (user, projects) {
		return {fd: projects, fT: user};
	});
var $author$project$Decoders$projectedUserDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'projects',
	$author$project$Decoders$projectsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'user',
		$author$project$Decoders$userDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$Social$ProjectedUser)));
var $author$project$Decoders$projectedUsersDecoder = $elm$json$Json$Decode$list($author$project$Decoders$projectedUserDecoder);
var $author$project$Models$Social$WatcherProject = F4(
	function (id, ownerId, projectId, agreed) {
		return {f6: agreed, eC: id, e0: ownerId, de: projectId};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Decoders$watcherProjectDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'agreed',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'projectId',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'ownerId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Models$Social$WatcherProject)))));
var $author$project$Decoders$watcherProjectsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$watcherProjectDecoder);
var $author$project$Decoders$supporterSocialDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'projectStats',
	$author$project$Decoders$projectStatsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'reactionOpportunities',
		$author$project$Decoders$reactionExpsDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'watcherProjects',
			$author$project$Decoders$watcherProjectsDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'projectedUsers',
				$author$project$Decoders$projectedUsersDecoder,
				$elm$json$Json$Decode$succeed($author$project$Models$Social$SupporterSocial)))));
var $author$project$App$Social$Commands$getSupporterSocial = {
	cn: $elm$core$Maybe$Nothing,
	cz: A2($elm$http$Http$expectJson, $author$project$App$Social$Messages$GotSupporterSocial, $author$project$Decoders$supporterSocialDecoder),
	cS: 'GET',
	fS: '/supporter_social'
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$App$Update$cmdByMode = F2(
	function (access, socialMode) {
		var doReq = $author$project$Api$dealWithMaybeAccess(
			$elm$core$Maybe$Just(access));
		var p = _List_fromArray(
			[
				A2(
				$elm$core$Platform$Cmd$map,
				$author$project$App$Messages$ProjectMsg,
				doReq($author$project$App$Project$Commands$getProjects))
			]);
		var s = A2(
			$elm$core$List$map,
			$elm$core$Platform$Cmd$map($author$project$App$Messages$SocialMsg),
			A2(
				$elm$core$List$map,
				doReq,
				_List_fromArray(
					[$author$project$App$Social$Commands$getSupporteeSocial, $author$project$App$Social$Commands$getSupporterSocial])));
		return $elm$core$Platform$Cmd$batch(
			$elm$core$List$concat(
				function () {
					if (socialMode.$ === 1) {
						return _List_fromArray(
							[s, p]);
					} else {
						if (socialMode.a) {
							return _List_fromArray(
								[s]);
						} else {
							return _List_fromArray(
								[p]);
						}
					}
				}()));
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$App$Update$drawerByProject = F2(
	function (pMsg, pModel) {
		_v0$2:
		while (true) {
			switch (pMsg.$) {
				case 2:
					return (!_Utils_eq(pModel.fa, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(false) : $elm$core$Maybe$Nothing;
				case 1:
					if (!pMsg.a.$) {
						var _v1 = pMsg.a;
						return $elm$core$Maybe$Just(false);
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $author$project$App$Social$Model$hasContent = function (_v0) {
	var supporteeSocial = _v0.dy;
	var supporterSocial = _v0.dz;
	var op = F2(
		function (f, a) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2($elm$core$Maybe$map, f, a));
		});
	var hasSupporterS = function (_v2) {
		var watcherProjects = _v2.ic;
		var reactionOpportunities = _v2.hl;
		return ($elm$core$List$length(watcherProjects) > 0) || ($elm$core$List$length(reactionOpportunities) > 0);
	};
	var hasSupporteeS = function (_v1) {
		var reactions = _v1.fi;
		var watcherUsers = _v1.id;
		return ($elm$core$List$length(reactions) > 0) && ($elm$core$List$length(watcherUsers) > 0);
	};
	return A2(op, hasSupporteeS, supporteeSocial) || A2(op, hasSupporterS, supporterSocial);
};
var $author$project$App$User$Model$LoggedIn = {$: 0};
var $author$project$App$User$Model$isLoggedIn = function (_v0) {
	var status = _v0.fC;
	return _Utils_eq(status, $author$project$App$User$Model$LoggedIn);
};
var $elm$core$Basics$not = _Basics_not;
var $author$project$Models$Project$Is = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Project$Messages$TaskedObjectiveMsg = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$App$Project$Messages$WatcherMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$App$Project$Messages$Deleted = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$App$Project$Commands$delete = function (id) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: $elm$http$Http$expectWhatever(
			function (res) {
				return $author$project$App$Project$Messages$ProjectMsg(
					A2($author$project$App$Project$Messages$Deleted, id, res));
			}),
		cS: 'DELETE',
		fS: '/project/' + $elm$core$String$fromInt(id)
	};
};
var $author$project$App$Project$Messages$Posted = function (a) {
	return {$: 2, a: a};
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Encoders$projectPayloadEncoder = function (_v0) {
	var title = _v0.hS;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(title))
			]));
};
var $author$project$App$Project$Commands$post = function (payload) {
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$projectPayloadEncoder(payload)),
		cz: A2(
			$elm$http$Http$expectJson,
			function (res) {
				return $author$project$App$Project$Messages$ProjectMsg(
					$author$project$App$Project$Messages$Posted(res));
			},
			$author$project$Decoders$projectDecoder),
		cS: 'POST',
		fS: '/project'
	};
};
var $author$project$App$Project$Messages$Put = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Project$Commands$put = function (_v0) {
	var title = _v0.hS;
	var id = _v0.eC;
	var payload = {hS: title};
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$projectPayloadEncoder(payload)),
		cz: A2(
			$elm$http$Http$expectJson,
			function (res) {
				return $author$project$App$Project$Messages$ProjectMsg(
					$author$project$App$Project$Messages$Put(res));
			},
			$author$project$Decoders$projectDecoder),
		cS: 'PUT',
		fS: '/project/' + $elm$core$String$fromInt(id)
	};
};
var $author$project$App$Project$Commands$cmdReqToReqReq = function (cmdReq) {
	switch (cmdReq.$) {
		case 0:
			var payload = cmdReq.a;
			return $author$project$App$Project$Commands$post(payload);
		case 1:
			var task = cmdReq.a;
			return $author$project$App$Project$Commands$put(task);
		default:
			var pId = cmdReq.a;
			return $author$project$App$Project$Commands$delete(pId);
	}
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$App$Project$Messages$GotOngoingEfforts = function (a) {
	return {$: 7, a: a};
};
var $author$project$Models$Project$EffortExp = F8(
	function (id, taskId, taskDescription, objectiveId, objectiveDescription, projectId, projectTitle, startedAt) {
		return {eC: id, g8: objectiveDescription, g9: objectiveId, de: projectId, hj: projectTitle, hz: startedAt, hG: taskDescription, hI: taskId};
	});
var $author$project$Decoders$effortExpDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'startedAt',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'projectTitle',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'projectId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'objectiveDescription',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'objectiveId',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'taskDescription',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'taskId',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Models$Project$EffortExp)))))))));
var $author$project$Decoders$effortExpsDecoder = $elm$json$Json$Decode$list($author$project$Decoders$effortExpDecoder);
var $author$project$App$Project$Commands$getOngoingByProject = function (pId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: A2($elm$http$Http$expectJson, $author$project$App$Project$Messages$GotOngoingEfforts, $author$project$Decoders$effortExpsDecoder),
		cS: 'GET',
		fS: '/project/' + ($elm$core$String$fromInt(pId) + '/efforts')
	};
};
var $author$project$App$Project$Model$getProjectId = function (_v0) {
	var project = _v0.fa;
	if ((!project.$) && (!project.a.$)) {
		var id = project.a.a.eC;
		return $elm$core$Maybe$Just(id);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Watcher$Messages$GotSupporters = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Watcher$Commands$getSupporters = {
	cn: $elm$core$Maybe$Nothing,
	cz: A2($elm$http$Http$expectJson, $author$project$App$Watcher$Messages$GotSupporters, $author$project$Decoders$usersDecoder),
	cS: 'GET',
	fS: '/supporters'
};
var $author$project$App$Project$Update$getTaskedObjectiveModel = F2(
	function (model, maybeOId) {
		if (!maybeOId.$) {
			var oId = maybeOId.a;
			return A2($elm$core$Dict$get, oId, model.hK);
		} else {
			return model.eU;
		}
	});
var $author$project$App$Project$Messages$GotTaskedObjectives = function (a) {
	return {$: 4, a: a};
};
var $author$project$Models$Project$TaskedObjective = F2(
	function (objective, tasks) {
		return {eV: objective, hL: tasks};
	});
var $author$project$Models$Project$Objective = F8(
	function (id, projectId, description, dueBy, done, createdAt, updatedAt, status) {
		return {am: createdAt, ec: description, gx: done, eh: dueBy, eC: id, de: projectId, fC: status, a0: updatedAt};
	});
var $author$project$Decoders$objectiveDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$author$project$Decoders$statusDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdAt',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'done',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dueBy',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'projectId',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Models$Project$Objective)))))))));
var $author$project$Models$Project$Task = F8(
	function (id, objectiveId, description, estimatedUnits, done, createdAt, updatedAt, status) {
		return {am: createdAt, ec: description, gx: done, el: estimatedUnits, eC: id, g9: objectiveId, fC: status, a0: updatedAt};
	});
var $author$project$Decoders$taskDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$author$project$Decoders$statusDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdAt',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'done',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'estimatedUnits',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'objectiveId',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Models$Project$Task)))))))));
var $author$project$Decoders$taskedObjectiveDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'tasks',
	$elm$json$Json$Decode$list($author$project$Decoders$taskDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'objective',
		$author$project$Decoders$objectiveDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$Project$TaskedObjective)));
var $author$project$Decoders$taskedObjectivesDecoder = $elm$json$Json$Decode$list($author$project$Decoders$taskedObjectiveDecoder);
var $author$project$App$Project$Commands$getTaskedObjectives = function (projectId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: A2($elm$http$Http$expectJson, $author$project$App$Project$Messages$GotTaskedObjectives, $author$project$Decoders$taskedObjectivesDecoder),
		cS: 'GET',
		fS: '/project/' + ($elm$core$String$fromInt(projectId) + '/tasked_objectives')
	};
};
var $author$project$App$Watcher$Messages$GotWatchers = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$Social$WatcherUser = F2(
	function (watcher, user) {
		return {fT: user, ia: watcher};
	});
var $author$project$Models$Social$Watcher = F7(
	function (id, projectId, userId, userAgreed, createdAt, updatedAt, visibility) {
		return {am: createdAt, eC: id, de: projectId, a0: updatedAt, h6: userAgreed, fU: userId, dJ: visibility};
	});
var $author$project$Models$Social$ObjectiveLevel = 1;
var $author$project$Models$Social$ProjectLevel = 0;
var $author$project$Models$Social$TaskLevel = 2;
var $author$project$Decoders$visibilityDecoder = function () {
	var partial = function (string) {
		switch (string) {
			case 'Project':
				return $elm$json$Json$Decode$succeed(0);
			case 'Objective':
				return $elm$json$Json$Decode$succeed(1);
			case 'Task':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('invalid Visibility variant');
		}
	};
	return A2($elm$json$Json$Decode$andThen, partial, $elm$json$Json$Decode$string);
}();
var $author$project$Decoders$watcherDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'visibility',
	$author$project$Decoders$visibilityDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdAt',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'userAgreed',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'userId',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'projectId',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Models$Social$Watcher))))))));
var $author$project$Decoders$watcherUserDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'user',
	$author$project$Decoders$userDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'watcher',
		$author$project$Decoders$watcherDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$Social$WatcherUser)));
var $author$project$Decoders$watcherUsersDecoder = $elm$json$Json$Decode$list($author$project$Decoders$watcherUserDecoder);
var $author$project$App$Watcher$Commands$getWatchers = function (pId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: A2($elm$http$Http$expectJson, $author$project$App$Watcher$Messages$GotWatchers, $author$project$Decoders$watcherUsersDecoder),
		cS: 'GET',
		fS: '/project/' + ($elm$core$String$fromInt(pId) + '/watchers')
	};
};
var $author$project$App$Watcher$Messages$NewSupporterWatcher = 0;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$Models$User$initUserPayload = {gB: '', er: '', g$: $elm$core$Maybe$Nothing};
var $author$project$Models$Social$initSupporterWatcherPayload = {fT: $author$project$Models$User$initUserPayload, dJ: 0};
var $author$project$App$Watcher$Model$init = function (pId) {
	return {e2: $author$project$Models$Social$initSupporterWatcherPayload, de: pId, fw: $aforemny$material_components_web_elm$Material$Snackbar$initialQueue, fA: 0, fG: $elm$core$Set$empty, dA: _List_Nil, dK: _List_Nil};
};
var $author$project$Models$Project$ToBe = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Form$Model$Objective = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Form$Model$Idle = 0;
var $author$project$App$Form$Model$ObjDescription = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Form$Model$TaskDescription = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Form$Model$Title = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Form$Model$initFields = function (form) {
	switch (form.$) {
		case 0:
			var pPot = form.a;
			if (!pPot.$) {
				var p = pPot.a;
				return _List_fromArray(
					[
						$author$project$App$Form$Model$Title(p.hS)
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$App$Form$Model$Title('')
					]);
			}
		case 1:
			var oPot = form.a;
			if (!oPot.$) {
				var o = oPot.a;
				return _List_fromArray(
					[
						$author$project$App$Form$Model$ObjDescription(o.ec)
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$App$Form$Model$ObjDescription('')
					]);
			}
		default:
			var tPot = form.a;
			if (!tPot.$) {
				var t = tPot.a;
				return _List_fromArray(
					[
						$author$project$App$Form$Model$TaskDescription(t.ec)
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$App$Form$Model$TaskDescription('')
					]);
			}
	}
};
var $author$project$App$Form$Model$init = function (form) {
	return {
		eq: $author$project$App$Form$Model$initFields(form),
		ba: form,
		fC: 0
	};
};
var $author$project$App$Form$Model$initObjectivePotential = function (oP) {
	return $author$project$App$Form$Model$init(
		$author$project$App$Form$Model$Objective(oP));
};
var $author$project$App$Objective$Model$init = function (objectivePotential) {
	var base = {ba: $elm$core$Maybe$Nothing, eV: objectivePotential};
	if (objectivePotential.$ === 1) {
		return _Utils_update(
			base,
			{
				ba: $elm$core$Maybe$Just(
					$author$project$App$Form$Model$initObjectivePotential(objectivePotential))
			});
	} else {
		return base;
	}
};
var $author$project$Models$Project$initObjectivePayload = function (pId) {
	return {ec: '', eh: $elm$core$Maybe$Nothing, de: pId};
};
var $author$project$App$TaskedObjective$Model$initForNewObjective = function (pId) {
	return {
		d6: $elm$core$Dict$empty,
		aq: $elm$core$Set$empty,
		eO: false,
		bR: $elm$core$Maybe$Nothing,
		eV: $author$project$App$Objective$Model$init(
			$author$project$Models$Project$ToBe(
				$author$project$Models$Project$initObjectivePayload(pId))),
		hL: $elm$core$Dict$empty
	};
};
var $author$project$App$Form$Model$Project = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Form$Model$initProjectPotential = function (pP) {
	return $author$project$App$Form$Model$init(
		$author$project$App$Form$Model$Project(pP));
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Models$Project$potentialToMaybe = function (p) {
	if (!p.$) {
		var t = p.a;
		return $elm$core$Maybe$Just(t);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$App$TaskedObjective$Model$getDoneTaskIds = function (tasks) {
	var fmFnc = function (_v0) {
		var task = _v0.fI;
		return $author$project$Models$Project$potentialToMaybe(task);
	};
	return $elm$core$Set$fromList(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.eC;
			},
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.gx;
				},
				A2(
					$elm$core$List$filterMap,
					fmFnc,
					$elm$core$Dict$values(tasks)))));
};
var $author$project$App$Form$Model$Task = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Form$Model$initTaskPotential = function (tP) {
	return $author$project$App$Form$Model$init(
		$author$project$App$Form$Model$Task(tP));
};
var $author$project$App$Task$Model$init = function (taskPotential) {
	var base = {d6: $elm$core$Dict$empty, ej: _List_Nil, ba: $elm$core$Maybe$Nothing, eO: false, eZ: $elm$core$Maybe$Nothing, fI: taskPotential};
	if (taskPotential.$ === 1) {
		return _Utils_update(
			base,
			{
				ba: $elm$core$Maybe$Just(
					$author$project$App$Form$Model$initTaskPotential(taskPotential))
			});
	} else {
		return base;
	}
};
var $author$project$App$TaskedObjective$Model$init = function (_v0) {
	var objective = _v0.eV;
	var tasks = _v0.hL;
	var makePair = function (task) {
		return _Utils_Tuple2(
			task.eC,
			$author$project$App$Task$Model$init(
				$author$project$Models$Project$Is(task)));
	};
	var tasksDict = $elm$core$Dict$fromList(
		A2($elm$core$List$map, makePair, tasks));
	return {
		d6: $elm$core$Dict$empty,
		aq: $author$project$App$TaskedObjective$Model$getDoneTaskIds(tasksDict),
		eO: false,
		bR: $elm$core$Maybe$Nothing,
		eV: $author$project$App$Objective$Model$init(
			$author$project$Models$Project$Is(objective)),
		hL: tasksDict
	};
};
var $author$project$App$TaskedObjective$Model$listToDict = function (tOs) {
	var makePair = function (tO) {
		return _Utils_Tuple2(
			tO.eV.eC,
			$author$project$App$TaskedObjective$Model$init(tO));
	};
	return $elm$core$Dict$fromList(
		A2($elm$core$List$map, makePair, tOs));
};
var $author$project$App$Project$Messages$CmdReq = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Project$Messages$ToPost = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Project$Messages$ToPut = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Project$Update$processSubmit = function (fModel) {
	var _v0 = fModel.ba;
	if (!_v0.$) {
		if (_v0.a.$ === 1) {
			var payload = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Project$Messages$CmdReq(
					$author$project$App$Project$Messages$ToPost(payload)));
		} else {
			var project = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Project$Messages$CmdReq(
					$author$project$App$Project$Messages$ToPut(project)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Project$Update$resetProjectSpecific = function (model) {
	return _Utils_update(
		model,
		{ed: $elm$core$Maybe$Nothing, ba: $elm$core$Maybe$Nothing, eO: false, eU: $elm$core$Maybe$Nothing, e_: $elm$core$Dict$empty, fa: $elm$core$Maybe$Nothing, hK: $elm$core$Dict$empty});
};
var $author$project$App$TaskedObjective$Messages$ObjectiveMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$TaskedObjective$Messages$TaskMsg = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$App$Objective$Messages$Deleted = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Objective$Commands$delete = function (_v0) {
	var id = _v0.eC;
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: $elm$http$Http$expectWhatever($author$project$App$Objective$Messages$Deleted),
		cS: 'DELETE',
		fS: '/objective/' + $elm$core$String$fromInt(id)
	};
};
var $author$project$App$Objective$Messages$Posted = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Encoders$maybeEncoder = F2(
	function (encoder, maybea) {
		if (!maybea.$) {
			var a = maybea.a;
			return encoder(a);
		} else {
			return $elm$json$Json$Encode$null;
		}
	});
var $author$project$Encoders$objectivePayloadEncoder = function (_v0) {
	var description = _v0.ec;
	var dueBy = _v0.eh;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(description)),
				_Utils_Tuple2(
				'dueBy',
				A2($author$project$Encoders$maybeEncoder, $elm$json$Json$Encode$int, dueBy))
			]));
};
var $author$project$App$Objective$Commands$post = function (payload) {
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$objectivePayloadEncoder(payload)),
		cz: A2($elm$http$Http$expectJson, $author$project$App$Objective$Messages$Posted, $author$project$Decoders$objectiveDecoder),
		cS: 'POST',
		fS: '/project/' + ($elm$core$String$fromInt(payload.de) + '/objective')
	};
};
var $author$project$App$Objective$Messages$Put = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Objective$Commands$put = function (_v0) {
	var id = _v0.eC;
	var projectId = _v0.de;
	var description = _v0.ec;
	var dueBy = _v0.eh;
	var payload = {ec: description, eh: dueBy, de: projectId};
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$objectivePayloadEncoder(payload)),
		cz: A2($elm$http$Http$expectJson, $author$project$App$Objective$Messages$Put, $author$project$Decoders$objectiveDecoder),
		cS: 'PUT',
		fS: '/project/' + ($elm$core$String$fromInt(projectId) + ('/objective/' + $elm$core$String$fromInt(id)))
	};
};
var $author$project$App$Objective$Commands$cmdReqToReqReq = function (cmdReq) {
	switch (cmdReq.$) {
		case 0:
			var payload = cmdReq.a;
			return $author$project$App$Objective$Commands$post(payload);
		case 1:
			var objective = cmdReq.a;
			return $author$project$App$Objective$Commands$put(objective);
		default:
			var objective = cmdReq.a;
			return $author$project$App$Objective$Commands$delete(objective);
	}
};
var $author$project$App$Task$Messages$Deleted = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Task$Commands$delete = function (_v0) {
	var id = _v0.eC;
	var objectiveId = _v0.g9;
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: $elm$http$Http$expectWhatever($author$project$App$Task$Messages$Deleted),
		cS: 'DELETE',
		fS: '/task/' + $elm$core$String$fromInt(id)
	};
};
var $author$project$App$Task$Messages$Got = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Task$Commands$get = function (taskId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: A2($elm$http$Http$expectJson, $author$project$App$Task$Messages$Got, $author$project$Decoders$taskDecoder),
		cS: 'GET',
		fS: '/task/' + $elm$core$String$fromInt(taskId)
	};
};
var $author$project$App$Task$Messages$Posted = function (a) {
	return {$: 2, a: a};
};
var $author$project$Encoders$taskPayloadEncoder = function (_v0) {
	var description = _v0.ec;
	var estimatedUnits = _v0.el;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(description)),
				_Utils_Tuple2(
				'estimatedUnits',
				A2($author$project$Encoders$maybeEncoder, $elm$json$Json$Encode$int, estimatedUnits))
			]));
};
var $author$project$App$Task$Commands$post = function (payload) {
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$taskPayloadEncoder(payload)),
		cz: A2($elm$http$Http$expectJson, $author$project$App$Task$Messages$Posted, $author$project$Decoders$taskDecoder),
		cS: 'POST',
		fS: '/objective/' + ($elm$core$String$fromInt(payload.g9) + '/task')
	};
};
var $author$project$App$Task$Messages$ToQAdded = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Task$Commands$postStart = function (tId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: A2($elm$http$Http$expectJson, $author$project$App$Task$Messages$ToQAdded, $author$project$Decoders$effortExpDecoder),
		cS: 'POST',
		fS: '/task/' + ($elm$core$String$fromInt(tId) + '/effort')
	};
};
var $author$project$App$Task$Messages$Put = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Task$Commands$put = function (_v0) {
	var id = _v0.eC;
	var objectiveId = _v0.g9;
	var description = _v0.ec;
	var estimatedUnits = _v0.el;
	var payload = {ec: description, el: estimatedUnits, g9: objectiveId};
	return {
		cn: $elm$core$Maybe$Just(
			$author$project$Encoders$taskPayloadEncoder(payload)),
		cz: A2($elm$http$Http$expectJson, $author$project$App$Task$Messages$Put, $author$project$Decoders$taskDecoder),
		cS: 'PUT',
		fS: '/objective/' + ($elm$core$String$fromInt(objectiveId) + ('/task/' + $elm$core$String$fromInt(id)))
	};
};
var $author$project$App$Task$Messages$DonePut = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Task$Commands$putDone = function (_v0) {
	var taskId = _v0.hI;
	var done = _v0.gx;
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: $elm$http$Http$expectWhatever($author$project$App$Task$Messages$DonePut),
		cS: 'PUT',
		fS: '/task/' + ($elm$core$String$fromInt(taskId) + (done ? '/done' : '/undone'))
	};
};
var $author$project$App$Task$Messages$FromQRemoved = function (a) {
	return {$: 7, a: a};
};
var $author$project$App$Task$Commands$putStop = function (effortId) {
	return {
		cn: $elm$core$Maybe$Nothing,
		cz: $elm$http$Http$expectWhatever($author$project$App$Task$Messages$FromQRemoved),
		cS: 'PUT',
		fS: '/effort/' + ($elm$core$String$fromInt(effortId) + '/stop')
	};
};
var $author$project$App$Task$Commands$cmdReqToReqReq = function (cmdReq) {
	switch (cmdReq.$) {
		case 0:
			var taskId = cmdReq.a;
			return $author$project$App$Task$Commands$get(taskId);
		case 1:
			var payload = cmdReq.a;
			return $author$project$App$Task$Commands$post(payload);
		case 2:
			var task = cmdReq.a;
			return $author$project$App$Task$Commands$put(task);
		case 3:
			var donePayload = cmdReq.a;
			return $author$project$App$Task$Commands$putDone(donePayload);
		case 4:
			var task = cmdReq.a;
			return $author$project$App$Task$Commands$delete(task);
		case 5:
			var taskId = cmdReq.a;
			return $author$project$App$Task$Commands$postStart(taskId);
		default:
			var effortId = cmdReq.a;
			return $author$project$App$Task$Commands$putStop(effortId);
	}
};
var $author$project$App$Project$Update$taskedObjectiveCmdReq = F3(
	function (access, oId, tOMsg) {
		var doReq = $author$project$Api$dealWithMaybeAccess(access);
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Project$Messages$TaskedObjectiveMsg(oId),
			function () {
				_v0$2:
				while (true) {
					switch (tOMsg.$) {
						case 4:
							if (!tOMsg.a.$) {
								var cmdReq = tOMsg.a.a;
								return A2(
									$elm$core$Platform$Cmd$map,
									$author$project$App$TaskedObjective$Messages$ObjectiveMsg,
									doReq(
										$author$project$App$Objective$Commands$cmdReqToReqReq(cmdReq)));
							} else {
								break _v0$2;
							}
						case 5:
							if (!tOMsg.b.$) {
								var tId = tOMsg.a;
								var cmdReq = tOMsg.b.a;
								return A2(
									$elm$core$Platform$Cmd$map,
									$author$project$App$TaskedObjective$Messages$TaskMsg(tId),
									doReq(
										$author$project$App$Task$Commands$cmdReqToReqReq(cmdReq)));
							} else {
								break _v0$2;
							}
						default:
							break _v0$2;
					}
				}
				return $elm$core$Platform$Cmd$none;
			}());
	});
var $author$project$App$Metrics$Update$update = F2(
	function (msg, model) {
		var hovering = msg;
		return _Utils_update(
			model,
			{eA: hovering});
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$diff = F2(
	function (t1, t2) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, t) {
					return A2($elm$core$Dict$remove, k, t);
				}),
			t1,
			t2);
	});
var $elm$core$Set$diff = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$diff, dict1, dict2);
	});
var $author$project$App$TaskedObjective$Update$getTaskModel = F2(
	function (model, maybeTaskId) {
		if (!maybeTaskId.$) {
			var tId = maybeTaskId.a;
			return A2($elm$core$Dict$get, tId, model.hL);
		} else {
			return model.bR;
		}
	});
var $author$project$Models$Project$initTaskPayload = function (oId) {
	return {ec: '', el: $elm$core$Maybe$Nothing, g9: oId};
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Dict$intersect = F2(
	function (t1, t2) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v0) {
					return A2($elm$core$Dict$member, k, t2);
				}),
			t1);
	});
var $elm$core$Set$intersect = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$intersect, dict1, dict2);
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$core$Set$union = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$union, dict1, dict2);
	});
var $author$project$App$Objective$Messages$CmdReq = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Objective$Messages$ToPost = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Objective$Messages$ToPut = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Objective$Update$processSubmit = function (fModel) {
	var _v0 = fModel.ba;
	if (_v0.$ === 1) {
		if (_v0.a.$ === 1) {
			var payload = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Objective$Messages$CmdReq(
					$author$project$App$Objective$Messages$ToPost(payload)));
		} else {
			var objective = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Objective$Messages$CmdReq(
					$author$project$App$Objective$Messages$ToPut(objective)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$App$Form$Update$updateFields = F2(
	function (fields, field) {
		var updateFnc = function (f) {
			var _v0 = _Utils_Tuple2(f, field);
			_v0$5:
			while (true) {
				switch (_v0.a.$) {
					case 0:
						if (!_v0.b.$) {
							return field;
						} else {
							break _v0$5;
						}
					case 1:
						if (_v0.b.$ === 1) {
							return field;
						} else {
							break _v0$5;
						}
					case 2:
						if (_v0.b.$ === 2) {
							return field;
						} else {
							break _v0$5;
						}
					case 4:
						if (_v0.b.$ === 4) {
							return field;
						} else {
							break _v0$5;
						}
					default:
						if (_v0.b.$ === 3) {
							return field;
						} else {
							break _v0$5;
						}
				}
			}
			return f;
		};
		return A2($elm$core$List$map, updateFnc, fields);
	});
var $author$project$App$Form$Update$updateForm = F2(
	function (form, field) {
		var _v0 = _Utils_Tuple2(form, field);
		_v0$6:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.a.a.$) {
						if (!_v0.b.$) {
							var p = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Project(
								$author$project$Models$Project$Is(
									_Utils_update(
										p,
										{hS: s})));
						} else {
							break _v0$6;
						}
					} else {
						if (!_v0.b.$) {
							var p = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Project(
								$author$project$Models$Project$ToBe(
									_Utils_update(
										p,
										{hS: s})));
						} else {
							break _v0$6;
						}
					}
				case 1:
					if (!_v0.a.a.$) {
						if (_v0.b.$ === 1) {
							var o = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Objective(
								$author$project$Models$Project$Is(
									_Utils_update(
										o,
										{ec: s})));
						} else {
							break _v0$6;
						}
					} else {
						if (_v0.b.$ === 1) {
							var o = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Objective(
								$author$project$Models$Project$ToBe(
									_Utils_update(
										o,
										{ec: s})));
						} else {
							break _v0$6;
						}
					}
				default:
					if (!_v0.a.a.$) {
						if (_v0.b.$ === 2) {
							var t = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Task(
								$author$project$Models$Project$Is(
									_Utils_update(
										t,
										{ec: s})));
						} else {
							break _v0$6;
						}
					} else {
						if (_v0.b.$ === 2) {
							var t = _v0.a.a.a;
							var s = _v0.b.a;
							return $author$project$App$Form$Model$Task(
								$author$project$Models$Project$ToBe(
									_Utils_update(
										t,
										{ec: s})));
						} else {
							break _v0$6;
						}
					}
			}
		}
		return form;
	});
var $author$project$App$Form$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var f = msg.a;
				return _Utils_update(
					model,
					{
						eq: A2($author$project$App$Form$Update$updateFields, model.eq, f),
						ba: A2($author$project$App$Form$Update$updateForm, model.ba, f)
					});
			case 1:
				var f = msg.a;
				return model;
			case 2:
				return model;
			default:
				return model;
		}
	});
var $author$project$App$Form$Update$updateExt = F3(
	function (formToParentMsg, msg, model) {
		var form = model.ba;
		switch (msg.$) {
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: $elm$core$Maybe$Nothing}),
					$elm$core$Maybe$Nothing);
			case 2:
				return _Utils_Tuple2(
					model,
					A2($elm$core$Maybe$andThen, formToParentMsg, form));
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ba: A2(
								$elm$core$Maybe$map,
								$author$project$App$Form$Update$update(msg),
								form)
						}),
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$App$Objective$Update$update = F2(
	function (msg, model) {
		var form = model.ba;
		var objective = model.eV;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
			case 1:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 2:
				if (!msg.a.$) {
					var o = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: $elm$core$Maybe$Nothing,
								eV: $author$project$Models$Project$Is(o)
							}),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 3:
				return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ba: $elm$core$Maybe$Just(
								$author$project$App$Form$Model$initObjectivePotential(objective))
						}),
					$elm$core$Maybe$Nothing);
			default:
				var fMsg = msg.a;
				return A3($author$project$App$Form$Update$updateExt, $author$project$App$Objective$Update$processSubmit, fMsg, model);
		}
	});
var $author$project$App$Task$Messages$CmdReq = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Task$Messages$ToPost = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Task$Messages$ToPut = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$Task$Update$processSubmit = function (fModel) {
	var _v0 = fModel.ba;
	if (_v0.$ === 2) {
		if (_v0.a.$ === 1) {
			var payload = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Task$Messages$CmdReq(
					$author$project$App$Task$Messages$ToPost(payload)));
		} else {
			var task = _v0.a.a;
			return $elm$core$Maybe$Just(
				$author$project$App$Task$Messages$CmdReq(
					$author$project$App$Task$Messages$ToPut(task)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Task$Update$updateDone = function (model) {
	var task = model.fI;
	var ongoingEffort = model.eZ;
	if (!task.$) {
		var t = task.a;
		var done = t.gx;
		return _Utils_update(
			model,
			{
				eZ: ((!done) && (!_Utils_eq(ongoingEffort, $elm$core$Maybe$Nothing))) ? $elm$core$Maybe$Nothing : ongoingEffort,
				fI: $author$project$Models$Project$Is(
					_Utils_update(
						t,
						{gx: !done}))
			});
	} else {
		return model;
	}
};
var $author$project$App$Task$Update$updateUponEffort = F2(
	function (model, effortExp) {
		var _v0 = model.fI;
		if (!_v0.$) {
			var id = _v0.a.eC;
			return _Utils_eq(id, effortExp.hI) ? _Utils_update(
				model,
				{
					eZ: $elm$core$Maybe$Just(effortExp)
				}) : model;
		} else {
			return model;
		}
	});
var $author$project$App$Task$Update$update = F2(
	function (msg, model) {
		var form = model.ba;
		var task = model.fI;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
			case 1:
				if (!msg.a.$) {
					var t = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: $elm$core$Maybe$Nothing,
								fI: $author$project$Models$Project$Is(t)
							}),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 2:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 3:
				if (!msg.a.$) {
					var t = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: $elm$core$Maybe$Nothing,
								fI: $author$project$Models$Project$Is(t)
							}),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						$author$project$App$Task$Update$updateDone(model),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ba: $elm$core$Maybe$Just(
								$author$project$App$Form$Model$initTaskPotential(task)),
							eO: false
						}),
					$elm$core$Maybe$Nothing);
			case 9:
				var fMsg = msg.a;
				return A3($author$project$App$Form$Update$updateExt, $author$project$App$Task$Update$processSubmit, fMsg, model);
			case 6:
				if (!msg.a.$) {
					var effortExp = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$App$Task$Update$updateUponEffort, model, effortExp),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eZ: $elm$core$Maybe$Nothing}),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eO: !model.eO}),
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$App$TaskedObjective$Update$updateTask = F3(
	function (model, maybeTaskId, tModel) {
		if (!maybeTaskId.$) {
			var tId = maybeTaskId.a;
			return _Utils_update(
				model,
				{
					hL: A3($elm$core$Dict$insert, tId, tModel, model.hL)
				});
		} else {
			return _Utils_update(
				model,
				{
					bR: _Utils_eq(tModel.ba, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(tModel)
				});
		}
	});
var $author$project$App$TaskedObjective$Update$updateTasks = F4(
	function (model, maybeTaskId, tMsg, tModel) {
		_v0$2:
		while (true) {
			switch (tMsg.$) {
				case 2:
					if (!tMsg.a.$) {
						var task = tMsg.a.a;
						var newTModel = $author$project$App$Task$Model$init(
							$author$project$Models$Project$Is(task));
						return _Utils_update(
							model,
							{
								bR: $elm$core$Maybe$Nothing,
								hL: A3($elm$core$Dict$insert, task.eC, newTModel, model.hL)
							});
					} else {
						break _v0$2;
					}
				case 5:
					if (!tMsg.a.$) {
						var op = function (tId) {
							return A2($elm$core$Dict$remove, tId, model.hL);
						};
						return _Utils_update(
							model,
							{
								hL: A2(
									$elm$core$Maybe$withDefault,
									model.hL,
									A2($elm$core$Maybe$map, op, maybeTaskId))
							});
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return A3($author$project$App$TaskedObjective$Update$updateTask, model, maybeTaskId, tModel);
	});
var $author$project$App$TaskedObjective$Update$update = F2(
	function (msg, model) {
		var objective = model.eV;
		var tasks = model.hL;
		var newTask = model.bR;
		var hiddenTasks = model.aq;
		var menu = model.eO;
		switch (msg.$) {
			case 0:
				var doneTaskIds = $author$project$App$TaskedObjective$Model$getDoneTaskIds(tasks);
				var hiddenTasksCount = $elm$core$Set$size(
					A2($elm$core$Set$intersect, hiddenTasks, doneTaskIds));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aq: (hiddenTasksCount > 0) ? A2($elm$core$Set$diff, hiddenTasks, doneTaskIds) : A2($elm$core$Set$union, hiddenTasks, doneTaskIds),
							eO: false
						}),
					$elm$core$Maybe$Nothing);
			case 1:
				var taskIds = $elm$core$Set$fromList(
					$elm$core$Dict$keys(tasks));
				var isHidden = _Utils_eq(
					A2($elm$core$Set$intersect, hiddenTasks, taskIds),
					taskIds);
				var doneTaskIds = $author$project$App$TaskedObjective$Model$getDoneTaskIds(tasks);
				var undoneTaskIds = A2($elm$core$Set$diff, taskIds, doneTaskIds);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aq: isHidden ? A2($elm$core$Set$diff, hiddenTasks, undoneTaskIds) : A2($elm$core$Set$union, hiddenTasks, taskIds)
						}),
					$elm$core$Maybe$Nothing);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eO: !menu}),
					$elm$core$Maybe$Nothing);
			case 3:
				var _v1 = objective.eV;
				if (!_v1.$) {
					var id = _v1.a.eC;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bR: $elm$core$Maybe$Just(
									$author$project$App$Task$Model$init(
										$author$project$Models$Project$ToBe(
											$author$project$Models$Project$initTaskPayload(id))))
							}),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
				}
			case 4:
				var oMsg = msg.a;
				var _v2 = A2($author$project$App$Objective$Update$update, oMsg, objective);
				var oModel = _v2.a;
				var maybeOMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eO: false, eV: oModel}),
					A2($elm$core$Maybe$map, $author$project$App$TaskedObjective$Messages$ObjectiveMsg, maybeOMsg));
			default:
				var maybeTaskId = msg.a;
				var tMsg = msg.b;
				var tModel = A2($author$project$App$TaskedObjective$Update$getTaskModel, model, maybeTaskId);
				var _v3 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(tModel, $elm$core$Maybe$Nothing),
					A2(
						$elm$core$Maybe$map,
						function (_v4) {
							var mo = _v4.a;
							var ms = _v4.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(mo),
								ms);
						},
						A2(
							$elm$core$Maybe$map,
							$author$project$App$Task$Update$update(tMsg),
							tModel)));
				var tModelUpdated = _v3.a;
				var maybeTMsg = _v3.b;
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$withDefault,
						model,
						A2(
							$elm$core$Maybe$map,
							A3($author$project$App$TaskedObjective$Update$updateTasks, model, maybeTaskId, tMsg),
							tModelUpdated)),
					A2(
						$elm$core$Maybe$map,
						$author$project$App$TaskedObjective$Messages$TaskMsg(maybeTaskId),
						maybeTMsg));
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$App$Project$Model$getOngoingTaskIdsOrdered = function (ongoingEfforts) {
	return A2(
		$elm$core$List$map,
		function ($) {
			return $.hI;
		},
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.hz;
			},
			$elm$core$Dict$values(ongoingEfforts)));
};
var $author$project$App$Project$Model$getNextTaskId = F2(
	function (_v0, inc) {
		var ongoingEfforts = _v0.e_;
		var doing = _v0.ed;
		var taskIdsOrdered = $author$project$App$Project$Model$getOngoingTaskIdsOrdered(ongoingEfforts);
		var count = $elm$core$List$length(taskIdsOrdered);
		var indices = A2($elm$core$List$range, 1, count);
		var indexDict = $elm$core$Dict$fromList(
			A3($elm$core$List$map2, $elm$core$Tuple$pair, taskIdsOrdered, indices));
		var indById = function (id) {
			return A2($elm$core$Dict$get, id, indexDict);
		};
		var indexDictInv = $elm$core$Dict$fromList(
			A3($elm$core$List$map2, $elm$core$Tuple$pair, indices, taskIdsOrdered));
		var idByInd = function (i) {
			return A2($elm$core$Dict$get, i, indexDictInv);
		};
		var mapNextIndex = function (i) {
			return ((i + inc) < 1) ? count : ((_Utils_cmp(i + inc, count) > 0) ? 1 : (i + inc));
		};
		return A2(
			$elm$core$Maybe$andThen,
			idByInd,
			A2(
				$elm$core$Maybe$map,
				mapNextIndex,
				A2($elm$core$Maybe$andThen, indById, doing)));
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Project$Update$updateDoing = F2(
	function (dMsg, model) {
		var doing = model.ed;
		var ongoingEfforts = model.e_;
		if (!dMsg.$) {
			var inc = dMsg.a;
			return _Utils_update(
				model,
				{
					ed: A2($author$project$App$Project$Model$getNextTaskId, model, inc)
				});
		} else {
			return _Utils_update(
				model,
				{
					ed: _Utils_eq(doing, $elm$core$Maybe$Nothing) ? $elm$core$List$head(
						$author$project$App$Project$Model$getOngoingTaskIdsOrdered(ongoingEfforts)) : $elm$core$Maybe$Nothing
				});
		}
	});
var $author$project$App$Metrics$Model$init = function (maybeTCs) {
	return {eA: _List_Nil, fK: maybeTCs};
};
var $author$project$Models$Project$initProjectPayload = {hS: ''};
var $author$project$App$Form$Model$initProjectToBeForm = $author$project$App$Form$Model$init(
	$author$project$App$Form$Model$Project(
		$author$project$Models$Project$ToBe($author$project$Models$Project$initProjectPayload)));
var $author$project$App$Project$Update$updateProject = F2(
	function (pMsg, model) {
		var projects = model.fd;
		_v0$5:
		while (true) {
			switch (pMsg.$) {
				case 0:
					var modelReset = $author$project$App$Project$Update$resetProjectSpecific(model);
					return _Utils_update(
						modelReset,
						{
							ba: $elm$core$Maybe$Just($author$project$App$Form$Model$initProjectToBeForm),
							fa: $elm$core$Maybe$Just(
								$author$project$Models$Project$ToBe($author$project$Models$Project$initProjectPayload))
						});
				case 1:
					if (!pMsg.a.$) {
						var pWS = pMsg.a.a;
						return _Utils_update(
							model,
							{
								eP: $elm$core$Dict$fromList(
									A2(
										$elm$core$List$map,
										function (pTC) {
											return _Utils_Tuple2(
												pTC.de,
												$author$project$App$Metrics$Model$init(pTC.fK));
										},
										pWS.hi)),
								fb: $elm$core$Dict$fromList(
									A2(
										$elm$core$List$map,
										function (pS) {
											return _Utils_Tuple2(pS.de, pS);
										},
										pWS.fb)),
								fd: pWS.fd
							});
					} else {
						break _v0$5;
					}
				case 2:
					if (!pMsg.a.$) {
						var proj = pMsg.a.a;
						return _Utils_update(
							model,
							{
								ba: $elm$core$Maybe$Nothing,
								fa: $elm$core$Maybe$Just(
									$author$project$Models$Project$Is(proj)),
								fd: A2(
									$elm$core$List$append,
									projects,
									_List_fromArray(
										[proj]))
							});
					} else {
						break _v0$5;
					}
				case 3:
					if (!pMsg.a.$) {
						var proj = pMsg.a.a;
						var updateFnc = function (p) {
							return _Utils_eq(p.eC, proj.eC) ? proj : p;
						};
						return _Utils_update(
							model,
							{
								ba: $elm$core$Maybe$Nothing,
								fa: $elm$core$Maybe$Just(
									$author$project$Models$Project$Is(proj)),
								fd: A2($elm$core$List$map, updateFnc, model.fd)
							});
					} else {
						break _v0$5;
					}
				default:
					if (!pMsg.b.$) {
						var pId = pMsg.a;
						return model;
					} else {
						break _v0$5;
					}
			}
		}
		return model;
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm_community$list_extra$List$Extra$gatherWith = F2(
	function (testFn, list) {
		var helper = F2(
			function (scattered, gathered) {
				helper:
				while (true) {
					if (!scattered.b) {
						return $elm$core$List$reverse(gathered);
					} else {
						var toGather = scattered.a;
						var population = scattered.b;
						var _v1 = A2(
							$elm$core$List$partition,
							testFn(toGather),
							population);
						var gathering = _v1.a;
						var remaining = _v1.b;
						var $temp$scattered = remaining,
							$temp$gathered = A2(
							$elm$core$List$cons,
							_Utils_Tuple2(toGather, gathering),
							gathered);
						scattered = $temp$scattered;
						gathered = $temp$gathered;
						continue helper;
					}
				}
			});
		return A2(helper, list, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$gatherEqualsBy = F2(
	function (extract, list) {
		return A2(
			$elm_community$list_extra$List$Extra$gatherWith,
			F2(
				function (a, b) {
					return _Utils_eq(
						extract(a),
						extract(b));
				}),
			list);
	});
var $author$project$App$TaskedObjective$Update$updateUponOngoingEfforts = F2(
	function (model, effortExps) {
		var op = F2(
			function (eX, m) {
				var taskId = eX.hI;
				var tasks = m.hL;
				var _v0 = A2($elm$core$Dict$get, taskId, tasks);
				if (!_v0.$) {
					var tModel = _v0.a;
					var tModelUpdated = A2($author$project$App$Task$Update$updateUponEffort, tModel, eX);
					return _Utils_update(
						m,
						{
							hL: A3($elm$core$Dict$insert, taskId, tModelUpdated, tasks)
						});
				} else {
					return m;
				}
			});
		return A3($elm$core$List$foldr, op, model, effortExps);
	});
var $author$project$App$Project$Update$updateTaskedObjectivedUponOngoingEfforts = F2(
	function (model, effortExps) {
		var op = F2(
			function (_v1, m) {
				var eX0 = _v1.a;
				var objectiveId = eX0.g9;
				var eXs = _v1.b;
				var taskedObjectives = m.hK;
				var _v0 = A2($elm$core$Dict$get, objectiveId, taskedObjectives);
				if (!_v0.$) {
					var tOModel = _v0.a;
					var tOModelUpdated = A2(
						$author$project$App$TaskedObjective$Update$updateUponOngoingEfforts,
						tOModel,
						A2($elm$core$List$cons, eX0, eXs));
					return _Utils_update(
						m,
						{
							hK: A3($elm$core$Dict$insert, objectiveId, tOModelUpdated, taskedObjectives)
						});
				} else {
					return m;
				}
			});
		return A3(
			$elm$core$List$foldr,
			op,
			model,
			A2(
				$elm_community$list_extra$List$Extra$gatherEqualsBy,
				function ($) {
					return $.g9;
				},
				effortExps));
	});
var $author$project$App$TaskedObjective$Model$initWithObjective = function (objective) {
	return {
		d6: $elm$core$Dict$empty,
		aq: $elm$core$Set$empty,
		eO: false,
		bR: $elm$core$Maybe$Nothing,
		eV: $author$project$App$Objective$Model$init(
			$author$project$Models$Project$Is(objective)),
		hL: $elm$core$Dict$empty
	};
};
var $author$project$App$Project$Update$updateTaskedObjective = F3(
	function (model, maybeOId, tOModel) {
		if (!maybeOId.$) {
			var oId = maybeOId.a;
			return _Utils_update(
				model,
				{
					hK: A3($elm$core$Dict$insert, oId, tOModel, model.hK)
				});
		} else {
			return _Utils_update(
				model,
				{
					eU: _Utils_eq(tOModel.eV.ba, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(tOModel)
				});
		}
	});
var $author$project$App$Project$Update$updateTaskedObjectives = F4(
	function (model, maybeOId, tOMsg, tOModel) {
		var ongoingEfforts = model.e_;
		var doing = model.ed;
		var taskedObjectives = model.hK;
		_v0$4:
		while (true) {
			switch (tOMsg.$) {
				case 4:
					if ((tOMsg.a.$ === 1) && (!tOMsg.a.a.$)) {
						var objective = tOMsg.a.a.a;
						var newTOModel = $author$project$App$TaskedObjective$Model$initWithObjective(objective);
						return _Utils_update(
							model,
							{
								eU: $elm$core$Maybe$Nothing,
								hK: A3($elm$core$Dict$insert, objective.eC, newTOModel, taskedObjectives)
							});
					} else {
						break _v0$4;
					}
				case 5:
					if (!tOMsg.a.$) {
						switch (tOMsg.b.$) {
							case 4:
								if (!tOMsg.b.a.$) {
									var taskId = tOMsg.a.a;
									return A3(
										$author$project$App$Project$Update$updateTaskedObjective,
										_Utils_update(
											model,
											{
												ed: _Utils_eq(
													doing,
													$elm$core$Maybe$Just(taskId)) ? A2($author$project$App$Project$Model$getNextTaskId, model, 1) : doing,
												e_: A2($elm$core$Dict$remove, taskId, ongoingEfforts)
											}),
										maybeOId,
										tOModel);
								} else {
									break _v0$4;
								}
							case 6:
								if (!tOMsg.b.a.$) {
									var taskId = tOMsg.a.a;
									var effortExp = tOMsg.b.a.a;
									return A3(
										$author$project$App$Project$Update$updateTaskedObjective,
										_Utils_update(
											model,
											{
												e_: A3($elm$core$Dict$insert, taskId, effortExp, ongoingEfforts)
											}),
										maybeOId,
										tOModel);
								} else {
									break _v0$4;
								}
							case 7:
								if (!tOMsg.b.a.$) {
									var taskId = tOMsg.a.a;
									return A3(
										$author$project$App$Project$Update$updateTaskedObjective,
										_Utils_update(
											model,
											{
												ed: _Utils_eq(
													doing,
													$elm$core$Maybe$Just(taskId)) ? A2($author$project$App$Project$Model$getNextTaskId, model, 1) : doing,
												e_: A2($elm$core$Dict$remove, taskId, model.e_)
											}),
										maybeOId,
										tOModel);
								} else {
									break _v0$4;
								}
							default:
								break _v0$4;
						}
					} else {
						break _v0$4;
					}
				default:
					break _v0$4;
			}
		}
		return A3($author$project$App$Project$Update$updateTaskedObjective, model, maybeOId, tOModel);
	});
var $aforemny$material_components_web_elm$Material$Snackbar$inc = function (_v0) {
	var messageId = _v0;
	return messageId + 1;
};
var $aforemny$material_components_web_elm$Material$Snackbar$addMessage = F2(
	function (message_, _v0) {
		var queue = _v0;
		return _Utils_update(
			queue,
			{
				av: _Utils_ap(
					queue.av,
					_List_fromArray(
						[
							_Utils_Tuple2(queue.bg, message_)
						])),
				bg: $aforemny$material_components_web_elm$Material$Snackbar$inc(queue.bg)
			});
	});
var $aforemny$material_components_web_elm$Material$Snackbar$close = F2(
	function (messageId, _v0) {
		var queue = _v0;
		return _Utils_update(
			queue,
			{
				av: function () {
					var _v1 = queue.av;
					if (!_v1.b) {
						return _List_Nil;
					} else {
						var _v2 = _v1.a;
						var currentMessageId = _v2.a;
						var otherMessages = _v1.b;
						return _Utils_eq(currentMessageId, messageId) ? otherMessages : queue.av;
					}
				}()
			});
	});
var $aforemny$material_components_web_elm$Material$Snackbar$Message = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Snackbar$message = function (label) {
	return {
		cc: $elm$core$Maybe$Nothing,
		cd: $elm$core$Maybe$Nothing,
		g_: label,
		cQ: false,
		c_: $elm$core$Maybe$Nothing,
		c$: $elm$core$Maybe$Nothing,
		du: false,
		dD: $elm$core$Maybe$Just(5000)
	};
};
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$App$Watcher$Update$toggleSupporterChoice = F2(
	function (userId, set) {
		return A2($elm$core$Set$member, userId, set) ? A2($elm$core$Set$remove, userId, set) : A2($elm$core$Set$insert, userId, set);
	});
var $author$project$App$Watcher$Update$updateField = F4(
	function (f, s, source, payload) {
		var user = payload.fT;
		var visibility = payload.dJ;
		var _v0 = _Utils_Tuple2(source, f);
		if (!_v0.a) {
			if (!_v0.b) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return _Utils_update(
					payload,
					{
						fT: _Utils_update(
							user,
							{er: s})
					});
			} else {
				var _v3 = _v0.a;
				var _v4 = _v0.b;
				return _Utils_update(
					payload,
					{
						fT: _Utils_update(
							user,
							{gB: s})
					});
			}
		} else {
			return payload;
		}
	});
var $author$project$App$Watcher$Messages$FromSupporters = 1;
var $author$project$App$Watcher$Model$getNonWatcherSupporters = function (_v0) {
	var watchers = _v0.dK;
	var supporters = _v0.dA;
	var notAWatcher = function (_v1) {
		var id = _v1.eC;
		return !A2(
			$elm$core$Set$member,
			id,
			$elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.eC;
					},
					A2(
						$elm$core$List$map,
						function ($) {
							return $.fT;
						},
						watchers))));
	};
	return A2($elm$core$List$filter, notAWatcher, supporters);
};
var $author$project$App$Watcher$Update$updateSource = function (model) {
	var nonWatcherSupporters = $author$project$App$Watcher$Model$getNonWatcherSupporters(model);
	return _Utils_update(
		model,
		{
			fA: ($elm$core$List$length(nonWatcherSupporters) > 0) ? 1 : 0
		});
};
var $author$project$App$Watcher$Update$updateUponPosted = F3(
	function (model, isNew, watcherUser) {
		var supporters = model.dA;
		var watchers = model.dK;
		return _Utils_update(
			model,
			{
				fG: (!isNew) ? A2($elm$core$Set$remove, watcherUser.fT.eC, model.fG) : model.fG,
				dA: isNew ? A2(
					$elm$core$List$append,
					supporters,
					_List_fromArray(
						[watcherUser.fT])) : supporters,
				dK: A2(
					$elm$core$List$append,
					watchers,
					_List_fromArray(
						[watcherUser]))
			});
	});
var $author$project$App$Watcher$Update$update = F2(
	function (msg, model) {
		var projectId = model.de;
		var supporters = model.dA;
		var watchers = model.dK;
		var source = model.fA;
		var payload = model.e2;
		var supporterUserIdSet = model.fG;
		switch (msg.$) {
			case 0:
				return model;
			case 1:
				if (!msg.a.$) {
					var sups = msg.a.a;
					return $author$project$App$Watcher$Update$updateSource(
						_Utils_update(
							model,
							{dA: sups}));
				} else {
					return model;
				}
			case 2:
				if (!msg.a.$) {
					var wats = msg.a.a;
					return $author$project$App$Watcher$Update$updateSource(
						_Utils_update(
							model,
							{dK: wats}));
				} else {
					return model;
				}
			case 3:
				if (!msg.b.$) {
					var isNew = msg.a;
					var watcherUser = msg.b.a;
					return $author$project$App$Watcher$Update$updateSource(
						A3($author$project$App$Watcher$Update$updateUponPosted, model, isNew, watcherUser));
				} else {
					return model;
				}
			case 4:
				if (!msg.b.$) {
					var uId = msg.a;
					var filterFnc = function (_v1) {
						var user = _v1.fT;
						return !_Utils_eq(user.eC, uId);
					};
					return _Utils_update(
						model,
						{
							dK: A2($elm$core$List$filter, filterFnc, model.dK)
						});
				} else {
					var uId = msg.a;
					return model;
				}
			case 5:
				var f = msg.a;
				var s = msg.b;
				return _Utils_update(
					model,
					{
						e2: A4($author$project$App$Watcher$Update$updateField, f, s, source, payload)
					});
			case 6:
				return model;
			case 7:
				var s = msg.a;
				var snackbarMsg = $aforemny$material_components_web_elm$Material$Snackbar$message(s);
				return _Utils_update(
					model,
					{
						fw: A2($aforemny$material_components_web_elm$Material$Snackbar$addMessage, snackbarMsg, model.fw)
					});
			case 8:
				var msgId = msg.a;
				return _Utils_update(
					model,
					{
						fw: A2($aforemny$material_components_web_elm$Material$Snackbar$close, msgId, model.fw)
					});
			case 9:
				var src = msg.a;
				return _Utils_update(
					model,
					{fA: src});
			case 10:
				var userId = msg.a;
				return _Utils_update(
					model,
					{
						fG: A2($author$project$App$Watcher$Update$toggleSupporterChoice, userId, supporterUserIdSet)
					});
			default:
				var v = msg.a;
				return _Utils_update(
					model,
					{
						e2: _Utils_update(
							payload,
							{dJ: v})
					});
		}
	});
var $author$project$App$Project$Update$updateWatcher = F2(
	function (model, wMsg) {
		return _Utils_update(
			model,
			{
				ia: function () {
					if (wMsg.$ === 6) {
						return $elm$core$Maybe$Nothing;
					} else {
						return A2(
							$elm$core$Maybe$map,
							$author$project$App$Watcher$Update$update(wMsg),
							model.ia);
					}
				}()
			});
	});
var $author$project$App$Watcher$Messages$Deleted = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Encoders$visibilityEncoder = function (visibility) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (visibility) {
				case 0:
					return 'Project';
				case 1:
					return 'Objective';
				default:
					return 'Task';
			}
		}());
};
var $author$project$Encoders$watcherPayloadEncoder = function (_v0) {
	var userId = _v0.fU;
	var visibility = _v0.dJ;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'userId',
				$elm$json$Json$Encode$int(userId)),
				_Utils_Tuple2(
				'visibility',
				$author$project$Encoders$visibilityEncoder(visibility))
			]));
};
var $author$project$App$Watcher$Commands$deleteWatcher = F2(
	function (pId, payload) {
		return {
			cn: $elm$core$Maybe$Just(
				$author$project$Encoders$watcherPayloadEncoder(payload)),
			cz: $elm$http$Http$expectWhatever(
				$author$project$App$Watcher$Messages$Deleted(payload.fU)),
			cS: 'DELETE',
			fS: '/project/' + ($elm$core$String$fromInt(pId) + '/watcher')
		};
	});
var $author$project$App$Watcher$Messages$Posted = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Encoders$userPayloadEncoder = function (_v0) {
	var email = _v0.gB;
	var firstName = _v0.er;
	var lastName = _v0.g$;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(email)),
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(firstName)),
				_Utils_Tuple2(
				'lastName',
				A2($author$project$Encoders$maybeEncoder, $elm$json$Json$Encode$string, lastName))
			]));
};
var $author$project$Encoders$supporterWatcherPayloadEncoder = function (_v0) {
	var user = _v0.fT;
	var visibility = _v0.dJ;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$author$project$Encoders$userPayloadEncoder(user)),
				_Utils_Tuple2(
				'visibility',
				$author$project$Encoders$visibilityEncoder(visibility))
			]));
};
var $author$project$App$Watcher$Commands$postSupporterWatcher = F2(
	function (pId, payload) {
		return {
			cn: $elm$core$Maybe$Just(
				$author$project$Encoders$supporterWatcherPayloadEncoder(payload)),
			cz: A2(
				$elm$http$Http$expectJson,
				$author$project$App$Watcher$Messages$Posted(true),
				$author$project$Decoders$watcherUserDecoder),
			cS: 'POST',
			fS: '/project/' + ($elm$core$String$fromInt(pId) + '/supporter_watcher')
		};
	});
var $author$project$App$Watcher$Commands$postWatcher = F2(
	function (pId, payload) {
		return {
			cn: $elm$core$Maybe$Just(
				$author$project$Encoders$watcherPayloadEncoder(payload)),
			cz: A2(
				$elm$http$Http$expectJson,
				$author$project$App$Watcher$Messages$Posted(false),
				$author$project$Decoders$watcherUserDecoder),
			cS: 'POST',
			fS: '/project/' + ($elm$core$String$fromInt(pId) + '/watcher')
		};
	});
var $author$project$App$Watcher$Commands$cmdReqToReqReq = function (cmdReq) {
	switch (cmdReq.$) {
		case 0:
			var pId = cmdReq.a;
			var payload = cmdReq.b;
			return _List_fromArray(
				[
					A2($author$project$App$Watcher$Commands$postSupporterWatcher, pId, payload)
				]);
		case 1:
			var pId = cmdReq.a;
			var payloads = cmdReq.b;
			return A2(
				$elm$core$List$map,
				$author$project$App$Watcher$Commands$postWatcher(pId),
				payloads);
		default:
			var pId = cmdReq.a;
			var payload = cmdReq.b;
			return _List_fromArray(
				[
					A2($author$project$App$Watcher$Commands$deleteWatcher, pId, payload)
				]);
	}
};
var $author$project$App$Project$Update$watcherCmdReqs = F2(
	function (access, wMsg) {
		var doReq = $author$project$Api$dealWithMaybeAccess(access);
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$App$Project$Messages$WatcherMsg,
			function () {
				if (!wMsg.$) {
					var cmdReqs = wMsg.a;
					return $elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							doReq,
							$author$project$App$Watcher$Commands$cmdReqToReqReq(cmdReqs)));
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			}());
	});
var $author$project$App$Project$Update$update = F2(
	function (msg, model) {
		update:
		while (true) {
			var doReq = $author$project$Api$dealWithMaybeAccess(model.dR);
			var _v0 = model;
			var access = _v0.dR;
			var taskedObjectives = _v0.hK;
			var watcher = _v0.ia;
			var newObjective = _v0.eU;
			var project = _v0.fa;
			var projects = _v0.fd;
			switch (msg.$) {
				case 0:
					var cmdReq = msg.a;
					return _Utils_Tuple2(
						model,
						doReq(
							$author$project$App$Project$Commands$cmdReqToReqReq(cmdReq)));
				case 1:
					var pMsg = msg.a;
					return _Utils_Tuple2(
						A2($author$project$App$Project$Update$updateProject, pMsg, model),
						$elm$core$Platform$Cmd$none);
				case 2:
					var proj = msg.a;
					var modelReset = $author$project$App$Project$Update$resetProjectSpecific(model);
					return _Utils_Tuple2(
						_Utils_update(
							modelReset,
							{
								fa: $elm$core$Maybe$Just(
									$author$project$Models$Project$Is(proj))
							}),
						doReq(
							$author$project$App$Project$Commands$getTaskedObjectives(proj.eC)));
				case 3:
					var dMsg = msg.a;
					return _Utils_Tuple2(
						A2($author$project$App$Project$Update$updateDoing, dMsg, model),
						$elm$core$Platform$Cmd$none);
				case 4:
					if (!msg.a.$) {
						var tOs = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									hK: $author$project$App$TaskedObjective$Model$listToDict(tOs)
								}),
							A2(
								$elm$core$Maybe$withDefault,
								$elm$core$Platform$Cmd$none,
								A2(
									$elm$core$Maybe$map,
									doReq,
									A2(
										$elm$core$Maybe$map,
										$author$project$App$Project$Commands$getOngoingByProject,
										$author$project$App$Project$Model$getProjectId(model)))));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 5:
					var maybeOId = msg.a;
					var tOMsg = msg.b;
					var tOModel = A2($author$project$App$Project$Update$getTaskedObjectiveModel, model, maybeOId);
					var _v2 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(tOModel, $elm$core$Maybe$Nothing),
						A2(
							$elm$core$Maybe$map,
							function (_v3) {
								var mo = _v3.a;
								var ms = _v3.b;
								return _Utils_Tuple2(
									$elm$core$Maybe$Just(mo),
									ms);
							},
							A2(
								$elm$core$Maybe$map,
								$author$project$App$TaskedObjective$Update$update(tOMsg),
								tOModel)));
					var tOModelUpdated = _v2.a;
					var maybeTOMsg = _v2.b;
					var modelUpdated = A2(
						$elm$core$Maybe$withDefault,
						model,
						A2(
							$elm$core$Maybe$map,
							A3($author$project$App$Project$Update$updateTaskedObjectives, model, maybeOId, tOMsg),
							tOModelUpdated));
					if (!maybeTOMsg.$) {
						var newTOMsg = maybeTOMsg.a;
						var $temp$msg = A2($author$project$App$Project$Messages$TaskedObjectiveMsg, maybeOId, newTOMsg),
							$temp$model = modelUpdated;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(
							modelUpdated,
							A3($author$project$App$Project$Update$taskedObjectiveCmdReq, access, maybeOId, tOMsg));
					}
				case 6:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eU: A2(
									$elm$core$Maybe$map,
									$author$project$App$TaskedObjective$Model$initForNewObjective,
									$author$project$App$Project$Model$getProjectId(model))
							}),
						$elm$core$Platform$Cmd$none);
				case 7:
					if (!msg.a.$) {
						var effortExps = msg.a.a;
						return _Utils_Tuple2(
							A2(
								$author$project$App$Project$Update$updateTaskedObjectivedUponOngoingEfforts,
								_Utils_update(
									model,
									{
										e_: $elm$core$Dict$fromList(
											A2(
												$elm$core$List$map,
												function (e) {
													return _Utils_Tuple2(e.hI, e);
												},
												effortExps))
									}),
								effortExps),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 8:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: A2($elm$core$Maybe$map, $author$project$App$Form$Model$initProjectPotential, project)
							}),
						$elm$core$Platform$Cmd$none);
				case 9:
					var fMsg = msg.a;
					var _v5 = A3($author$project$App$Form$Update$updateExt, $author$project$App$Project$Update$processSubmit, fMsg, model);
					var modelUpdated = _v5.a;
					var maybeMsg = _v5.b;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(modelUpdated, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (m) {
								return A2($author$project$App$Project$Update$update, m, modelUpdated);
							},
							maybeMsg));
				case 10:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eO: !model.eO}),
						$elm$core$Platform$Cmd$none);
				case 11:
					var pId = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(
									$author$project$App$Watcher$Model$init(pId))
							}),
						$elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$map,
								$elm$core$Platform$Cmd$map($author$project$App$Project$Messages$WatcherMsg),
								A2(
									$elm$core$List$map,
									doReq,
									_List_fromArray(
										[
											$author$project$App$Watcher$Commands$getWatchers(pId),
											$author$project$App$Watcher$Commands$getSupporters
										])))));
				case 12:
					var wMsg = msg.a;
					return _Utils_Tuple2(
						A2($author$project$App$Project$Update$updateWatcher, model, wMsg),
						A2($author$project$App$Project$Update$watcherCmdReqs, access, wMsg));
				default:
					var pId = msg.a;
					var mMsg = msg.b;
					var doUpdate = function (m) {
						return _Utils_update(
							model,
							{
								eP: A3($elm$core$Dict$insert, pId, m, model.eP)
							});
					};
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$withDefault,
							model,
							A2(
								$elm$core$Maybe$map,
								doUpdate,
								A2(
									$elm$core$Maybe$map,
									$author$project$App$Metrics$Update$update(mMsg),
									A2($elm$core$Dict$get, pId, model.eP)))),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Models$Social$gatherReactionExps = function (rE) {
	var mapFnc = function (_v0) {
		var a = _v0.a;
		var bs = _v0.b;
		return {
			f3: a.f3,
			eM: A2(
				$elm$core$List$cons,
				a.g1,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.g1;
					},
					bs)),
			g9: a.g9,
			e0: a.e0,
			de: a.de,
			hk: a.hk,
			fh: A2(
				$elm$core$List$cons,
				a.eC,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.eC;
					},
					bs)),
			hJ: $elm$core$Set$fromList(
				A2(
					$elm$core$List$cons,
					a.hI,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.hI;
						},
						bs))),
			ib: a.ib
		};
	};
	var gatherFnc = F2(
		function (a, b) {
			return _Utils_eq(a.e0, b.e0) && (_Utils_eq(a.de, b.de) && (_Utils_eq(a.g9, b.g9) && (_Utils_eq(a.f3, b.f3) && (_Utils_eq(a.ib, b.ib) && _Utils_eq(a.hk, b.hk)))));
		});
	return A2(
		$elm$core$List$map,
		mapFnc,
		A2($elm_community$list_extra$List$Extra$gatherWith, gatherFnc, rE));
};
var $author$project$App$Social$Messages$PostedReaction = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Encoders$reactionPayloadEncoder = function (_v0) {
	var reaction = _v0.hk;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'reaction',
				$elm$json$Json$Encode$string(reaction))
			]));
};
var $author$project$App$Social$Commands$postReaction = F2(
	function (reaction, lId) {
		return {
			cn: $elm$core$Maybe$Just(
				$author$project$Encoders$reactionPayloadEncoder(
					{hk: reaction})),
			cz: $elm$http$Http$expectWhatever(
				$author$project$App$Social$Messages$PostedReaction(lId)),
			cS: 'POST',
			fS: '/task_log/' + ($elm$core$String$fromInt(lId) + '/react')
		};
	});
var $author$project$App$Social$Messages$WatcherRSVPPut = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Encoders$watcherRSVPPayloadEncoder = function (_v0) {
	var userAgreed = _v0.h6;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'userAgreed',
				$elm$json$Json$Encode$bool(userAgreed))
			]));
};
var $author$project$App$Social$Commands$putWatcherRSVP = F2(
	function (wId, rsvp) {
		return {
			cn: $elm$core$Maybe$Just(
				$author$project$Encoders$watcherRSVPPayloadEncoder(
					{h6: rsvp})),
			cz: $elm$http$Http$expectWhatever(
				A2($author$project$App$Social$Messages$WatcherRSVPPut, wId, rsvp)),
			cS: 'PUT',
			fS: '/watcher/' + ($elm$core$String$fromInt(wId) + '/rsvp')
		};
	});
var $author$project$App$Social$Update$updateSupporterSocialOnReact = F2(
	function (logId, sS) {
		return _Utils_update(
			sS,
			{
				hl: A2(
					$elm$core$List$filter,
					function (rE) {
						return !_Utils_eq(rE.g1, logId);
					},
					sS.hl)
			});
	});
var $author$project$App$Social$Update$updateSupporterSummaryOnReact = F2(
	function (logId, reactionExpSumms) {
		var filterMapFnc = function (rES) {
			var _v0 = A2(
				$elm$core$List$filter,
				function (lId) {
					return !_Utils_eq(lId, logId);
				},
				rES.eM);
			if (!_v0.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var ids = _v0;
				return $elm$core$Maybe$Just(
					_Utils_update(
						rES,
						{eM: ids}));
			}
		};
		return A2($elm$core$List$filterMap, filterMapFnc, reactionExpSumms);
	});
var $author$project$App$Social$Update$update = F2(
	function (msg, model) {
		var doReq = $author$project$Api$dealWithMaybeAccess(model.dR);
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var sS = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dz: $elm$core$Maybe$Just(sS),
								fF: $author$project$Models$Social$gatherReactionExps(sS.hl)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var sS = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dy: $elm$core$Maybe$Just(sS),
								fE: $author$project$Models$Social$gatherReactionExps(sS.fi)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var wId = msg.a;
				var rsvp = msg.b;
				return _Utils_Tuple2(
					model,
					doReq(
						A2($author$project$App$Social$Commands$putWatcherRSVP, wId, rsvp)));
			case 3:
				if (!msg.c.$) {
					var wId = msg.a;
					var rsvp = msg.b;
					var updateSS = function (sS) {
						return _Utils_update(
							sS,
							{
								ic: A2(
									$elm$core$List$filter,
									function (_v1) {
										var id = _v1.eC;
										return !_Utils_eq(wId, id);
									},
									sS.ic)
							});
					};
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dz: A2($elm$core$Maybe$map, updateSS, model.dz)
							}),
						rsvp ? doReq($author$project$App$Social$Commands$getSupporterSocial) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var lIds = msg.a;
				var reaction = msg.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							doReq,
							A2(
								$elm$core$List$map,
								$author$project$App$Social$Commands$postReaction(reaction),
								lIds))));
			default:
				if (!msg.b.$) {
					var id = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dz: A2(
									$elm$core$Maybe$map,
									$author$project$App$Social$Update$updateSupporterSocialOnReact(id),
									model.dz),
								fF: A2($author$project$App$Social$Update$updateSupporterSummaryOnReact, id, model.fF)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var ids = msg.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Models$Local$Data = F2(
	function (userId, token) {
		return {fM: token, fU: userId};
	});
var $author$project$App$Messages$GotAccessData = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$User$Messages$LogInMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$User$Update$doLoad = _Platform_outgoingPort(
	'doLoad',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$App$User$Messages$GotUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$User$Commands$getUser = F2(
	function (userId, token) {
		var url = $author$project$Api$urlPrefix + ('/user/' + $elm$core$String$fromInt(userId));
		var headerVal = 'Bearer ' + token;
		return $elm$http$Http$request(
			{
				cn: $elm$http$Http$emptyBody,
				cz: A2($elm$http$Http$expectJson, $author$project$App$User$Messages$GotUser, $author$project$Decoders$userDecoder),
				gK: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', headerVal)
					]),
				cS: 'GET',
				hQ: $elm$core$Maybe$Nothing,
				h1: $elm$core$Maybe$Nothing,
				h5: url
			});
	});
var $author$project$Decoders$localDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'userId',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Models$Local$Data)));
var $author$project$Encoders$localDataEncoder = function (_v0) {
	var token = _v0.fM;
	var userId = _v0.fU;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'userId',
				$elm$json$Json$Encode$int(userId)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(token))
			]));
};
var $author$project$App$User$Update$makeAccessData = function (_v0) {
	var token = _v0.fM;
	var userId = _v0.fU;
	var _v1 = _Utils_Tuple2(token, userId);
	if ((!_v1.a.$) && (!_v1.b.$)) {
		var t = _v1.a.a;
		var uid = _v1.b.a;
		return $elm$core$Maybe$Just(
			{fM: t, fU: uid});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$User$Update$save = _Platform_outgoingPort('save', $elm$json$Json$Encode$string);
var $author$project$Models$User$PasscodePayload = function (value) {
	return {h7: value};
};
var $author$project$App$User$Messages$Save = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$User$LogIn$Model$SendPasscode = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$User$LogIn$Messages$ShowSnackbar = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$User$LogIn$Model$ValidatePasscode = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$User$LogIn$Messages$GotUserId = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{cn: $elm$http$Http$emptyBody, cz: r.cz, gK: _List_Nil, cS: 'GET', hQ: $elm$core$Maybe$Nothing, h1: $elm$core$Maybe$Nothing, h5: r.h5});
};
var $author$project$Decoders$userIdDecoder = $elm$json$Json$Decode$int;
var $author$project$App$User$LogIn$Commands$getUserId = function (email) {
	var url = $author$project$Api$urlPrefix + '/user/id';
	var query = '?email=' + email;
	return $elm$http$Http$get(
		{
			cz: A2(
				$elm$http$Http$expectJson,
				$author$project$App$User$LogIn$Messages$GotUserId(email),
				$author$project$Decoders$userIdDecoder),
			h5: _Utils_ap(url, query)
		});
};
var $author$project$App$User$LogIn$Messages$PostedPasscodeReq = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{cn: r.cn, cz: r.cz, gK: _List_Nil, cS: 'POST', hQ: $elm$core$Maybe$Nothing, h1: $elm$core$Maybe$Nothing, h5: r.h5});
};
var $author$project$App$User$LogIn$Commands$postPasscodeReq = function (uid) {
	var url = $author$project$Api$urlPrefix + ('/user/' + ($elm$core$String$fromInt(uid) + '/login_passcode'));
	return $elm$http$Http$post(
		{
			cn: $elm$http$Http$emptyBody,
			cz: $elm$http$Http$expectWhatever(
				$author$project$App$User$LogIn$Messages$PostedPasscodeReq(uid)),
			h5: url
		});
};
var $author$project$App$User$LogIn$Messages$ValidatedPasscode = function (a) {
	return {$: 5, a: a};
};
var $author$project$Encoders$passcodePayloadEncoder = function (_v0) {
	var value = _v0.h7;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(value))
			]));
};
var $author$project$Models$User$UserToken = F2(
	function (user, token) {
		return {fM: token, fT: user};
	});
var $author$project$Decoders$userTokenDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'user',
		$author$project$Decoders$userDecoder,
		$elm$json$Json$Decode$succeed($author$project$Models$User$UserToken)));
var $author$project$App$User$LogIn$Commands$validatePasscode = F2(
	function (uid, payload) {
		var url = $author$project$Api$urlPrefix + ('/user/' + ($elm$core$String$fromInt(uid) + '/token'));
		return $elm$http$Http$post(
			{
				cn: $elm$http$Http$jsonBody(
					$author$project$Encoders$passcodePayloadEncoder(payload)),
				cz: A2($elm$http$Http$expectJson, $author$project$App$User$LogIn$Messages$ValidatedPasscode, $author$project$Decoders$userTokenDecoder),
				h5: url
			});
	});
var $author$project$App$User$LogIn$Update$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					switch (msg.a) {
						case 0:
							var _v1 = msg.a;
							var val = msg.b;
							var _v2 = model.ba;
							if (!_v2.$) {
								var f = _v2.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											ba: $author$project$App$User$LogIn$Model$GetUserId(
												_Utils_update(
													f,
													{gB: val}))
										}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
							}
						case 1:
							var _v3 = msg.a;
							var val = msg.b;
							var _v4 = model.ba;
							if (_v4.$ === 2) {
								var f = _v4.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											ba: $author$project$App$User$LogIn$Model$ValidatePasscode(
												_Utils_update(
													f,
													{c8: val}))
										}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
							}
						case 2:
							var _v5 = msg.a;
							var val = msg.b;
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						default:
							var _v6 = msg.a;
							var val = msg.b;
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					}
				case 1:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{fL: true}),
						function () {
							var _v7 = model.ba;
							switch (_v7.$) {
								case 0:
									var email = _v7.a.gB;
									return $author$project$App$User$LogIn$Commands$getUserId(email);
								case 2:
									var userId = _v7.a.fU;
									var passcode = _v7.a.c8;
									return A2(
										$author$project$App$User$LogIn$Commands$validatePasscode,
										userId,
										$author$project$Models$User$PasscodePayload(passcode));
								default:
									return $elm$core$Platform$Cmd$none;
							}
						}(),
						$elm$core$Maybe$Nothing);
				case 2:
					if (!msg.a.$) {
						var user = msg.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ba: $author$project$App$User$LogIn$Model$SendPasscode(
										{gB: user.gB, fU: user.eC})
								}),
							$author$project$App$User$LogIn$Commands$postPasscodeReq(user.eC),
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{fL: false}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				case 3:
					if (!msg.b.$) {
						var email = msg.a;
						var uid = msg.b.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ba: $author$project$App$User$LogIn$Model$SendPasscode(
										{gB: email, fU: uid})
								}),
							$author$project$App$User$LogIn$Commands$postPasscodeReq(uid),
							$elm$core$Maybe$Nothing);
					} else {
						var email = msg.a;
						var modelUpdated = _Utils_update(
							model,
							{fL: false});
						var $temp$msg = $author$project$App$User$LogIn$Messages$ShowSnackbar('unknow email'),
							$temp$model = modelUpdated;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				case 4:
					if (!msg.b.$) {
						var uid = msg.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ba: $author$project$App$User$LogIn$Model$ValidatePasscode(
										{c8: '', fU: uid}),
									fL: false
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{fL: false}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				case 5:
					if (!msg.a.$) {
						var userToken = msg.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									fL: false,
									fV: $elm$core$Maybe$Just(userToken)
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Just(
								$author$project$App$User$Messages$Save(userToken)));
					} else {
						var e = msg.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									fC: $elm$core$Maybe$Just(e),
									fL: false
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				case 6:
					var s = msg.a;
					var snackbarMsg = $aforemny$material_components_web_elm$Material$Snackbar$message(s);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								fw: A2($aforemny$material_components_web_elm$Material$Snackbar$addMessage, snackbarMsg, model.fw)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				default:
					var msgId = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								fw: A2($aforemny$material_components_web_elm$Material$Snackbar$close, msgId, model.fw)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$App$User$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var logInMsg = msg.a;
				var _v1 = A2($author$project$App$User$LogIn$Update$update, logInMsg, model.eN);
				var logInModel = _v1.a;
				var logInCmd = _v1.b;
				var userMsg = _v1.c;
				var model1 = _Utils_update(
					model,
					{eN: logInModel});
				var update1 = function (pMsg) {
					return A2($author$project$App$User$Update$update, pMsg, model1);
				};
				var _v2 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple3(model1, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing),
					A2($elm$core$Maybe$map, update1, userMsg));
				var model2 = _v2.a;
				var userCmd = _v2.b;
				var cmds = _List_fromArray(
					[
						userCmd,
						A2($elm$core$Platform$Cmd$map, $author$project$App$User$Messages$LogInMsg, logInCmd)
					]);
				return _Utils_Tuple3(
					model2,
					$elm$core$Platform$Cmd$batch(cmds),
					$elm$core$Maybe$Nothing);
			case 2:
				var user = msg.a.fT;
				var token = msg.a.fM;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							eN: $author$project$App$User$LogIn$Model$init(false),
							fC: $author$project$App$User$Model$LoggedIn,
							fM: $elm$core$Maybe$Just(token),
							fT: $elm$core$Maybe$Just(user),
							fU: $elm$core$Maybe$Just(user.eC)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$App$User$Update$save(
								A2(
									$elm$json$Json$Encode$encode,
									0,
									$author$project$Encoders$localDataEncoder(
										A2($author$project$Models$Local$Data, user.eC, token)))),
								A2($author$project$App$User$Commands$getUser, user.eC, token)
							])),
					$elm$core$Maybe$Nothing);
			case 4:
				return _Utils_Tuple3(
					model,
					$author$project$App$User$Update$doLoad(0),
					$elm$core$Maybe$Nothing);
			case 3:
				var value = msg.a;
				var _v3 = A2($elm$json$Json$Decode$decodeString, $author$project$Decoders$localDataDecoder, value);
				if (!_v3.$) {
					var userId = _v3.a.fU;
					var token = _v3.a.fM;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								fM: $elm$core$Maybe$Just(token),
								fU: $elm$core$Maybe$Just(userId)
							}),
						A2($author$project$App$User$Commands$getUser, userId, token),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 1:
				if (!msg.a.$) {
					var user = msg.a.a;
					var model1 = _Utils_update(
						model,
						{
							fC: $author$project$App$User$Model$LoggedIn,
							fT: $elm$core$Maybe$Just(user),
							fU: $elm$core$Maybe$Just(user.eC)
						});
					return _Utils_Tuple3(
						model1,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$App$Messages$GotAccessData,
							$author$project$App$User$Update$makeAccessData(model1)));
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple3($author$project$App$User$Model$init, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$App$Update$update = F2(
	function (msg, model) {
		update:
		while (true) {
			var project = model.fa;
			var doReq = $author$project$Api$dealWithMaybeAccess(model.dR);
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 1:
					var url = msg.a;
					var _v1 = _Utils_Tuple2(
						_Utils_update(
							model,
							{h5: url}),
						$elm$core$Platform$Cmd$none);
					var m = _v1.a;
					var c = _v1.b;
					if ((url.$ === 2) && (!url.a.$)) {
						var p = url.a.a;
						var $temp$msg = $author$project$App$Messages$ProjectMsg(
							$author$project$App$Project$Messages$Chose(p)),
							$temp$model = m;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(m, c);
					}
				case 2:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eg: !model.eg}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var sM = A2($elm$core$Maybe$map, $elm$core$Basics$not, model.fz);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fz: sM}),
						$elm$core$Platform$Cmd$none);
				case 4:
					var access = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dR: $elm$core$Maybe$Just(access),
								fa: $author$project$App$Project$Model$init(
									$elm$core$Maybe$Just(access)),
								fy: $author$project$App$Social$Model$init(
									$elm$core$Maybe$Just(access))
							}),
						A2($author$project$App$Update$cmdByMode, access, model.fz));
				case 5:
					var pMsg = msg.a;
					var _v3 = A2($author$project$App$Project$Update$update, pMsg, model.fa);
					var pModel = _v3.a;
					var pCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eg: A2(
									$elm$core$Maybe$withDefault,
									model.eg,
									A2($author$project$App$Update$drawerByProject, pMsg, pModel)),
								fa: pModel
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$ProjectMsg, pCmd));
				case 6:
					var userMsg = msg.a;
					var _v4 = A2($author$project$App$User$Update$update, userMsg, model.fT);
					var userModel = _v4.a;
					var userCmd = _v4.b;
					var appMsg = _v4.c;
					var model1 = _Utils_update(
						model,
						{fT: userModel});
					var update1 = function (pMsg) {
						return A2($author$project$App$Update$update, pMsg, model1);
					};
					var _v5 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model1, $elm$core$Platform$Cmd$none),
						A2($elm$core$Maybe$map, update1, appMsg));
					var model2 = _v5.a;
					var appCmd = _v5.b;
					var cmds = _List_fromArray(
						[
							appCmd,
							A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$UserMsg, userCmd)
						]);
					return _Utils_Tuple2(
						_Utils_update(
							model2,
							{
								h5: $author$project$App$User$Model$isLoggedIn(model2.fT) ? $author$project$Models$App$Default : $author$project$Models$App$Auth
							}),
						$elm$core$Platform$Cmd$batch(cmds));
				default:
					var sMsg = msg.a;
					var _v6 = A2($author$project$App$Social$Update$update, sMsg, model.fy);
					var sModel = _v6.a;
					var sCmd = _v6.b;
					var socialMode = $author$project$App$Social$Model$hasContent(sModel) ? $elm$core$Maybe$Just(
						!$elm$core$List$length(model.fa.fd)) : $elm$core$Maybe$Nothing;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fy: sModel, fz: socialMode}),
						A2($elm$core$Platform$Cmd$map, $author$project$App$Messages$SocialMsg, sCmd));
			}
		}
	});
var $author$project$App$Update$init = function () {
	var msg = $author$project$App$Messages$UserMsg($author$project$App$User$Messages$DoLoad);
	var model = $author$project$App$Model$init;
	return A2($author$project$App$Update$update, msg, model);
}();
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$App$User$Messages$Load = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$User$Update$load = _Platform_incomingPort('load', $elm$json$Json$Decode$string);
var $author$project$App$User$Subscriptions$subscriptions = function (model) {
	return $author$project$App$User$Update$load($author$project$App$User$Messages$Load);
};
var $author$project$App$Subscriptions$subscriptions = function (_v0) {
	var user = _v0.fT;
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$App$Messages$UserMsg,
		$author$project$App$User$Subscriptions$subscriptions(user));
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$App$User$LogIn$Messages$CloseSnackbar = function (a) {
	return {$: 7, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $aforemny$material_components_web_elm$Material$LayoutGrid$cell = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid__cell'),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$LayoutGrid$inner = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid__inner'),
				attributes),
			nodes);
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid = F2(
	function (attributes, nodes) {
		return A3(
			$elm$html$Html$node,
			'mdc-layout-grid',
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-layout-grid'),
				A2(
					$elm$core$List$cons,
					A2($elm$html$Html$Attributes$style, 'display', 'block'),
					attributes)),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$LayoutGrid$span = function (n) {
	return $elm$html$Html$Attributes$class(
		'mdc-layout-grid__cell--span-' + $elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$LayoutGrid$span12 = $aforemny$material_components_web_elm$Material$LayoutGrid$span(12);
var $aforemny$material_components_web_elm$Material$Card$Block = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$block = $elm$core$Basics$identity;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $aforemny$material_components_web_elm$Material$Card$actionsElt = function (content) {
	var _v0 = content.f4;
	if (!_v0.$) {
		var buttons = _v0.a.d1;
		var icons = _v0.a.eB;
		var fullBleed = _v0.a.cF;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class('mdc-card__actions')),
							fullBleed ? $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class('mdc-card__actions--full-bleed')) : $elm$core$Maybe$Nothing
						])),
				$elm$core$List$concat(
					_List_fromArray(
						[
							(!$elm$core$List$isEmpty(buttons)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-card__action-buttons')
									]),
								A2(
									$elm$core$List$map,
									function (_v1) {
										var button_ = _v1;
										return button_;
									},
									buttons))
							]) : _List_Nil,
							(!$elm$core$List$isEmpty(icons)) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-card__action-icons')
									]),
								A2(
									$elm$core$List$map,
									function (_v2) {
										var icon_ = _v2;
										return icon_;
									},
									icons))
							]) : _List_Nil
						])))
			]);
	} else {
		return _List_Nil;
	}
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $aforemny$material_components_web_elm$Material$Card$clickHandler = function (_v0) {
	var onClick = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $aforemny$material_components_web_elm$Material$Card$hrefAttr = function (_v0) {
	var href = _v0.bd;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$Card$primaryActionCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-card__primary-action'));
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $aforemny$material_components_web_elm$Material$Card$tabIndexProp = function (tabIndex) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(tabIndex)));
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $aforemny$material_components_web_elm$Material$Card$targetAttr = function (_v0) {
	var target = _v0.dB;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target);
};
var $aforemny$material_components_web_elm$Material$Card$primaryAction = F2(
	function (config_, blocks) {
		var href = config_.bd;
		return _List_fromArray(
			[
				A2(
				(!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? $elm$html$Html$a : $elm$html$Html$div,
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Card$primaryActionCs,
							$aforemny$material_components_web_elm$Material$Card$tabIndexProp(0),
							$aforemny$material_components_web_elm$Material$Card$clickHandler(config_),
							$aforemny$material_components_web_elm$Material$Card$hrefAttr(config_),
							$aforemny$material_components_web_elm$Material$Card$targetAttr(config_)
						])),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var html = _v0;
						return html;
					},
					blocks))
			]);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $aforemny$material_components_web_elm$Material$Card$blocksElt = F2(
	function (config_, _v0) {
		var onClick = config_.c2;
		var href = config_.bd;
		var blocks = _v0.gf;
		return A2(
			$elm$core$List$map,
			function (_v1) {
				var html = _v1;
				return html;
			},
			(((!_Utils_eq(onClick, $elm$core$Maybe$Nothing)) || (!_Utils_eq(href, $elm$core$Maybe$Nothing))) ? $aforemny$material_components_web_elm$Material$Card$primaryAction(config_) : $elm$core$Basics$identity)(
				A2($elm$core$List$cons, blocks.a, blocks.b)));
	});
var $aforemny$material_components_web_elm$Material$Card$outlinedCs = function (_v0) {
	var outlined = _v0.c6;
	return outlined ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-card--outlined')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Card$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-card'));
var $aforemny$material_components_web_elm$Material$Card$card = F2(
	function (config_, content) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-card',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Card$rootCs,
							$aforemny$material_components_web_elm$Material$Card$outlinedCs(config_)
						])),
				additionalAttributes),
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2($aforemny$material_components_web_elm$Material$Card$blocksElt, config_, content),
						$aforemny$material_components_web_elm$Material$Card$actionsElt(content)
					])));
	});
var $aforemny$material_components_web_elm$Material$Card$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$config = {ce: _List_Nil, bd: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing, c6: false, dB: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$Card$Actions = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$fullBleedActions = function (button_) {
	return {
		d1: _List_fromArray(
			[button_]),
		cF: true,
		eB: _List_Nil
	};
};
var $aforemny$material_components_web_elm$Material$Card$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Card$setOutlined = F2(
	function (outlined, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{c6: outlined});
	});
var $author$project$App$User$LogIn$Messages$Submitted = {$: 1};
var $aforemny$material_components_web_elm$Material$Card$Button = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Button$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Button$Text = 0;
var $elm$html$Html$button = _VirtualDom_node('button');
var $aforemny$material_components_web_elm$Material$Button$clickHandler = function (_v0) {
	var onClick = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$Button$denseCs = function (_v0) {
	var dense = _v0.ct;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-button--dense')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $aforemny$material_components_web_elm$Material$Button$disabledAttr = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$disabled(disabled));
};
var $aforemny$material_components_web_elm$Material$Button$hrefAttr = function (_v0) {
	var href = _v0.bd;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $aforemny$material_components_web_elm$Material$Button$labelElt = function (label) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-button__label')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				])));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $aforemny$material_components_web_elm$Material$Button$iconElt = function (_v0) {
	var config_ = _v0;
	return A2(
		$elm$core$Maybe$map,
		$elm$html$Html$map($elm$core$Basics$never),
		function () {
			var _v1 = config_.gQ;
			if (!_v1.$) {
				if (!_v1.a.$) {
					var node = _v1.a.a.bS;
					var attributes = _v1.a.a.bw;
					var nodes = _v1.a.a.bT;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$class('mdc-button__icon'),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									attributes)),
							nodes));
				} else {
					var node = _v1.a.a.bS;
					var attributes = _v1.a.a.bw;
					var nodes = _v1.a.a.bT;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('mdc-button__icon'),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									attributes)),
							nodes));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}());
};
var $aforemny$material_components_web_elm$Material$Button$leadingIconElt = function (config_) {
	var trailingIcon = config_.cb;
	return (!trailingIcon) ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $aforemny$material_components_web_elm$Material$Menu$closeHandler = function (_v0) {
	var onClose = _v0.bX;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCMenuSurface:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $aforemny$material_components_web_elm$Material$List$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$config = {ce: _List_Nil, cl: false, ct: false, bL: true, b3: true, dF: false, h8: false, dO: false};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$avatarListCs = function (_v0) {
	var avatarList = _v0.cl;
	return avatarList ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--avatar-list')) : $elm$core$Maybe$Nothing;
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $aforemny$material_components_web_elm$Material$List$clickHandler = function (listItems) {
	var getOnClick = function (listItem_) {
		switch (listItem_.$) {
			case 0:
				var onClick = listItem_.b.c2;
				return $elm$core$Maybe$Just(onClick);
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var nthOnClick = function (index) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$Basics$identity,
			$elm$core$List$head(
				A2(
					$elm$core$List$drop,
					index,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						A2($elm$core$List$map, getOnClick, listItems)))));
	};
	var mergedClickHandler = A2(
		$elm$json$Json$Decode$andThen,
		function (index) {
			var _v0 = nthOnClick(index);
			if (!_v0.$) {
				var msg_ = _v0.a;
				return $elm$json$Json$Decode$succeed(msg_);
			} else {
				return $elm$json$Json$Decode$fail('');
			}
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['detail', 'index']),
			$elm$json$Json$Decode$int));
	return $elm$core$Maybe$Just(
		A2($elm$html$Html$Events$on, 'MDCList:action', mergedClickHandler));
};
var $aforemny$material_components_web_elm$Material$List$denseCs = function (_v0) {
	var dense = _v0.ct;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--dense')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$interactiveProp = function (_v0) {
	var interactive = _v0.bL;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'interactive',
			$elm$json$Json$Encode$bool(interactive)));
};
var $aforemny$material_components_web_elm$Material$List$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-deprecated-list'));
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $aforemny$material_components_web_elm$Material$List$selectedIndexProp = function (listItems) {
	var selectedIndex = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, listItem_) {
					switch (listItem_.$) {
						case 0:
							var selection = listItem_.b.hu;
							return (!_Utils_eq(selection, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
						case 1:
							return $elm$core$Maybe$Nothing;
						default:
							return $elm$core$Maybe$Nothing;
					}
				}),
			A2(
				$elm$core$List$filter,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							return true;
						case 1:
							return false;
						default:
							return false;
					}
				},
				listItems)));
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'selectedIndex',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, selectedIndex)));
};
var $aforemny$material_components_web_elm$Material$List$twoLineCs = function (_v0) {
	var twoLine = _v0.dF;
	return twoLine ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list--two-line')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$wrapFocusProp = function (_v0) {
	var wrapFocus = _v0.dO;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'wrapFocus',
			$elm$json$Json$Encode$bool(wrapFocus)));
};
var $aforemny$material_components_web_elm$Material$List$list = F3(
	function (config_, firstListItem, remainingListItems) {
		var ripples = config_.b3;
		var interactive = config_.bL;
		var additionalAttributes = config_.ce;
		var listItems = A2($elm$core$List$cons, firstListItem, remainingListItems);
		return A3(
			$elm$html$Html$node,
			'mdc-list',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$rootCs,
							$aforemny$material_components_web_elm$Material$List$denseCs(config_),
							$aforemny$material_components_web_elm$Material$List$avatarListCs(config_),
							$aforemny$material_components_web_elm$Material$List$twoLineCs(config_),
							$aforemny$material_components_web_elm$Material$List$wrapFocusProp(config_),
							$aforemny$material_components_web_elm$Material$List$clickHandler(listItems),
							$aforemny$material_components_web_elm$Material$List$selectedIndexProp(listItems),
							$aforemny$material_components_web_elm$Material$List$interactiveProp(config_)
						])),
				additionalAttributes),
			A2(
				$elm$core$List$map,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							var node = listItem_.a;
							var config__ = listItem_.b;
							return node(
								_Utils_update(
									config__,
									{b3: ripples && interactive}));
						case 1:
							var node = listItem_.a;
							return node;
						default:
							var node = listItem_.a;
							return node;
					}
				},
				listItems));
	});
var $aforemny$material_components_web_elm$Material$Menu$openProp = function (_v0) {
	var open = _v0.c4;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Menu$quickOpenProp = function (_v0) {
	var quickOpen = _v0.dg;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'quickOpen',
			$elm$json$Json$Encode$bool(quickOpen)));
};
var $aforemny$material_components_web_elm$Material$Menu$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-menu mdc-menu-surface'));
var $aforemny$material_components_web_elm$Material$List$setRipples = F2(
	function (ripples, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{b3: ripples});
	});
var $aforemny$material_components_web_elm$Material$List$setWrapFocus = F2(
	function (wrapFocus, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dO: wrapFocus});
	});
var $aforemny$material_components_web_elm$Material$Menu$menu = F3(
	function (config_, firstListItem, remainingListItems) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-menu',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Menu$rootCs,
							$aforemny$material_components_web_elm$Material$Menu$openProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$quickOpenProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$closeHandler(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A3(
					$aforemny$material_components_web_elm$Material$List$list,
					A2(
						$aforemny$material_components_web_elm$Material$List$setWrapFocus,
						true,
						A2($aforemny$material_components_web_elm$Material$List$setRipples, false, $aforemny$material_components_web_elm$Material$List$config)),
					firstListItem,
					remainingListItems)
				]));
	});
var $aforemny$material_components_web_elm$Material$Button$rippleElt = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-button__ripple')
			]),
		_List_Nil));
var $aforemny$material_components_web_elm$Material$Button$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-button'));
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $aforemny$material_components_web_elm$Material$Button$tabIndexProp = function (_v0) {
	var disabled = _v0.a6;
	return disabled ? $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(-1))) : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(0)));
};
var $aforemny$material_components_web_elm$Material$Button$targetAttr = function (_v0) {
	var href = _v0.bd;
	var target = _v0.dB;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$touchCs = function (_v0) {
	var touch = _v0.bp;
	return touch ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-button--touch')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$touchElt = function (_v0) {
	var touch = _v0.bp;
	return touch ? $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-button__touch')
				]),
			_List_Nil)) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$trailingIconElt = function (config_) {
	var trailingIcon = config_.cb;
	return trailingIcon ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$variantCs = function (variant) {
	switch (variant) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--raised'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--unelevated'));
		default:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--outlined'));
	}
};
var $aforemny$material_components_web_elm$Material$Button$button = F3(
	function (variant, config_, label) {
		var innerConfig = config_;
		var additionalAttributes = innerConfig.ce;
		var touch = innerConfig.bp;
		var href = innerConfig.bd;
		var disabled = innerConfig.a6;
		var wrapTouch = function (node) {
			return touch ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-touch-target-wrapper')
					]),
				_List_fromArray(
					[node])) : node;
		};
		var wrapMenu = function (node) {
			var _v0 = innerConfig.eO;
			if (_v0.$ === 1) {
				return node;
			} else {
				var _v1 = _v0.a;
				var menuConfig = _v1.a;
				var firstListItem = _v1.b;
				var remainingListItems = _v1.c;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-menu-surface--anchor')
						]),
					_List_fromArray(
						[
							node,
							A3($aforemny$material_components_web_elm$Material$Menu$menu, menuConfig, firstListItem, remainingListItems)
						]));
			}
		};
		return wrapMenu(
			wrapTouch(
				A3(
					$elm$html$Html$node,
					'mdc-button',
					_List_Nil,
					_List_fromArray(
						[
							A2(
							((!_Utils_eq(href, $elm$core$Maybe$Nothing)) && (!disabled)) ? $elm$html$Html$a : $elm$html$Html$button,
							_Utils_ap(
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											$aforemny$material_components_web_elm$Material$Button$rootCs,
											$aforemny$material_components_web_elm$Material$Button$variantCs(variant),
											$aforemny$material_components_web_elm$Material$Button$denseCs(config_),
											$aforemny$material_components_web_elm$Material$Button$touchCs(config_),
											$aforemny$material_components_web_elm$Material$Button$disabledAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$tabIndexProp(config_),
											$aforemny$material_components_web_elm$Material$Button$hrefAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$targetAttr(config_),
											$aforemny$material_components_web_elm$Material$Button$clickHandler(config_)
										])),
								additionalAttributes),
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$Button$rippleElt,
										$aforemny$material_components_web_elm$Material$Button$leadingIconElt(config_),
										$aforemny$material_components_web_elm$Material$Button$labelElt(label),
										$aforemny$material_components_web_elm$Material$Button$trailingIconElt(config_),
										$aforemny$material_components_web_elm$Material$Button$touchElt(config_)
									])))
						]))));
	});
var $aforemny$material_components_web_elm$Material$Button$text = F2(
	function (config_, label) {
		return A3($aforemny$material_components_web_elm$Material$Button$button, 0, config_, label);
	});
var $aforemny$material_components_web_elm$Material$Card$button = F2(
	function (_v0, label) {
		var buttonConfig = _v0;
		return A2(
			$aforemny$material_components_web_elm$Material$Button$text,
			_Utils_update(
				buttonConfig,
				{
					ce: A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-card__action'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class('mdc-card__action--button'),
							buttonConfig.ce))
				}),
			label);
	});
var $aforemny$material_components_web_elm$Material$Button$config = {ce: _List_Nil, ct: false, a6: false, bd: $elm$core$Maybe$Nothing, gQ: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing, bp: true, cb: false};
var $aforemny$material_components_web_elm$Material$Button$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{a6: disabled});
	});
var $aforemny$material_components_web_elm$Material$Button$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$App$User$LogIn$View$viewButton = function (_v0) {
	var form = _v0.ba;
	var toSubmit = _v0.fL;
	var config = A2(
		$aforemny$material_components_web_elm$Material$Button$setDisabled,
		toSubmit,
		A2($aforemny$material_components_web_elm$Material$Button$setOnClick, $author$project$App$User$LogIn$Messages$Submitted, $aforemny$material_components_web_elm$Material$Button$config));
	return A2($aforemny$material_components_web_elm$Material$Card$button, config, 'Next');
};
var $author$project$App$User$LogIn$Messages$Email = 0;
var $author$project$App$User$LogIn$Messages$Passcode = 1;
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$App$User$LogIn$Messages$UpdateField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$TextField$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TextField$config = {ce: _List_Nil, a6: false, cx: false, bH: false, g_: $elm$core$Maybe$Nothing, eL: $elm$core$Maybe$Nothing, au: $elm$core$Maybe$Nothing, bO: $elm$core$Maybe$Nothing, aw: $elm$core$Maybe$Nothing, bP: $elm$core$Maybe$Nothing, c0: $elm$core$Maybe$Nothing, c1: $elm$core$Maybe$Nothing, c3: $elm$core$Maybe$Nothing, c9: $elm$core$Maybe$Nothing, b$: $elm$core$Maybe$Nothing, db: $elm$core$Maybe$Nothing, di: false, bl: $elm$core$Maybe$Nothing, dx: $elm$core$Maybe$Nothing, cb: $elm$core$Maybe$Nothing, dG: $elm$core$Maybe$Nothing, dH: true, h7: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$TextField$disabledCs = function (_v0) {
	var disabled = _v0.a6;
	return disabled ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--disabled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$disabledProp = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $aforemny$material_components_web_elm$Material$TextField$endAlignedCs = function (_v0) {
	var endAligned = _v0.cx;
	return endAligned ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--end-aligned')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$filledCs = function (outlined_) {
	return (!outlined_) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--filled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$foucClassNamesProp = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$Attributes$property,
		'foucClassNames',
		A2(
			$elm$json$Json$Encode$list,
			$elm$json$Json$Encode$string,
			_List_fromArray(
				['mdc-text-field--label-floating']))));
var $aforemny$material_components_web_elm$Material$TextField$fullwidthCs = function (_v0) {
	var fullwidth = _v0.bH;
	return fullwidth ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--fullwidth')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$ariaLabelAttr = function (_v0) {
	var fullwidth = _v0.bH;
	var label = _v0.g_;
	return fullwidth ? A2(
		$elm$core$Maybe$map,
		$elm$html$Html$Attributes$attribute('aria-label'),
		label) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $aforemny$material_components_web_elm$Material$TextField$blurHandler = function (_v0) {
	var onBlur = _v0.c0;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, onBlur);
};
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $aforemny$material_components_web_elm$Material$TextField$changeHandler = function (_v0) {
	var onChange = _v0.c1;
	return A2(
		$elm$core$Maybe$map,
		function (f) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, f, $elm$html$Html$Events$targetValue));
		},
		onChange);
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $aforemny$material_components_web_elm$Material$TextField$inputCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field__input'));
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $aforemny$material_components_web_elm$Material$TextField$inputHandler = function (_v0) {
	var onInput = _v0.c3;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, onInput);
};
var $aforemny$material_components_web_elm$Material$TextField$maxLengthAttr = function (_v0) {
	var maxLength = _v0.bO;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('maxLength'),
			$elm$core$String$fromInt),
		maxLength);
};
var $aforemny$material_components_web_elm$Material$TextField$minLengthAttr = function (_v0) {
	var minLength = _v0.bP;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('minLength'),
			$elm$core$String$fromInt),
		minLength);
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $aforemny$material_components_web_elm$Material$TextField$placeholderAttr = function (_v0) {
	var placeholder = _v0.b$;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, placeholder);
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $aforemny$material_components_web_elm$Material$TextField$typeAttr = function (_v0) {
	var type_ = _v0.dG;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$type_, type_);
};
var $aforemny$material_components_web_elm$Material$TextField$inputElt = function (config_) {
	return A2(
		$elm$html$Html$input,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$inputCs,
					$aforemny$material_components_web_elm$Material$TextField$typeAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$ariaLabelAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$placeholderAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$blurHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$changeHandler(config_),
					$aforemny$material_components_web_elm$Material$TextField$minLengthAttr(config_),
					$aforemny$material_components_web_elm$Material$TextField$maxLengthAttr(config_)
				])),
		_List_Nil);
};
var $aforemny$material_components_web_elm$Material$TextField$labelElt = function (_v0) {
	var label = _v0.g_;
	var value = _v0.h7;
	var fullwidth = _v0.bH;
	var floatingLabelFloatAboveCs = 'mdc-floating-label--float-above';
	var floatingLabelCs = 'mdc-floating-label';
	var _v1 = _Utils_Tuple2(fullwidth, label);
	if ((!_v1.a) && (!_v1.b.$)) {
		var str = _v1.b.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					(A2($elm$core$Maybe$withDefault, '', value) !== '') ? $elm$html$Html$Attributes$class(floatingLabelCs + (' ' + floatingLabelFloatAboveCs)) : $elm$html$Html$Attributes$class(floatingLabelCs),
					A2(
					$elm$html$Html$Attributes$property,
					'foucClassNames',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						_List_fromArray(
							[floatingLabelFloatAboveCs])))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$labelFloatingCs = function (_v0) {
	var label = _v0.g_;
	var value = _v0.h7;
	var fullwidth = _v0.bH;
	return ((!fullwidth) && ((!_Utils_eq(label, $elm$core$Maybe$Nothing)) && (A2($elm$core$Maybe$withDefault, '', value) !== ''))) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--label-floating')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$TextField$iconElt = F2(
	function (modifierCs, icon_) {
		if (icon_.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			if (!icon_.a.$) {
				var node = icon_.a.a.bS;
				var attributes = icon_.a.a.bw;
				var nodes = icon_.a.a.bT;
				var onInteraction = icon_.a.a.eY;
				var disabled = icon_.a.a.a6;
				return A2(
					node,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-text-field__icon'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(modifierCs),
							function () {
								if (!onInteraction.$) {
									var msg = onInteraction.a;
									return (!disabled) ? A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(0),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(msg),
												A2(
													$elm$core$List$cons,
													A2(
														$elm$html$Html$Events$on,
														'keydown',
														A2(
															$elm$json$Json$Decode$andThen,
															function (keyCode) {
																return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
															},
															$elm$html$Html$Events$keyCode)),
													attributes)))) : A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(-1),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											attributes));
								} else {
									return attributes;
								}
							}())),
					nodes);
			} else {
				var node = icon_.a.a.bS;
				var attributes = icon_.a.a.bw;
				var nodes = icon_.a.a.bT;
				var onInteraction = icon_.a.a.eY;
				var disabled = icon_.a.a.a6;
				return A2(
					node,
					A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class('mdc-text-field__icon'),
						A2(
							$elm$core$List$cons,
							$elm$svg$Svg$Attributes$class(modifierCs),
							function () {
								if (!onInteraction.$) {
									var msg = onInteraction.a;
									return (!disabled) ? A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(0),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(msg),
												A2(
													$elm$core$List$cons,
													A2(
														$elm$html$Html$Events$on,
														'keydown',
														A2(
															$elm$json$Json$Decode$andThen,
															function (keyCode) {
																return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
															},
															$elm$html$Html$Events$keyCode)),
													attributes)))) : A2(
										$elm$core$List$cons,
										$elm$html$Html$Attributes$tabindex(-1),
										A2(
											$elm$core$List$cons,
											A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
											attributes));
								} else {
									return attributes;
								}
							}())),
					nodes);
			}
		}
	});
var $aforemny$material_components_web_elm$Material$TextField$leadingIconElt = function (_v0) {
	var leadingIcon = _v0.eL;
	return A2($aforemny$material_components_web_elm$Material$TextField$iconElt, 'mdc-text-field__icon--leading', leadingIcon);
};
var $aforemny$material_components_web_elm$Material$TextField$lineRippleElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-line-ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$maxLengthProp = function (_v0) {
	var maxLength = _v0.bO;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'maxLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, maxLength))));
};
var $aforemny$material_components_web_elm$Material$TextField$maxProp = function (_v0) {
	var max = _v0.au;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'max',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, max)))));
};
var $aforemny$material_components_web_elm$Material$TextField$minLengthProp = function (_v0) {
	var minLength = _v0.bP;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'minLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, minLength))));
};
var $aforemny$material_components_web_elm$Material$TextField$minProp = function (_v0) {
	var min = _v0.aw;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'min',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, min)))));
};
var $aforemny$material_components_web_elm$Material$TextField$noLabelCs = function (_v0) {
	var label = _v0.g_;
	return _Utils_eq(label, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--no-label')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineLeadingElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__leading')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineNotchElt = function (config_) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline__notch')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$TextField$labelElt(config_)
			]));
};
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineTrailingElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__trailing')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$notchedOutlineElt = function (config_) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineLeadingElt,
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineNotchElt(config_),
				$aforemny$material_components_web_elm$Material$TextField$notchedOutlineTrailingElt
			]));
};
var $aforemny$material_components_web_elm$Material$TextField$outlinedCs = function (outlined_) {
	return outlined_ ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--outlined')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$patternProp = function (_v0) {
	var pattern = _v0.c9;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'pattern',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, pattern))));
};
var $aforemny$material_components_web_elm$Material$TextField$prefixCs = $elm$html$Html$Attributes$class('mdc-text-field__affix mdc-text-field__affix--prefix');
var $aforemny$material_components_web_elm$Material$TextField$prefixElt = function (_v0) {
	var prefix = _v0.db;
	if (!prefix.$) {
		var prefixStr = prefix.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TextField$prefixCs]),
			_List_fromArray(
				[
					$elm$html$Html$text(prefixStr)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$requiredProp = function (_v0) {
	var required = _v0.di;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'required',
			$elm$json$Json$Encode$bool(required)));
};
var $aforemny$material_components_web_elm$Material$TextField$rippleElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-text-field__ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TextField$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field'));
var $aforemny$material_components_web_elm$Material$TextField$stepProp = function (_v0) {
	var step = _v0.bl;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'step',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, step)))));
};
var $aforemny$material_components_web_elm$Material$TextField$suffixCs = $elm$html$Html$Attributes$class('mdc-text-field__affix mdc-text-field__affix--suffix');
var $aforemny$material_components_web_elm$Material$TextField$suffixElt = function (_v0) {
	var suffix = _v0.dx;
	if (!suffix.$) {
		var suffixStr = suffix.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TextField$suffixCs]),
			_List_fromArray(
				[
					$elm$html$Html$text(suffixStr)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $aforemny$material_components_web_elm$Material$TextField$trailingIconElt = function (_v0) {
	var trailingIcon = _v0.cb;
	return A2($aforemny$material_components_web_elm$Material$TextField$iconElt, 'mdc-text-field__icon--trailing', trailingIcon);
};
var $aforemny$material_components_web_elm$Material$TextField$validProp = function (_v0) {
	var valid = _v0.dH;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'valid',
			$elm$json$Json$Encode$bool(valid)));
};
var $aforemny$material_components_web_elm$Material$TextField$valueProp = function (_v0) {
	var value = _v0.h7;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$property('value'),
			$elm$json$Json$Encode$string),
		value);
};
var $aforemny$material_components_web_elm$Material$TextField$withLeadingIconCs = function (_v0) {
	var leadingIcon = _v0.eL;
	return (!_Utils_eq(leadingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-leading-icon')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$withTrailingIconCs = function (_v0) {
	var trailingIcon = _v0.cb;
	return (!_Utils_eq(trailingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-trailing-icon')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TextField$textField = F2(
	function (outlined_, config_) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-text-field',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TextField$rootCs,
							$aforemny$material_components_web_elm$Material$TextField$noLabelCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$filledCs(outlined_),
							$aforemny$material_components_web_elm$Material$TextField$outlinedCs(outlined_),
							$aforemny$material_components_web_elm$Material$TextField$fullwidthCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$labelFloatingCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$foucClassNamesProp,
							$aforemny$material_components_web_elm$Material$TextField$disabledCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$withLeadingIconCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$withTrailingIconCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$endAlignedCs(config_),
							$aforemny$material_components_web_elm$Material$TextField$valueProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$disabledProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$requiredProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$validProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$patternProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$minLengthProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$maxLengthProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$minProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$maxProp(config_),
							$aforemny$material_components_web_elm$Material$TextField$stepProp(config_)
						])),
				additionalAttributes),
			outlined_ ? _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$leadingIconElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$prefixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$suffixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$notchedOutlineElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$trailingIconElt(config_)
				]) : _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$rippleElt,
					$aforemny$material_components_web_elm$Material$TextField$leadingIconElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$prefixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$inputElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$suffixElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$labelElt(config_),
					$aforemny$material_components_web_elm$Material$TextField$lineRippleElt,
					$aforemny$material_components_web_elm$Material$TextField$trailingIconElt(config_)
				]));
	});
var $aforemny$material_components_web_elm$Material$TextField$outlined = function (config_) {
	return A2($aforemny$material_components_web_elm$Material$TextField$textField, true, config_);
};
var $aforemny$material_components_web_elm$Material$TextField$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TextField$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{a6: disabled});
	});
var $aforemny$material_components_web_elm$Material$TextField$setOnInput = F2(
	function (onInput, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c3: $elm$core$Maybe$Just(onInput)
			});
	});
var $aforemny$material_components_web_elm$Material$TextField$setPlaceholder = F2(
	function (placeholder, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{b$: placeholder});
	});
var $aforemny$material_components_web_elm$Material$TextField$setType = F2(
	function (type_, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dG: type_});
	});
var $aforemny$material_components_web_elm$Material$TextField$setValue = F2(
	function (value, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{h7: value});
	});
var $author$project$App$User$LogIn$View$viewField = F2(
	function (_v0, _v1) {
		var toSubmit = _v0.fL;
		var field = _v1.a;
		var value = _v1.b;
		var textFieldType = function () {
			if (!field) {
				return 'email';
			} else {
				return 'text';
			}
		}();
		var placeHolder = function () {
			switch (field) {
				case 0:
					return 'Email';
				case 1:
					return 'One-time passcode';
				case 2:
					return 'First name';
				default:
					return 'Last name (Optional)';
			}
		}();
		var config = A2(
			$aforemny$material_components_web_elm$Material$TextField$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$TextField$setType,
				$elm$core$Maybe$Just(textFieldType),
				A2(
					$aforemny$material_components_web_elm$Material$TextField$setValue,
					$elm$core$Maybe$Just(value),
					A2(
						$aforemny$material_components_web_elm$Material$TextField$setPlaceholder,
						$elm$core$Maybe$Just(placeHolder),
						A2(
							$aforemny$material_components_web_elm$Material$TextField$setDisabled,
							toSubmit,
							A2(
								$aforemny$material_components_web_elm$Material$TextField$setOnInput,
								$author$project$App$User$LogIn$Messages$UpdateField(field),
								$aforemny$material_components_web_elm$Material$TextField$config))))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding-top', '10px')
				]),
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$TextField$outlined(config)
				]));
	});
var $author$project$App$User$LogIn$View$viewForm = function (model) {
	var fieldPairs = function () {
		var _v0 = model.ba;
		switch (_v0.$) {
			case 0:
				var email = _v0.a.gB;
				return _List_fromArray(
					[
						_Utils_Tuple2(0, email)
					]);
			case 1:
				var email = _v0.a.gB;
				return _List_fromArray(
					[
						_Utils_Tuple2(0, email)
					]);
			default:
				var passcode = _v0.a.c8;
				return _List_fromArray(
					[
						_Utils_Tuple2(1, passcode)
					]);
		}
	}();
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Events$onSubmit($author$project$App$User$LogIn$Messages$Submitted)
			]),
		A2(
			$elm$core$List$map,
			$author$project$App$User$LogIn$View$viewField(model),
			fieldPairs));
};
var $aforemny$material_components_web_elm$Material$Typography$headline6 = $elm$html$Html$Attributes$class('mdc-typography--headline6');
var $author$project$App$User$LogIn$View$viewTitle = function (_v0) {
	var form = _v0.ba;
	var title = 'Log in';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$headline6,
				A2($elm$html$Html$Attributes$style, 'font-weight', '300'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '20px')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$App$User$LogIn$View$viewCard = function (model) {
	var config = A2(
		$aforemny$material_components_web_elm$Material$Card$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '15px 15px 5px 15px')
			]),
		A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		config,
		{
			f4: $elm$core$Maybe$Just(
				$aforemny$material_components_web_elm$Material$Card$fullBleedActions(
					$author$project$App$User$LogIn$View$viewButton(model))),
			gf: _Utils_Tuple2(
				$aforemny$material_components_web_elm$Material$Card$block(
					$author$project$App$User$LogIn$View$viewTitle(model)),
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Card$block(
						$author$project$App$User$LogIn$View$viewForm(model))
					]))
		});
};
var $aforemny$material_components_web_elm$Material$Snackbar$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Snackbar$config = function (_v0) {
	var onClosed = _v0.hb;
	return {ce: _List_Nil, co: false, hb: onClosed};
};
var $aforemny$material_components_web_elm$Material$Snackbar$closeOnEscapeProp = function (_v0) {
	var closeOnEscape = _v0.co;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'closeOnEscape',
			$elm$json$Json$Encode$bool(closeOnEscape)));
};
var $aforemny$material_components_web_elm$Material$Snackbar$closedHandler = F2(
	function (messageId, _v0) {
		var onClosed = _v0.hb;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$Events$on,
				'MDCSnackbar:closed',
				$elm$json$Json$Decode$succeed(
					onClosed(messageId))));
	});
var $aforemny$material_components_web_elm$Material$Snackbar$leadingCs = function (message_) {
	return A2(
		$elm$core$Maybe$andThen,
		function (_v0) {
			var leading = _v0.cQ;
			return leading ? $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-snackbar--leading')) : $elm$core$Maybe$Nothing;
		},
		message_);
};
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $aforemny$material_components_web_elm$Material$Snackbar$messageIdProp = function (_v0) {
	var messageId = _v0;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'messageId',
			$elm$json$Json$Encode$int(messageId)));
};
var $aforemny$material_components_web_elm$Material$Snackbar$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-snackbar'));
var $aforemny$material_components_web_elm$Material$Snackbar$stackedCs = function (message_) {
	return A2(
		$elm$core$Maybe$andThen,
		function (_v0) {
			var stacked = _v0.du;
			return stacked ? $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-snackbar--stacked')) : $elm$core$Maybe$Nothing;
		},
		message_);
};
var $aforemny$material_components_web_elm$Material$Snackbar$actionButtonClickHandler = F2(
	function (messageId, _v0) {
		var onActionButtonClick = _v0.c_;
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Events$onClick,
				$elm$core$Basics$apR(messageId)),
			onActionButtonClick);
	});
var $aforemny$material_components_web_elm$Material$Snackbar$actionButtonCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-button mdc-snackbar__action'));
var $aforemny$material_components_web_elm$Material$Snackbar$actionButtonElt = F2(
	function (messageId, message_) {
		var actionButton = message_.cc;
		return A2(
			$elm$core$Maybe$map,
			function (actionButtonLabel) {
				return A2(
					$elm$html$Html$button,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$aforemny$material_components_web_elm$Material$Snackbar$actionButtonCs,
								A2($aforemny$material_components_web_elm$Material$Snackbar$actionButtonClickHandler, messageId, message_)
							])),
					_List_fromArray(
						[
							$elm$html$Html$text(actionButtonLabel)
						]));
			},
			actionButton);
	});
var $aforemny$material_components_web_elm$Material$Snackbar$actionIconClickHandler = F2(
	function (messageId, _v0) {
		var onActionIconClick = _v0.c$;
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Events$onClick,
				$elm$core$Basics$apR(messageId)),
			onActionIconClick);
	});
var $aforemny$material_components_web_elm$Material$Snackbar$actionIconElt = F2(
	function (messageId, message_) {
		var actionIcon = message_.cd;
		if (!actionIcon.$) {
			if (!actionIcon.a.$) {
				var node = actionIcon.a.a.bS;
				var attributes = actionIcon.a.a.bw;
				var nodes = actionIcon.a.a.bT;
				return $elm$core$Maybe$Just(
					A2(
						node,
						_Utils_ap(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$elm$core$Maybe$Just(
										$elm$html$Html$Attributes$class('mdc-icon-button')),
										$elm$core$Maybe$Just(
										$elm$html$Html$Attributes$class('mdc-snackbar__dismiss')),
										A2($aforemny$material_components_web_elm$Material$Snackbar$actionIconClickHandler, messageId, message_)
									])),
							attributes),
						nodes));
			} else {
				var node = actionIcon.a.a.bS;
				var attributes = actionIcon.a.a.bw;
				var nodes = actionIcon.a.a.bT;
				return $elm$core$Maybe$Just(
					A2(
						node,
						_Utils_ap(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$elm$core$Maybe$Just(
										$elm$svg$Svg$Attributes$class('mdc-icon-button')),
										$elm$core$Maybe$Just(
										$elm$svg$Svg$Attributes$class('mdc-snackbar__dismiss')),
										A2($aforemny$material_components_web_elm$Material$Snackbar$actionIconClickHandler, messageId, message_)
									])),
							attributes),
						nodes));
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $aforemny$material_components_web_elm$Material$Snackbar$actionsElt = F2(
	function (messageId, message_) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-snackbar__actions')
				]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($aforemny$material_components_web_elm$Material$Snackbar$actionButtonElt, messageId, message_),
						A2($aforemny$material_components_web_elm$Material$Snackbar$actionIconElt, messageId, message_)
					])));
	});
var $aforemny$material_components_web_elm$Material$Snackbar$ariaPoliteLiveAttr = A2($elm$html$Html$Attributes$attribute, 'aria-live', 'polite');
var $aforemny$material_components_web_elm$Material$Snackbar$ariaStatusRoleAttr = A2($elm$html$Html$Attributes$attribute, 'aria-role', 'status');
var $aforemny$material_components_web_elm$Material$Snackbar$labelElt = function (_v0) {
	var label = _v0.g_;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-snackbar__label'),
				$aforemny$material_components_web_elm$Material$Snackbar$ariaStatusRoleAttr,
				$aforemny$material_components_web_elm$Material$Snackbar$ariaPoliteLiveAttr
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $aforemny$material_components_web_elm$Material$Snackbar$surfaceElt = F2(
	function (messageId, message_) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-snackbar__surface')
				]),
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Snackbar$labelElt(message_),
					A2($aforemny$material_components_web_elm$Material$Snackbar$actionsElt, messageId, message_)
				]));
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $aforemny$material_components_web_elm$Material$Snackbar$timeoutMsProp = function (message_) {
	var indefiniteTimeout = -1;
	var normalizedTimeoutMs = A2(
		$elm$core$Maybe$withDefault,
		indefiniteTimeout,
		A2(
			$elm$core$Maybe$andThen,
			function (_v0) {
				var timeoutMs = _v0.dD;
				return A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$clamp, 4000, 10000),
					timeoutMs);
			},
			message_));
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'timeoutMs',
			$elm$json$Json$Encode$int(normalizedTimeoutMs)));
};
var $aforemny$material_components_web_elm$Material$Snackbar$snackbar = F2(
	function (config_, queue) {
		var additionalAttributes = config_.ce;
		var messages = queue.av;
		var nextMessageId = queue.bg;
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(-1, $elm$core$Maybe$Nothing),
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$mapSecond($elm$core$Maybe$Just),
				$elm$core$List$head(messages)));
		var currentMessageId = _v0.a;
		var currentMessage = _v0.b;
		return A3(
			$elm$html$Html$node,
			'mdc-snackbar',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Snackbar$rootCs,
							$aforemny$material_components_web_elm$Material$Snackbar$closeOnEscapeProp(config_),
							$aforemny$material_components_web_elm$Material$Snackbar$leadingCs(currentMessage),
							$aforemny$material_components_web_elm$Material$Snackbar$stackedCs(currentMessage),
							$aforemny$material_components_web_elm$Material$Snackbar$messageIdProp(currentMessageId),
							$aforemny$material_components_web_elm$Material$Snackbar$timeoutMsProp(currentMessage),
							A2($aforemny$material_components_web_elm$Material$Snackbar$closedHandler, currentMessageId, config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$Snackbar$surfaceElt,
					currentMessageId,
					A2(
						$elm$core$Maybe$withDefault,
						$aforemny$material_components_web_elm$Material$Snackbar$message(''),
						currentMessage))
				]));
	});
var $author$project$Components$viewSnackbar = F2(
	function (closeMsg, _v0) {
		var snackbarQ = _v0.fw;
		var config = $aforemny$material_components_web_elm$Material$Snackbar$config(
			{hb: closeMsg});
		return A2($aforemny$material_components_web_elm$Material$Snackbar$snackbar, config, snackbarQ);
	});
var $author$project$App$User$LogIn$View$view = function (model) {
	return A2(
		$aforemny$material_components_web_elm$Material$LayoutGrid$layoutGrid,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'max-width', '480px'),
				A2($elm$html$Html$Attributes$style, 'padding-top', '200px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$LayoutGrid$inner,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$LayoutGrid$cell,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$LayoutGrid$span12]),
						_List_fromArray(
							[
								$author$project$App$User$LogIn$View$viewCard(model),
								A2($author$project$Components$viewSnackbar, $author$project$App$User$LogIn$Messages$CloseSnackbar, model)
							]))
					]))
			]));
};
var $author$project$App$User$View$view = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$App$User$Messages$LogInMsg,
		$author$project$App$User$LogIn$View$view(model.eN));
};
var $author$project$App$View$belowBarAttrs = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'display', '-ms-flexbox'),
		A2($elm$html$Html$Attributes$style, 'display', 'flex'),
		A2($elm$html$Html$Attributes$style, 'position', 'relative'),
		A2($elm$html$Html$Attributes$style, 'height', '100vh'),
		A2($elm$html$Html$Attributes$style, 'overflow', 'hidden')
	]);
var $aforemny$material_components_web_elm$Material$TopAppBar$fixedAdjust = $elm$html$Html$Attributes$class('mdc-top-app-bar--fixed-adjust');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$App$View$contentAttrs = _List_fromArray(
	[
		$aforemny$material_components_web_elm$Material$TopAppBar$fixedAdjust,
		$elm$html$Html$Attributes$id('content'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, '-webkit-box-sizing', 'border-box'),
		A2($elm$html$Html$Attributes$style, 'box-sizing', 'border-box'),
		A2($elm$html$Html$Attributes$style, 'max-width', '100%'),
		A2($elm$html$Html$Attributes$style, 'padding-left', '16px'),
		A2($elm$html$Html$Attributes$style, 'padding-right', '16px'),
		A2($elm$html$Html$Attributes$style, 'padding-bottom', '100px'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'overflow', 'auto'),
		A2($elm$html$Html$Attributes$style, 'display', '-ms-flexbox'),
		A2($elm$html$Html$Attributes$style, 'display', 'flex'),
		A2($elm$html$Html$Attributes$style, '-ms-flex-direction', 'column'),
		A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
		A2($elm$html$Html$Attributes$style, '-ms-flex-align', 'center'),
		A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
		A2($elm$html$Html$Attributes$style, '-ms-flex-pack', 'start'),
		A2($elm$html$Html$Attributes$style, 'justify-content', 'flex-start')
	]);
var $aforemny$material_components_web_elm$Material$Drawer$Modal$scrim = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-drawer-scrim'),
				additionalAttributes),
			nodes);
	});
var $elm$html$Html$Attributes$dir = $elm$html$Html$Attributes$stringProperty('dir');
var $aforemny$material_components_web_elm$Material$Typography$body1 = $elm$html$Html$Attributes$class('mdc-typography--body1');
var $author$project$Components$default = A2($elm$html$Html$div, _List_Nil, _List_Nil);
var $author$project$App$Project$Model$getProjectTitle = function (_v0) {
	var project = _v0.fa;
	if (!project.$) {
		if (!project.a.$) {
			var title = project.a.a.hS;
			return $elm$core$Maybe$Just(title);
		} else {
			var title = project.a.a.hS;
			return $elm$core$Maybe$Just(title);
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Project$Model$isInDoingMode = function (_v0) {
	var doing = _v0.ed;
	return !_Utils_eq(doing, $elm$core$Maybe$Nothing);
};
var $author$project$App$Project$Messages$DoingMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Project$Messages$Navigate = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$Button$Internal$Icon = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$Button$customIcon = F3(
	function (node, attributes, nodes) {
		return $aforemny$material_components_web_elm$Material$Button$Internal$Icon(
			{bw: attributes, bS: node, bT: nodes});
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $aforemny$material_components_web_elm$Material$Button$icon = function (iconName) {
	return A3(
		$aforemny$material_components_web_elm$Material$Button$customIcon,
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(iconName)
			]));
};
var $aforemny$material_components_web_elm$Material$Button$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Button$setDense = F2(
	function (dense, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ct: dense});
	});
var $aforemny$material_components_web_elm$Material$Button$setIcon = F2(
	function (icon_, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{gQ: icon_});
	});
var $author$project$Components$makeTextButton = F3(
	function (msg, txt, maybeIconString) {
		var icon = A2($elm$core$Maybe$map, $aforemny$material_components_web_elm$Material$Button$icon, maybeIconString);
		var config = A2(
			$aforemny$material_components_web_elm$Material$Button$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin', '0px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px'),
					A2($elm$html$Html$Attributes$style, 'text-transform', 'capitalize')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$Button$setDense,
				true,
				A2(
					$aforemny$material_components_web_elm$Material$Button$setIcon,
					icon,
					A2($aforemny$material_components_web_elm$Material$Button$setOnClick, msg, $aforemny$material_components_web_elm$Material$Button$config))));
		return A2($aforemny$material_components_web_elm$Material$Button$text, config, txt);
	});
var $author$project$App$Doing$View$viewNavigation = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'display', 'flex'),
			A2($elm$html$Html$Attributes$style, 'justify-content', 'space-around')
		]),
	_List_fromArray(
		[
			A3(
			$author$project$Components$makeTextButton,
			$author$project$App$Project$Messages$DoingMsg(
				$author$project$App$Project$Messages$Navigate(-1)),
			'',
			$elm$core$Maybe$Just('navigate_before')),
			A3(
			$author$project$Components$makeTextButton,
			$author$project$App$Project$Messages$DoingMsg(
				$author$project$App$Project$Messages$Navigate(1)),
			'',
			$elm$core$Maybe$Just('navigate_next'))
		]));
var $author$project$Models$App$InDoing = 2;
var $aforemny$material_components_web_elm$Material$Typography$headline5 = $elm$html$Html$Attributes$class('mdc-typography--headline5');
var $author$project$App$Task$Messages$ToPutDone = function (a) {
	return {$: 3, a: a};
};
var $aforemny$material_components_web_elm$Material$Icon$icon = F2(
	function (additionalAttributes, iconName) {
		return A2(
			$elm$html$Html$i,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('material-icons'),
				additionalAttributes),
			_List_fromArray(
				[
					$elm$html$Html$text(iconName)
				]));
	});
var $author$project$Models$Project$initTaskDonePayload = function (_v0) {
	var done = _v0.gx;
	var id = _v0.eC;
	var objectiveId = _v0.g9;
	return {gx: !done, g9: objectiveId, hI: id};
};
var $author$project$App$Task$View$viewTaskCheckIcon = F2(
	function (tP, isDisabled) {
		var maybeTask = $author$project$Models$Project$potentialToMaybe(tP);
		var makeActionAttrs = function (task) {
			var msg = $author$project$App$Task$Messages$CmdReq(
				$author$project$App$Task$Messages$ToPutDone(
					$author$project$Models$Project$initTaskDonePayload(task)));
			return _List_fromArray(
				[
					$elm$html$Html$Events$onClick(msg)
				]);
		};
		var iconDefault = 'radio_button_unchecked';
		var taskToIcon = function (task) {
			return task.gx ? 'check_circle_outline' : iconDefault;
		};
		var icon = A2(
			$elm$core$Maybe$withDefault,
			iconDefault,
			A2($elm$core$Maybe$map, taskToIcon, maybeTask));
		var attrsBase = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin-right', '5px'),
				A2(
				$elm$html$Html$Attributes$style,
				'color',
				isDisabled ? '#B0B0B0' : '#606060'),
				A2($elm$html$Html$Attributes$style, 'font-size', '20px'),
				$elm$html$Html$Attributes$disabled(isDisabled)
			]);
		var attrs = A2(
			$elm$core$List$append,
			attrsBase,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, makeActionAttrs, maybeTask)));
		return A2($aforemny$material_components_web_elm$Material$Icon$icon, attrs, icon);
	});
var $author$project$App$Task$View$viewDC = function (model) {
	var ongoingEffort = model.eZ;
	var task = model.fI;
	var form = model.ba;
	var viewTask = function (_v1) {
		var done = _v1.gx;
		var description = _v1.ec;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					$aforemny$material_components_web_elm$Material$Typography$headline5
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(description)
				]));
	};
	var mainContent = function () {
		if (!task.$) {
			var t = task.a;
			return viewTask(t);
		} else {
			return $author$project$Components$default;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'center')
			]),
		_List_fromArray(
			[
				A2($author$project$App$Task$View$viewTaskCheckIcon, task, false),
				mainContent
			]));
};
var $author$project$App$Task$Messages$SetForm = {$: 8};
var $author$project$App$Task$Messages$ToggleMenu = {$: 10};
var $aforemny$material_components_web_elm$Material$Typography$body2 = $elm$html$Html$Attributes$class('mdc-typography--body2');
var $author$project$App$Task$Messages$ToDelete = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Task$View$menuItems = function (task) {
	return _List_fromArray(
		[
			_Utils_Tuple3('Edit', $author$project$App$Task$Messages$SetForm, false),
			_Utils_Tuple3(
			'Delete',
			$author$project$App$Task$Messages$CmdReq(
				$author$project$App$Task$Messages$ToDelete(task)),
			false)
		]);
};
var $author$project$App$Task$Messages$FormMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Form$Messages$Abort = {$: 3};
var $author$project$App$Form$Messages$Submit = {$: 2};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$App$Form$View$getFieldAttrs = function (field) {
	switch (field.$) {
		case 0:
			return _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$headline5,
					A2($elm$html$Html$Attributes$style, 'font-weight', '200')
				]);
		case 1:
			return _List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$body1,
					A2($elm$html$Html$Attributes$style, 'font-weight', '500')
				]);
		case 3:
			return _List_fromArray(
				[$aforemny$material_components_web_elm$Material$Typography$headline6]);
		default:
			return _List_fromArray(
				[$aforemny$material_components_web_elm$Material$Typography$body2]);
	}
};
var $author$project$App$Form$View$getFieldTriple = function (field) {
	switch (field.$) {
		case 0:
			var s = field.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just('Project title'),
				$elm$core$Maybe$Just(s),
				$elm$core$Maybe$Just('Give your project a name'));
		case 1:
			var s = field.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just('Objective description'),
				$elm$core$Maybe$Just(s),
				$elm$core$Maybe$Nothing);
		case 2:
			var s = field.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just('Task description'),
				$elm$core$Maybe$Just(s),
				$elm$core$Maybe$Nothing);
		case 4:
			var mi = field.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just('Estimated units'),
				A2($elm$core$Maybe$map, $elm$core$String$fromInt, mi),
				$elm$core$Maybe$Nothing);
		default:
			var mi = field.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just('Due by'),
				A2($elm$core$Maybe$map, $elm$core$String$fromInt, mi),
				$elm$core$Maybe$Nothing);
	}
};
var $author$project$App$Form$Model$DueBy = function (a) {
	return {$: 3, a: a};
};
var $author$project$App$Form$Model$EstimatedUnits = function (a) {
	return {$: 4, a: a};
};
var $author$project$App$Form$Messages$Update = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Form$View$onInputOp = F2(
	function (field, newValue) {
		return $author$project$App$Form$Messages$Update(
			function () {
				switch (field.$) {
					case 0:
						return $author$project$App$Form$Model$Title(newValue);
					case 1:
						return $author$project$App$Form$Model$ObjDescription(newValue);
					case 2:
						return $author$project$App$Form$Model$TaskDescription(newValue);
					case 4:
						return $author$project$App$Form$Model$EstimatedUnits(
							$elm$core$String$toInt(newValue));
					default:
						return $author$project$App$Form$Model$DueBy(
							$elm$core$String$toInt(newValue));
				}
			}());
	});
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$App$Form$View$viewField = function (field) {
	var dealWithNothing = function (maybeString) {
		return A2($elm$core$Maybe$withDefault, '', maybeString);
	};
	var attrsField = $author$project$App$Form$View$getFieldAttrs(field);
	var _v0 = $author$project$App$Form$View$getFieldTriple(field);
	var maybeLabel = _v0.a;
	var maybeVal = _v0.b;
	var maybePlaceholder = _v0.c;
	var attrsBase = _List_fromArray(
		[
			$elm$html$Html$Events$onInput(
			$author$project$App$Form$View$onInputOp(field)),
			$elm$html$Html$Attributes$value(
			dealWithNothing(maybeVal)),
			$elm$html$Html$Attributes$placeholder(
			dealWithNothing(maybePlaceholder)),
			$elm$html$Html$Attributes$autocomplete(false),
			A2($elm$html$Html$Attributes$style, 'background-color', '#F5F5F5'),
			A2($elm$html$Html$Attributes$style, 'border', 'none'),
			A2($elm$html$Html$Attributes$style, 'outline', 'none'),
			A2($elm$html$Html$Attributes$style, 'width', '100%'),
			A2($elm$html$Html$Attributes$style, 'padding', '0')
		]);
	var attrs = A2($elm$core$List$append, attrsBase, attrsField);
	return A2($elm$html$Html$input, attrs, _List_Nil);
};
var $author$project$App$Form$View$view = function (_v0) {
	var fields = _v0.eq;
	var backdrop = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'transparent'),
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'right', '0'),
				A2($elm$html$Html$Attributes$style, 'bottom', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0'),
				$elm$html$Html$Events$onClick($author$project$App$Form$Messages$Abort),
				A2($elm$html$Html$Attributes$style, 'z-index', '10')
			]),
		_List_Nil);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$App$Form$Messages$Submit),
						A2($elm$html$Html$Attributes$style, 'position', 'relative'),
						A2($elm$html$Html$Attributes$style, 'z-index', '20')
					]),
				A2($elm$core$List$map, $author$project$App$Form$View$viewField, fields)),
				backdrop
			]));
};
var $author$project$App$Task$View$viewForm = F2(
	function (attrs, fModel) {
		var form = A2(
			$elm$html$Html$map,
			$author$project$App$Task$Messages$FormMsg,
			A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$default,
				A2($elm$core$Maybe$map, $author$project$App$Form$View$view, fModel)));
		return A2(
			$elm$html$Html$div,
			attrs,
			_List_fromArray(
				[form]));
	});
var $aforemny$material_components_web_elm$Material$List$Item$config = {ce: _List_Nil, a6: false, bd: $elm$core$Maybe$Nothing, bL: false, c2: $elm$core$Maybe$Nothing, b3: false, hu: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$Menu$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Menu$config = {ce: _List_Nil, bX: $elm$core$Maybe$Nothing, c4: false, dg: false};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$ListItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Activated = 1;
var $aforemny$material_components_web_elm$Material$List$Item$activatedCs = function (_v0) {
	var selection = _v0.hu;
	return _Utils_eq(
		selection,
		$elm$core$Maybe$Just(1)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list-item--activated')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$ariaSelectedAttr = function (_v0) {
	var selection = _v0.hu;
	return (!_Utils_eq(selection, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		A2($elm$html$Html$Attributes$attribute, 'aria-selected', 'true')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$disabledCs = function (_v0) {
	var disabled = _v0.a6;
	return disabled ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list-item--disabled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$hrefAttr = function (_v0) {
	var href = _v0.bd;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$List$Item$interactiveProp = function (_v0) {
	var interactive = _v0.bL;
	return A2(
		$elm$html$Html$Attributes$property,
		'interactive',
		$elm$json$Json$Encode$bool(interactive));
};
var $aforemny$material_components_web_elm$Material$List$Item$listItemCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-deprecated-list-item'));
var $aforemny$material_components_web_elm$Material$List$Item$rippleElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-deprecated-list-item__ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$List$Item$ripplesProp = function (_v0) {
	var ripples = _v0.b3;
	return A2(
		$elm$html$Html$Attributes$property,
		'ripples',
		$elm$json$Json$Encode$bool(ripples));
};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Selected = 0;
var $aforemny$material_components_web_elm$Material$List$Item$selectedCs = function (_v0) {
	var selection = _v0.hu;
	return _Utils_eq(
		selection,
		$elm$core$Maybe$Just(0)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-deprecated-list-item--selected')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$targetAttr = function (_v0) {
	var href = _v0.bd;
	var target = _v0.dB;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$listItemView = F2(
	function (config_, nodes) {
		var additionalAttributes = config_.ce;
		var href = config_.bd;
		return A2(
			F2(
				function (attributes, nodes_) {
					return A3(
						$elm$html$Html$node,
						'mdc-list-item',
						_Utils_ap(
							_List_fromArray(
								[
									$aforemny$material_components_web_elm$Material$List$Item$ripplesProp(config_),
									$aforemny$material_components_web_elm$Material$List$Item$interactiveProp(config_)
								]),
							(!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? _List_Nil : attributes),
						(!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? _List_fromArray(
							[
								A2($elm$html$Html$a, attributes, nodes_)
							]) : nodes_);
				}),
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$Item$listItemCs,
							$aforemny$material_components_web_elm$Material$List$Item$hrefAttr(config_),
							$aforemny$material_components_web_elm$Material$List$Item$targetAttr(config_),
							$aforemny$material_components_web_elm$Material$List$Item$disabledCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$selectedCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$activatedCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$ariaSelectedAttr(config_)
						])),
				additionalAttributes),
			A2($elm$core$List$cons, $aforemny$material_components_web_elm$Material$List$Item$rippleElt, nodes));
	});
var $aforemny$material_components_web_elm$Material$List$Item$listItem = F2(
	function (config_, nodes) {
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$Internal$ListItem,
			function (config__) {
				return A2($aforemny$material_components_web_elm$Material$List$Item$listItemView, config__, nodes);
			},
			config_);
	});
var $author$project$Components$makeIconButton = F3(
	function (attrs, msg, iconS) {
		var attrsBase = _List_fromArray(
			[
				$elm$html$Html$Events$onClick(msg),
				A2($elm$html$Html$Attributes$style, 'color', '#606060'),
				A2($elm$html$Html$Attributes$style, 'font-size', '20px')
			]);
		var attrsCombined = A2($elm$core$List$append, attrsBase, attrs);
		return A2($aforemny$material_components_web_elm$Material$Icon$icon, attrsCombined, iconS);
	});
var $aforemny$material_components_web_elm$Material$List$Item$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{a6: disabled});
	});
var $aforemny$material_components_web_elm$Material$List$Item$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Menu$setOnClose = F2(
	function (onClose, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bX: $elm$core$Maybe$Just(onClose)
			});
	});
var $aforemny$material_components_web_elm$Material$Menu$setOpen = F2(
	function (open, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{c4: open});
	});
var $aforemny$material_components_web_elm$Material$Menu$surfaceAnchor = $elm$html$Html$Attributes$class('mdc-menu-surface--anchor');
var $author$project$Components$viewMenu = F3(
	function (showMenu, toggleMsg, menuItems) {
		var menuConfig = A2(
			$aforemny$material_components_web_elm$Material$Menu$setOnClose,
			toggleMsg,
			A2($aforemny$material_components_web_elm$Material$Menu$setOpen, showMenu, $aforemny$material_components_web_elm$Material$Menu$config));
		var menuButton = A3(
			$author$project$Components$makeIconButton,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
					A2($elm$html$Html$Attributes$style, 'color', '#A0A0A0')
				]),
			toggleMsg,
			'more_vert');
		var makeItem = function (_v2) {
			var txt = _v2.a;
			var msg = _v2.b;
			var isDisabled = _v2.c;
			var config = A2(
				$aforemny$material_components_web_elm$Material$List$Item$setDisabled,
				isDisabled,
				A2($aforemny$material_components_web_elm$Material$List$Item$setOnClick, msg, $aforemny$material_components_web_elm$Material$List$Item$config));
			return A2(
				$aforemny$material_components_web_elm$Material$List$Item$listItem,
				config,
				_List_fromArray(
					[
						$elm$html$Html$text(txt)
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$Menu$surfaceAnchor]),
			_List_fromArray(
				[
					menuButton,
					function () {
					var _v0 = A2(
						$elm$core$List$filter,
						function (_v1) {
							var isD = _v1.c;
							return !isD;
						},
						menuItems);
					if (!_v0.b) {
						return $author$project$Components$default;
					} else {
						var h = _v0.a;
						var t = _v0.b;
						return A3(
							$aforemny$material_components_web_elm$Material$Menu$menu,
							menuConfig,
							makeItem(h),
							A2($elm$core$List$map, makeItem, t));
					}
				}()
				]));
	});
var $author$project$App$Task$Messages$ToAddToQ = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Task$Messages$ToRemoveFromQ = function (a) {
	return {$: 6, a: a};
};
var $author$project$App$Task$View$viewQAction = F2(
	function (maybeEffortExp, _v0) {
		var id = _v0.eC;
		var done = _v0.gx;
		var _v1 = function () {
			if (!maybeEffortExp.$) {
				var effortExp = maybeEffortExp.a;
				return _Utils_Tuple2(
					$author$project$App$Task$Messages$CmdReq(
						$author$project$App$Task$Messages$ToRemoveFromQ(effortExp.eC)),
					'playlist_remove');
			} else {
				return _Utils_Tuple2(
					$author$project$App$Task$Messages$CmdReq(
						$author$project$App$Task$Messages$ToAddToQ(id)),
					'playlist_add');
			}
		}();
		var msg = _v1.a;
		var iconString = _v1.b;
		return (!done) ? A3(
			$author$project$Components$makeIconButton,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin-right', '8px')
				]),
			msg,
			iconString) : $author$project$Components$default;
	});
var $author$project$App$Task$View$viewTOL = function (model) {
	var ongoingEffort = model.eZ;
	var task = model.fI;
	var form = model.ba;
	var attrsBase = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'width', '100%')
		]);
	var attrsAction = _List_fromArray(
		[
			$elm$html$Html$Events$onClick($author$project$App$Task$Messages$SetForm)
		]);
	var attrs = A2($elm$core$List$append, attrsBase, attrsAction);
	var viewTask = function (_v4) {
		var done = _v4.gx;
		var description = _v4.ec;
		return A2(
			$elm$html$Html$div,
			attrs,
			_List_fromArray(
				[
					$elm$html$Html$text(description)
				]));
	};
	var _v0 = function () {
		var _v2 = _Utils_Tuple2(task, form);
		if (_v2.b.$ === 1) {
			if (!_v2.a.$) {
				var t = _v2.a.a;
				var _v3 = _v2.b;
				return _Utils_Tuple2(
					_Utils_Tuple3(
						viewTask(t),
						A2($author$project$App$Task$View$viewQAction, ongoingEffort, t),
						A3(
							$author$project$Components$viewMenu,
							model.eO,
							$author$project$App$Task$Messages$ToggleMenu,
							$author$project$App$Task$View$menuItems(t))),
					false);
			} else {
				return _Utils_Tuple2(
					_Utils_Tuple3($author$project$Components$default, $author$project$Components$default, $author$project$Components$default),
					true);
			}
		} else {
			if (!_v2.a.$) {
				var t = _v2.a.a;
				return _Utils_Tuple2(
					_Utils_Tuple3(
						A2($author$project$App$Task$View$viewForm, attrsBase, form),
						A2($author$project$App$Task$View$viewQAction, ongoingEffort, t),
						A3(
							$author$project$Components$viewMenu,
							model.eO,
							$author$project$App$Task$Messages$ToggleMenu,
							$author$project$App$Task$View$menuItems(t))),
					false);
			} else {
				return _Utils_Tuple2(
					_Utils_Tuple3(
						A2($author$project$App$Task$View$viewForm, attrsBase, form),
						$author$project$Components$default,
						$author$project$Components$default),
					true);
			}
		}
	}();
	var _v1 = _v0.a;
	var mainContent = _v1.a;
	var qAction = _v1.b;
	var menu = _v1.c;
	var checkboxIsDisabled = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$body2,
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'align-content', 'space-between'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'start'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '3px'),
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'align-items', 'start')
					]),
				_List_fromArray(
					[
						A2($author$project$App$Task$View$viewTaskCheckIcon, task, checkboxIsDisabled),
						mainContent
					])),
				qAction,
				menu
			]));
};
var $author$project$App$Task$View$view = F2(
	function (context, model) {
		switch (context) {
			case 0:
				return $author$project$App$Task$View$viewTOL(model);
			case 2:
				return $author$project$App$Task$View$viewDC(model);
			default:
				return $author$project$Components$default;
		}
	});
var $author$project$App$Doing$View$viewTaskCard = function (_v0) {
	var objectiveId = _v0.a;
	var taskId = _v0.b;
	var tModel = _v0.c;
	var msgMap = function (tMsg) {
		return A2(
			$author$project$App$Project$Messages$TaskedObjectiveMsg,
			$elm$core$Maybe$Just(objectiveId),
			A2(
				$author$project$App$TaskedObjective$Messages$TaskMsg,
				$elm$core$Maybe$Just(taskId),
				tMsg));
	};
	var config = A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config);
	var body = $aforemny$material_components_web_elm$Material$Card$block(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '1rem')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					msgMap,
					A2($author$project$App$Task$View$view, 2, tModel))
				])));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'min-width', '320px')
			]),
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$Card$card,
				config,
				{
					f4: $elm$core$Maybe$Nothing,
					gf: _Utils_Tuple2(body, _List_Nil)
				})
			]));
};
var $author$project$App$Doing$View$viewWithNav = function (model) {
	var doing = model.ed;
	var ongoingEfforts = model.e_;
	var taskedObjectives = model.hK;
	var navigation = ($elm$core$Dict$size(ongoingEfforts) > 1) ? $author$project$App$Doing$View$viewNavigation : $author$project$Components$default;
	var lookUpOId = function (id) {
		return A2(
			$elm$core$Maybe$map,
			function (eE) {
				return _Utils_Tuple2(eE.g9, id);
			},
			A2($elm$core$Dict$get, id, ongoingEfforts));
	};
	var getTaskModel = function (_v1) {
		var oId = _v1.a;
		var tId = _v1.b;
		var fnc = function (tasks) {
			var _v0 = A2($elm$core$Dict$get, tId, tasks);
			if (!_v0.$) {
				var tModel = _v0.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple3(oId, tId, tModel));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		return A2(
			$elm$core$Maybe$andThen,
			fnc,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hL;
				},
				A2($elm$core$Dict$get, oId, taskedObjectives)));
	};
	var taskCard = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Components$default,
		A2(
			$elm$core$Maybe$map,
			$author$project$App$Doing$View$viewTaskCard,
			A2(
				$elm$core$Maybe$andThen,
				getTaskModel,
				A2($elm$core$Maybe$andThen, lookUpOId, doing))));
	var attrs = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'top', '50%'),
			A2($elm$html$Html$Attributes$style, 'left', '50%'),
			A2($elm$html$Html$Attributes$style, 'transform', 'translate(-50%, -50%)')
		]);
	return A2(
		$elm$html$Html$div,
		attrs,
		_List_fromArray(
			[taskCard, navigation]));
};
var $author$project$App$Doing$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'top', '0px'),
				A2($elm$html$Html$Attributes$style, 'right', '0px'),
				A2($elm$html$Html$Attributes$style, 'bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'left', '0px'),
				A2($elm$html$Html$Attributes$style, 'z-index', '10'),
				A2($elm$html$Html$Attributes$style, 'background-color', '#F2F3F4')
			]),
		_List_fromArray(
			[
				$author$project$App$Doing$View$viewWithNav(model)
			]));
};
var $author$project$Models$App$InTaskedObjective = 0;
var $author$project$App$TaskedObjective$Messages$ToggleMenu = {$: 2};
var $author$project$App$TaskedObjective$Model$getTasksToShow = function (_v0) {
	var tasks = _v0.hL;
	var hiddenTasks = _v0.aq;
	var filterFnc = function (_v1) {
		var tId = _v1.a;
		var task = _v1.b;
		return !A2($elm$core$Set$member, tId, hiddenTasks);
	};
	return A2(
		$elm$core$List$filter,
		filterFnc,
		$elm$core$Dict$toList(tasks));
};
var $author$project$App$TaskedObjective$Model$hasAllTasksHidden = function (_v0) {
	var tasks = _v0.hL;
	var hiddenTasks = _v0.aq;
	var taskIds = $elm$core$Set$fromList(
		$elm$core$Dict$keys(tasks));
	return ($elm$core$Set$size(taskIds) > 0) && _Utils_eq(hiddenTasks, taskIds);
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$App$TaskedObjective$Model$hasUndoneTasks = function (_v0) {
	var tasks = _v0.hL;
	var fmFnc = function (_v1) {
		var task = _v1.fI;
		return $author$project$Models$Project$potentialToMaybe(task);
	};
	return !A2(
		$elm$core$List$all,
		function ($) {
			return $.gx;
		},
		A2(
			$elm$core$List$filterMap,
			fmFnc,
			$elm$core$Dict$values(tasks)));
};
var $author$project$App$Objective$Messages$SetForm = {$: 4};
var $author$project$App$TaskedObjective$Messages$ToggleShowAllTasks = {$: 0};
var $author$project$App$TaskedObjective$Model$hasDoneTasks = function (_v0) {
	var tasks = _v0.hL;
	var doneTaskIds = $author$project$App$TaskedObjective$Model$getDoneTaskIds(tasks);
	return $elm$core$Set$size(doneTaskIds) > 0;
};
var $author$project$App$TaskedObjective$Model$hasHiddenTasks = function (_v0) {
	var tasks = _v0.hL;
	var hiddenTasks = _v0.aq;
	var doneTaskIds = $author$project$App$TaskedObjective$Model$getDoneTaskIds(tasks);
	var hiddenTasksCount = $elm$core$Set$size(
		A2($elm$core$Set$intersect, hiddenTasks, doneTaskIds));
	return hiddenTasksCount > 0;
};
var $author$project$App$TaskedObjective$View$menuItems = function (model) {
	var showHideToggleCond = $author$project$App$TaskedObjective$Model$hasHiddenTasks(model) && $author$project$App$TaskedObjective$Model$hasDoneTasks(model);
	return _List_fromArray(
		[
			_Utils_Tuple3(
			showHideToggleCond ? 'Show all' : 'Hide done',
			$author$project$App$TaskedObjective$Messages$ToggleShowAllTasks,
			!$author$project$App$TaskedObjective$Model$hasDoneTasks(model)),
			_Utils_Tuple3(
			'Edit',
			$author$project$App$TaskedObjective$Messages$ObjectiveMsg($author$project$App$Objective$Messages$SetForm),
			false)
		]);
};
var $author$project$App$Form$Model$formIsObjective = function (maybeModel) {
	if (!maybeModel.$) {
		var form = maybeModel.a.ba;
		if (form.$ === 1) {
			var oP = form.a;
			return $elm$core$Maybe$Just(oP);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$Objective$Messages$FormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$App$Objective$View$viewForm = F2(
	function (attrs, fModel) {
		var form = A2(
			$elm$html$Html$map,
			$author$project$App$Objective$Messages$FormMsg,
			A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$default,
				A2($elm$core$Maybe$map, $author$project$App$Form$View$view, fModel)));
		return A2(
			$elm$html$Html$div,
			attrs,
			_List_fromArray(
				[form]));
	});
var $author$project$App$Objective$View$view = function (_v0) {
	var form = _v0.ba;
	var objective = _v0.eV;
	var maybeObjective = $author$project$Models$Project$potentialToMaybe(objective);
	var attrsBase = _List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$body1,
			A2($elm$html$Html$Attributes$style, 'font-weight', '500'),
			A2($elm$html$Html$Attributes$style, 'min-height', '15px'),
			A2($elm$html$Html$Attributes$style, 'width', '100%')
		]);
	var attrsAction = _List_fromArray(
		[
			$elm$html$Html$Events$onClick($author$project$App$Objective$Messages$SetForm)
		]);
	var attrs = A2($elm$core$List$append, attrsBase, attrsAction);
	var viewObjective = function (o) {
		if (!o.$) {
			var description = o.a.ec;
			return A2(
				$elm$html$Html$div,
				attrs,
				_List_fromArray(
					[
						$elm$html$Html$text(description)
					]));
		} else {
			return $author$project$Components$default;
		}
	};
	var _v1 = $author$project$App$Form$Model$formIsObjective(form);
	if (!_v1.$) {
		return A2($author$project$App$Objective$View$viewForm, attrsBase, form);
	} else {
		return viewObjective(objective);
	}
};
var $author$project$App$TaskedObjective$Messages$AddTask = {$: 3};
var $author$project$App$TaskedObjective$View$viewAddTaskOrForm = function (_v0) {
	var newTask = _v0.bR;
	var viewButton = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$Components$makeTextButton,
				$author$project$App$TaskedObjective$Messages$AddTask,
				'Add task',
				$elm$core$Maybe$Just('add'))
			]));
	var content = function () {
		var viewFnc = function (tModel) {
			return A2(
				$elm$html$Html$map,
				$author$project$App$TaskedObjective$Messages$TaskMsg($elm$core$Maybe$Nothing),
				A2($author$project$App$Task$View$view, 0, tModel));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			viewButton,
			A2($elm$core$Maybe$map, viewFnc, newTask));
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$body2,
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '3px')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$App$TaskedObjective$Messages$ToggleHideTasks = {$: 1};
var $author$project$App$TaskedObjective$View$viewObjectiveMinMax = function (model) {
	var iconString = $author$project$App$TaskedObjective$Model$hasAllTasksHidden(model) ? 'chevron_right' : 'expand_more';
	return A3(
		$author$project$Components$makeIconButton,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '5px')
			]),
		$author$project$App$TaskedObjective$Messages$ToggleHideTasks,
		iconString);
};
var $author$project$App$TaskedObjective$View$view = function (model) {
	var objective = model.eV;
	var tasks = model.hL;
	var hiddenTasks = model.aq;
	var menu = model.eO;
	var viewTask = function (_v0) {
		var tId = _v0.a;
		var tModel = _v0.b;
		return A2(
			$elm$html$Html$map,
			$author$project$App$TaskedObjective$Messages$TaskMsg(
				$elm$core$Maybe$Just(tId)),
			A2($author$project$App$Task$View$view, 0, tModel));
	};
	var hasUndone = $author$project$App$TaskedObjective$Model$hasUndoneTasks(model);
	var hasHiddenTasks = $author$project$App$TaskedObjective$Model$hasAllTasksHidden(model);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin-bottom', '15px')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'align-content', 'space-between'),
						A2($elm$html$Html$Attributes$style, 'align-items', 'start'),
						A2($elm$html$Html$Attributes$style, 'border-bottom-style', 'solid'),
						A2($elm$html$Html$Attributes$style, 'border-bottom-width', '0.1px'),
						A2($elm$html$Html$Attributes$style, 'border-bottom-color', '#D1D1D1')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$App$TaskedObjective$Messages$ObjectiveMsg,
						$author$project$App$Objective$View$view(objective)),
						hasUndone ? $author$project$App$TaskedObjective$View$viewObjectiveMinMax(model) : $author$project$Components$default,
						A3(
						$author$project$Components$viewMenu,
						menu,
						$author$project$App$TaskedObjective$Messages$ToggleMenu,
						$author$project$App$TaskedObjective$View$menuItems(model))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				$elm$core$List$concat(
					_List_fromArray(
						[
							A2(
							$elm$core$List$map,
							viewTask,
							$author$project$App$TaskedObjective$Model$getTasksToShow(model)),
							(hasHiddenTasks && hasUndone) ? _List_Nil : _List_fromArray(
							[
								$author$project$App$TaskedObjective$View$viewAddTaskOrForm(model)
							])
						])))
			]));
};
var $author$project$App$Watcher$Messages$Close = {$: 6};
var $author$project$App$Watcher$Messages$CloseSnackbar = function (a) {
	return {$: 8, a: a};
};
var $aforemny$material_components_web_elm$Material$Dialog$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Dialog$config = {ce: _List_Nil, gC: true, bG: false, bX: $elm$core$Maybe$Nothing, c4: false, dl: true};
var $aforemny$material_components_web_elm$Material$Dialog$closeHandler = function (_v0) {
	var onClose = _v0.bX;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCDialog:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $aforemny$material_components_web_elm$Material$Dialog$actionsElt = function (_v0) {
	var actions = _v0.f4;
	return $elm$core$List$isEmpty(actions) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-dialog__actions')
				]),
			actions));
};
var $aforemny$material_components_web_elm$Material$Dialog$alertDialogRoleAttr = A2($elm$html$Html$Attributes$attribute, 'role', 'alertdialog');
var $aforemny$material_components_web_elm$Material$Dialog$ariaModalAttr = A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true');
var $aforemny$material_components_web_elm$Material$Dialog$contentElt = function (_v0) {
	var content = _v0.gn;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-dialog__content')
				]),
			content));
};
var $aforemny$material_components_web_elm$Material$Dialog$dialogSurfaceCs = $elm$html$Html$Attributes$class('mdc-dialog__surface');
var $aforemny$material_components_web_elm$Material$IconButton$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$IconButton$config = {ce: _List_Nil, a6: false, bd: $elm$core$Maybe$Nothing, g_: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$IconButton$Internal$Icon = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$IconButton$customIcon = F3(
	function (node, attributes, nodes) {
		return $aforemny$material_components_web_elm$Material$IconButton$Internal$Icon(
			{bw: attributes, bS: node, bT: nodes});
	});
var $aforemny$material_components_web_elm$Material$IconButton$icon = function (iconName) {
	return A3(
		$aforemny$material_components_web_elm$Material$IconButton$customIcon,
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(iconName)
			]));
};
var $aforemny$material_components_web_elm$Material$IconButton$clickHandler = function (_v0) {
	var onClick = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$IconButton$disabledAttr = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$disabled(disabled));
};
var $aforemny$material_components_web_elm$Material$IconButton$hrefAttr = function (_v0) {
	var href = _v0.bd;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$IconButton$iconButtonCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-icon-button'));
var $aforemny$material_components_web_elm$Material$IconButton$targetAttr = function (_v0) {
	var href = _v0.bd;
	var target = _v0.dB;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$IconButton$iconButton = F2(
	function (config_, icon_) {
		var innerConfig = config_;
		var additionalAttributes = innerConfig.ce;
		var href = innerConfig.bd;
		var disabled = innerConfig.a6;
		var wrapMenu = function (node) {
			var _v1 = innerConfig.eO;
			if (_v1.$ === 1) {
				return node;
			} else {
				var _v2 = _v1.a;
				var menuConfig = _v2.a;
				var firstListItem = _v2.b;
				var remainingListItems = _v2.c;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$Menu$surfaceAnchor]),
					_List_fromArray(
						[
							node,
							A3($aforemny$material_components_web_elm$Material$Menu$menu, menuConfig, firstListItem, remainingListItems)
						]));
			}
		};
		return wrapMenu(
			A3(
				$elm$html$Html$node,
				'mdc-icon-button',
				_List_Nil,
				_List_fromArray(
					[
						A2(
						((!_Utils_eq(href, $elm$core$Maybe$Nothing)) && (!disabled)) ? $elm$html$Html$a : $elm$html$Html$button,
						_Utils_ap(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$IconButton$iconButtonCs,
										$aforemny$material_components_web_elm$Material$IconButton$hrefAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$targetAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$disabledAttr(config_),
										$aforemny$material_components_web_elm$Material$IconButton$clickHandler(config_)
									])),
							additionalAttributes),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$elm$core$Basics$never,
								function () {
									if (!icon_.$) {
										var node = icon_.a.bS;
										var attributes = icon_.a.bw;
										var nodes = icon_.a.bT;
										return A2(
											node,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Attributes$class('mdc-icon-button__icon'),
												attributes),
											nodes);
									} else {
										var node = icon_.a.bS;
										var attributes = icon_.a.bw;
										var nodes = icon_.a.bT;
										return A2(
											node,
											A2(
												$elm$core$List$cons,
												$elm$svg$Svg$Attributes$class('mdc-icon-button__icon'),
												attributes),
											nodes);
									}
								}())
							]))
					])));
	});
var $aforemny$material_components_web_elm$Material$IconButton$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Dialog$titleElt = function (_v0) {
	var title = _v0.hS;
	if (!title.$) {
		var title_ = title.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-dialog__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title_)
					])));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $aforemny$material_components_web_elm$Material$Dialog$headerElt = F2(
	function (_v0, content) {
		var onClose = _v0.bX;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-dialog__header')
					]),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Dialog$titleElt(content),
							$elm$core$Maybe$Just(
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-dialog__close'),
										A2($elm$html$Html$Attributes$style, 'position', 'relative')
									]),
								_List_fromArray(
									[
										A2(
										$aforemny$material_components_web_elm$Material$IconButton$iconButton,
										function () {
											if (!onClose.$) {
												var onClose_ = onClose.a;
												return $aforemny$material_components_web_elm$Material$IconButton$setOnClick(onClose_);
											} else {
												return $elm$core$Basics$identity;
											}
										}()($aforemny$material_components_web_elm$Material$IconButton$config),
										$aforemny$material_components_web_elm$Material$IconButton$icon('close'))
									])))
						]))));
	});
var $aforemny$material_components_web_elm$Material$Dialog$surfaceElt = F2(
	function (config__, content) {
		var config_ = config__;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$Dialog$dialogSurfaceCs, $aforemny$material_components_web_elm$Material$Dialog$alertDialogRoleAttr, $aforemny$material_components_web_elm$Material$Dialog$ariaModalAttr]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						config_.bG ? A2($aforemny$material_components_web_elm$Material$Dialog$headerElt, config__, content) : $aforemny$material_components_web_elm$Material$Dialog$titleElt(content),
						$aforemny$material_components_web_elm$Material$Dialog$contentElt(content),
						$aforemny$material_components_web_elm$Material$Dialog$actionsElt(content)
					])));
	});
var $aforemny$material_components_web_elm$Material$Dialog$containerElt = F2(
	function (config_, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-dialog__container')
				]),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$Dialog$surfaceElt, config_, content)
				]));
	});
var $aforemny$material_components_web_elm$Material$Dialog$fullscreenCs = function (_v0) {
	var config_ = _v0;
	return config_.bG ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-dialog--fullscreen')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Dialog$openProp = function (_v0) {
	var open = _v0.c4;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Dialog$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-dialog'));
var $aforemny$material_components_web_elm$Material$Dialog$scrimClickActionProp = function (_v0) {
	var scrimCloses = _v0.dl;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'scrimClickAction',
			$elm$json$Json$Encode$string(
				scrimCloses ? 'close' : '')));
};
var $aforemny$material_components_web_elm$Material$Dialog$scrimElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-dialog__scrim')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Dialog$generic = F2(
	function (config_, content) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-dialog',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Dialog$rootCs,
							$aforemny$material_components_web_elm$Material$Dialog$fullscreenCs(config_),
							$aforemny$material_components_web_elm$Material$Dialog$openProp(config_),
							$aforemny$material_components_web_elm$Material$Dialog$scrimClickActionProp(config_),
							$aforemny$material_components_web_elm$Material$Dialog$closeHandler(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$Dialog$containerElt, config_, content),
					$aforemny$material_components_web_elm$Material$Dialog$scrimElt
				]));
	});
var $aforemny$material_components_web_elm$Material$Dialog$confirmation = F2(
	function (config_, _v0) {
		var title = _v0.hS;
		var content = _v0.gn;
		var actions = _v0.f4;
		return A2(
			$aforemny$material_components_web_elm$Material$Dialog$generic,
			config_,
			{
				f4: actions,
				gn: content,
				hS: $elm$core$Maybe$Just(title)
			});
	});
var $aforemny$material_components_web_elm$Material$Dialog$setOnClose = F2(
	function (onClose, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bX: $elm$core$Maybe$Just(onClose)
			});
	});
var $aforemny$material_components_web_elm$Material$Dialog$setOpen = F2(
	function (open, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{c4: open});
	});
var $author$project$App$Watcher$Messages$CmdReq = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$Watcher$Messages$ToPostSupporterWatcher = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$App$Watcher$Messages$ToPostWatcher = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$App$Watcher$View$makeWatcherPayload = F2(
	function (v, id) {
		return {fU: id, dJ: v};
	});
var $author$project$App$Watcher$Messages$SelectedVisibility = function (a) {
	return {$: 11, a: a};
};
var $aforemny$material_components_web_elm$Material$Select$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Select$config = {ce: _List_Nil, a6: false, g_: $elm$core$Maybe$Nothing, eL: $elm$core$Maybe$Nothing, c1: $elm$core$Maybe$Nothing, di: false, fu: $elm$core$Maybe$Nothing, dH: true};
var $aforemny$material_components_web_elm$Material$Select$Item$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Select$Item$config = function (_v0) {
	var value = _v0.h7;
	return {ce: _List_Nil, a6: false, h7: value};
};
var $aforemny$material_components_web_elm$Material$Select$Outlined = 1;
var $aforemny$material_components_web_elm$Material$Select$Filled = 0;
var $aforemny$material_components_web_elm$Material$Select$anchorCs = $elm$html$Html$Attributes$class('mdc-select__anchor');
var $aforemny$material_components_web_elm$Material$Select$ariaExpanded = function (value) {
	return A2(
		$elm$html$Html$Attributes$attribute,
		'aria-expanded',
		value ? 'true' : 'false');
};
var $aforemny$material_components_web_elm$Material$Select$ariaHaspopupAttr = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'aria-haspopup', value);
};
var $aforemny$material_components_web_elm$Material$Select$buttonRole = A2($elm$html$Html$Attributes$attribute, 'role', 'button');
var $aforemny$material_components_web_elm$Material$Select$anchorElt = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Select$anchorCs,
						$aforemny$material_components_web_elm$Material$Select$buttonRole,
						$aforemny$material_components_web_elm$Material$Select$ariaHaspopupAttr('listbox'),
						$aforemny$material_components_web_elm$Material$Select$ariaExpanded(false)
					]),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$Select$disabledProp = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $aforemny$material_components_web_elm$Material$Select$focusableAttr = function (value) {
	return A2(
		$elm$virtual_dom$VirtualDom$attribute,
		'focusable',
		value ? 'true' : 'false');
};
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$polygon = $elm$svg$Svg$trustedNode('polygon');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $aforemny$material_components_web_elm$Material$Select$dropdownIconElt = A2(
	$elm$html$Html$i,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-select__dropdown-icon')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('mdc-select__dropdown-icon-graphic'),
					$elm$svg$Svg$Attributes$viewBox('7 10 10 5'),
					$aforemny$material_components_web_elm$Material$Select$focusableAttr(false)
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$polygon,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('mdc-select__dropdown-icon-inactive'),
							$elm$svg$Svg$Attributes$stroke('none'),
							$elm$svg$Svg$Attributes$fillRule('evenodd'),
							$elm$svg$Svg$Attributes$points('7 10 12 15 17 10')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$polygon,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('mdc-select__dropdown-icon-active'),
							$elm$svg$Svg$Attributes$stroke('none'),
							$elm$svg$Svg$Attributes$fillRule('evenodd'),
							$elm$svg$Svg$Attributes$points('7 15 12 10 17 15')
						]),
					_List_Nil)
				]))
		]));
var $aforemny$material_components_web_elm$Material$Select$filledCs = function (variant) {
	return (!variant) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-select--filled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Select$floatingLabelElt = function (_v0) {
	var label = _v0.g_;
	return A2(
		$elm$core$Maybe$map,
		function (label_) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-floating-label')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label_)
					]));
		},
		label);
};
var $aforemny$material_components_web_elm$Material$Select$leadingIconCs = function (_v0) {
	var leadingIcon = _v0.eL;
	return A2(
		$elm$core$Maybe$map,
		function (_v1) {
			return $elm$html$Html$Attributes$class('mdc-select--with-leading-icon');
		},
		leadingIcon);
};
var $aforemny$material_components_web_elm$Material$Select$leadingIconElt = function (_v0) {
	var leadingIcon = _v0.eL;
	if (leadingIcon.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		if (!leadingIcon.a.$) {
			var node = leadingIcon.a.a.bS;
			var attributes = leadingIcon.a.a.bw;
			var nodes = leadingIcon.a.a.bT;
			var onInteraction = leadingIcon.a.a.eY;
			var disabled = leadingIcon.a.a.a6;
			return A2(
				node,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('mdc-select__icon'),
					function () {
						if (!onInteraction.$) {
							var msg = onInteraction.a;
							return (!disabled) ? A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$tabindex(0),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									A2(
										$elm$core$List$cons,
										$elm$html$Html$Events$onClick(msg),
										A2(
											$elm$core$List$cons,
											A2(
												$elm$html$Html$Events$on,
												'keydown',
												A2(
													$elm$json$Json$Decode$andThen,
													function (keyCode) {
														return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
													},
													$elm$html$Html$Events$keyCode)),
											attributes)))) : A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$tabindex(-1),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									attributes));
						} else {
							return attributes;
						}
					}()),
				nodes);
		} else {
			var node = leadingIcon.a.a.bS;
			var attributes = leadingIcon.a.a.bw;
			var nodes = leadingIcon.a.a.bT;
			var onInteraction = leadingIcon.a.a.eY;
			var disabled = leadingIcon.a.a.a6;
			return A2(
				node,
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$class('mdc-select__icon'),
					function () {
						if (!onInteraction.$) {
							var msg = onInteraction.a;
							return (!disabled) ? A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$tabindex(0),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									A2(
										$elm$core$List$cons,
										$elm$html$Html$Events$onClick(msg),
										A2(
											$elm$core$List$cons,
											A2(
												$elm$html$Html$Events$on,
												'keydown',
												A2(
													$elm$json$Json$Decode$andThen,
													function (keyCode) {
														return (keyCode === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
													},
													$elm$html$Html$Events$keyCode)),
											attributes)))) : A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$tabindex(-1),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
									attributes));
						} else {
							return attributes;
						}
					}()),
				nodes);
		}
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $aforemny$material_components_web_elm$Material$Select$lineRippleElt = A2(
	$elm$html$Html$label,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-line-ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$List$Item$graphic = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-deprecated-list-item__graphic'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$activated = 1;
var $aforemny$material_components_web_elm$Material$List$Item$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$List$Item$setSelected = F2(
	function (selection, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{hu: selection});
	});
var $aforemny$material_components_web_elm$Material$Select$listItemConfig = F3(
	function (selectedValue, onChange, config_) {
		var _v0 = config_;
		var value = _v0.h7;
		var disabled = _v0.a6;
		var additionalAttributes = _v0.ce;
		return function () {
			if (!onChange.$) {
				var onChange_ = onChange.a;
				return $aforemny$material_components_web_elm$Material$List$Item$setOnClick(
					onChange_(value));
			} else {
				return $elm$core$Basics$identity;
			}
		}()(
			A2(
				$aforemny$material_components_web_elm$Material$List$Item$setSelected,
				_Utils_eq(
					selectedValue,
					$elm$core$Maybe$Just(value)) ? $elm$core$Maybe$Just($aforemny$material_components_web_elm$Material$List$Item$activated) : $elm$core$Maybe$Nothing,
				A2(
					$aforemny$material_components_web_elm$Material$List$Item$setAttributes,
					additionalAttributes,
					A2($aforemny$material_components_web_elm$Material$List$Item$setDisabled, disabled, $aforemny$material_components_web_elm$Material$List$Item$config))));
	});
var $aforemny$material_components_web_elm$Material$Select$listItem = F4(
	function (leadingIcon, selected, onChange, _v0) {
		var config_ = _v0.a;
		var label = _v0.b;
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$listItem,
			A3($aforemny$material_components_web_elm$Material$Select$listItemConfig, selected, onChange, config_),
			$elm$core$List$concat(
				_List_fromArray(
					[
						(!_Utils_eq(leadingIcon, $elm$core$Maybe$Nothing)) ? _List_fromArray(
						[
							A2($aforemny$material_components_web_elm$Material$List$Item$graphic, _List_Nil, _List_Nil)
						]) : _List_Nil,
						_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mdc-deprecated-list-item__text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								]))
						])
					])));
	});
var $aforemny$material_components_web_elm$Material$Menu$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Select$menuElt = F5(
	function (leadingIcon, selected, onChange, firstSelectItem, remainingSelectItems) {
		return A3(
			$aforemny$material_components_web_elm$Material$Menu$menu,
			A2(
				$aforemny$material_components_web_elm$Material$Menu$setAttributes,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-select__menu'),
						A2($elm$html$Html$Attributes$style, 'width', '100%')
					]),
				$aforemny$material_components_web_elm$Material$Menu$config),
			A4($aforemny$material_components_web_elm$Material$Select$listItem, leadingIcon, selected, onChange, firstSelectItem),
			A2(
				$elm$core$List$map,
				A3($aforemny$material_components_web_elm$Material$Select$listItem, leadingIcon, selected, onChange),
				remainingSelectItems));
	});
var $aforemny$material_components_web_elm$Material$Select$noLabelCs = function (_v0) {
	var label = _v0.g_;
	return _Utils_eq(label, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-select--no-label')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Select$notchedOutlineElt = function (config_) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-notched-outline__leading')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-notched-outline__notch')
					]),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Select$floatingLabelElt(config_)
						]))),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-notched-outline__trailing')
					]),
				_List_Nil)
			]));
};
var $aforemny$material_components_web_elm$Material$Select$outlinedCs = function (variant) {
	return (variant === 1) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-select--outlined')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Select$requiredProp = function (_v0) {
	var required = _v0.di;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'required',
			$elm$json$Json$Encode$bool(required)));
};
var $aforemny$material_components_web_elm$Material$Select$rippleElt = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-select__ripple')
			]),
		_List_Nil));
var $aforemny$material_components_web_elm$Material$Select$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-select'));
var $aforemny$material_components_web_elm$Material$Select$selectedIndexProp = function (selectedIndex) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'selectedIndex',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, selectedIndex))));
};
var $aforemny$material_components_web_elm$Material$Select$selectedTextElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-select__selected-text')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Select$selectedTextContainerElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-select__selected-text-container')
		]),
	_List_fromArray(
		[$aforemny$material_components_web_elm$Material$Select$selectedTextElt]));
var $aforemny$material_components_web_elm$Material$Select$validProp = function (_v0) {
	var valid = _v0.dH;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'valid',
			$elm$json$Json$Encode$bool(valid)));
};
var $aforemny$material_components_web_elm$Material$Select$select = F4(
	function (variant, config_, firstSelectItem, remainingSelectItems) {
		var _v0 = config_;
		var leadingIcon = _v0.eL;
		var selected = _v0.fu;
		var additionalAttributes = _v0.ce;
		var onChange = _v0.c1;
		var selectedIndex = $elm$core$List$head(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (index, _v1) {
							var value = _v1.a.h7;
							return _Utils_eq(
								$elm$core$Maybe$Just(value),
								selected) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
						}),
					A2($elm$core$List$cons, firstSelectItem, remainingSelectItems))));
		return A3(
			$elm$html$Html$node,
			'mdc-select',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Select$rootCs,
							$aforemny$material_components_web_elm$Material$Select$noLabelCs(config_),
							$aforemny$material_components_web_elm$Material$Select$filledCs(variant),
							$aforemny$material_components_web_elm$Material$Select$outlinedCs(variant),
							$aforemny$material_components_web_elm$Material$Select$leadingIconCs(config_),
							$aforemny$material_components_web_elm$Material$Select$disabledProp(config_),
							$aforemny$material_components_web_elm$Material$Select$selectedIndexProp(selectedIndex),
							$aforemny$material_components_web_elm$Material$Select$validProp(config_),
							$aforemny$material_components_web_elm$Material$Select$requiredProp(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$Select$anchorElt,
					_List_Nil,
					$elm$core$List$concat(
						_List_fromArray(
							[
								(!variant) ? A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$Select$rippleElt,
										$aforemny$material_components_web_elm$Material$Select$floatingLabelElt(config_)
									])) : _List_fromArray(
								[
									$aforemny$material_components_web_elm$Material$Select$notchedOutlineElt(config_)
								]),
								_List_fromArray(
								[
									$aforemny$material_components_web_elm$Material$Select$leadingIconElt(config_),
									$aforemny$material_components_web_elm$Material$Select$selectedTextContainerElt,
									$aforemny$material_components_web_elm$Material$Select$dropdownIconElt
								]),
								(!variant) ? _List_fromArray(
								[$aforemny$material_components_web_elm$Material$Select$lineRippleElt]) : _List_Nil
							]))),
					A5($aforemny$material_components_web_elm$Material$Select$menuElt, leadingIcon, selected, onChange, firstSelectItem, remainingSelectItems)
				]));
	});
var $aforemny$material_components_web_elm$Material$Select$outlined = F3(
	function (config_, firstSelectItem, remainingSelectItems) {
		return A4($aforemny$material_components_web_elm$Material$Select$select, 1, config_, firstSelectItem, remainingSelectItems);
	});
var $aforemny$material_components_web_elm$Material$Select$Item$Internal$SelectItem = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$Select$Item$selectItem = $aforemny$material_components_web_elm$Material$Select$Item$Internal$SelectItem;
var $aforemny$material_components_web_elm$Material$Select$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Select$setLabel = F2(
	function (label, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{g_: label});
	});
var $aforemny$material_components_web_elm$Material$Select$setOnChange = F2(
	function (onChange, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c1: $elm$core$Maybe$Just(onChange)
			});
	});
var $aforemny$material_components_web_elm$Material$Select$setSelected = F2(
	function (selected, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{fu: selected});
	});
var $author$project$Models$Social$visibilityToString = function (v) {
	switch (v) {
		case 0:
			return 'Project';
		case 1:
			return 'Objective';
		default:
			return 'Task';
	}
};
var $author$project$App$Watcher$View$viewVisibilitySelector = function (_v0) {
	var payload = _v0.e2;
	var makeItem = function (v) {
		return A2(
			$aforemny$material_components_web_elm$Material$Select$Item$selectItem,
			$aforemny$material_components_web_elm$Material$Select$Item$config(
				{h7: v}),
			$author$project$Models$Social$visibilityToString(v));
	};
	var config = A2(
		$aforemny$material_components_web_elm$Material$Select$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$Select$setOnChange,
			$author$project$App$Watcher$Messages$SelectedVisibility,
			A2(
				$aforemny$material_components_web_elm$Material$Select$setSelected,
				$elm$core$Maybe$Just(payload.dJ),
				A2(
					$aforemny$material_components_web_elm$Material$Select$setLabel,
					$elm$core$Maybe$Just('Visibility'),
					$aforemny$material_components_web_elm$Material$Select$config))));
	return A3(
		$aforemny$material_components_web_elm$Material$Select$outlined,
		config,
		makeItem(0),
		A2(
			$elm$core$List$map,
			makeItem,
			_List_fromArray(
				[1])));
};
var $author$project$App$Watcher$View$viewActionRow = function (model) {
	var projectId = model.de;
	var payload = model.e2;
	var supporterUserIdSet = model.fG;
	var cmdReq = function () {
		var _v0 = model.fA;
		if (!_v0) {
			return A2($author$project$App$Watcher$Messages$ToPostSupporterWatcher, projectId, payload);
		} else {
			return A2(
				$author$project$App$Watcher$Messages$ToPostWatcher,
				projectId,
				A2(
					$elm$core$List$map,
					$author$project$App$Watcher$View$makeWatcherPayload(payload.dJ),
					$elm$core$Set$toList(supporterUserIdSet)));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'align-content', 'space-between'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '8px')
			]),
		_List_fromArray(
			[
				$author$project$App$Watcher$View$viewVisibilitySelector(model),
				A3(
				$author$project$Components$makeTextButton,
				$author$project$App$Watcher$Messages$CmdReq(cmdReq),
				'Invite',
				$elm$core$Maybe$Nothing)
			]));
};
var $author$project$Models$Watcher$Email = 1;
var $author$project$Models$Watcher$FirstName = 0;
var $author$project$App$Watcher$Messages$UpdateField = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$TextField$setLabel = F2(
	function (label, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{g_: label});
	});
var $author$project$App$Watcher$View$viewForm = function (model) {
	var fieldValue = F2(
		function (f, p) {
			if (!f) {
				return p.fT.er;
			} else {
				return p.fT.gB;
			}
		});
	var fieldLabel = function (f) {
		if (!f) {
			return 'First name';
		} else {
			return 'Email';
		}
	};
	var config = F2(
		function (f, p) {
			return A2(
				$aforemny$material_components_web_elm$Material$TextField$setAttributes,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'margin-right', '1px'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '8px'),
						A2($elm$html$Html$Attributes$style, 'width', '100%')
					]),
				A2(
					$aforemny$material_components_web_elm$Material$TextField$setOnInput,
					$author$project$App$Watcher$Messages$UpdateField(f),
					A2(
						$aforemny$material_components_web_elm$Material$TextField$setValue,
						$elm$core$Maybe$Just(
							A2(fieldValue, f, p)),
						A2(
							$aforemny$material_components_web_elm$Material$TextField$setLabel,
							$elm$core$Maybe$Just(
								fieldLabel(f)),
							$aforemny$material_components_web_elm$Material$TextField$config))));
		});
	var makeField = F2(
		function (f, p) {
			return $aforemny$material_components_web_elm$Material$TextField$outlined(
				A2(config, f, p));
		});
	var _v0 = model.fA;
	if (!_v0) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(makeField, 0, model.e2),
					A2(makeField, 1, model.e2)
				]));
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$App$Watcher$Messages$ToggleSupporterChoice = function (a) {
	return {$: 10, a: a};
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $aforemny$material_components_web_elm$Material$Checkbox$backgroundElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-checkbox__background')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('mdc-checkbox__checkmark'),
					$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('mdc-checkbox__checkmark-path'),
							$elm$svg$Svg$Attributes$fill('none'),
							$elm$svg$Svg$Attributes$d('M1.73,12.91 8.1,19.28 22.79,4.59')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-checkbox__mixedmark')
				]),
			_List_Nil)
		]));
var $aforemny$material_components_web_elm$Material$Checkbox$Internal$Checked = 1;
var $aforemny$material_components_web_elm$Material$Checkbox$checkedProp = function (_v0) {
	var state = _v0.b7;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'checked',
			$elm$json$Json$Encode$bool(
				_Utils_eq(
					state,
					$elm$core$Maybe$Just(1)))));
};
var $aforemny$material_components_web_elm$Material$Checkbox$disabledProp = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $aforemny$material_components_web_elm$Material$Checkbox$Internal$Indeterminate = 2;
var $aforemny$material_components_web_elm$Material$Checkbox$indeterminateProp = function (_v0) {
	var state = _v0.b7;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'indeterminate',
			$elm$json$Json$Encode$bool(
				_Utils_eq(
					state,
					$elm$core$Maybe$Just(2)))));
};
var $aforemny$material_components_web_elm$Material$Checkbox$changeHandler = function (_v0) {
	var onChange = _v0.c1;
	return A2(
		$elm$core$Maybe$map,
		function (msg) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				$elm$json$Json$Decode$succeed(msg));
		},
		onChange);
};
var $aforemny$material_components_web_elm$Material$Checkbox$nativeControlElt = function (config_) {
	return A2(
		$elm$html$Html$input,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$elm$html$Html$Attributes$type_('checkbox')),
					$elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-checkbox__native-control')),
					$aforemny$material_components_web_elm$Material$Checkbox$checkedProp(config_),
					$aforemny$material_components_web_elm$Material$Checkbox$indeterminateProp(config_),
					$aforemny$material_components_web_elm$Material$Checkbox$changeHandler(config_)
				])),
		_List_Nil);
};
var $aforemny$material_components_web_elm$Material$Checkbox$rippleElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-checkbox__ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Checkbox$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-checkbox'));
var $aforemny$material_components_web_elm$Material$Checkbox$touchCs = function (_v0) {
	var touch = _v0.bp;
	return touch ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-checkbox--touch')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Checkbox$checkbox = function (config_) {
	var touch = config_.bp;
	var additionalAttributes = config_.ce;
	var wrapTouch = function (node) {
		return touch ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-touch-target-wrapper')
				]),
			_List_fromArray(
				[node])) : node;
	};
	return wrapTouch(
		A3(
			$elm$html$Html$node,
			'mdc-checkbox',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Checkbox$rootCs,
							$aforemny$material_components_web_elm$Material$Checkbox$touchCs(config_),
							$aforemny$material_components_web_elm$Material$Checkbox$checkedProp(config_),
							$aforemny$material_components_web_elm$Material$Checkbox$indeterminateProp(config_),
							$aforemny$material_components_web_elm$Material$Checkbox$disabledProp(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Checkbox$nativeControlElt(config_),
					$aforemny$material_components_web_elm$Material$Checkbox$backgroundElt,
					$aforemny$material_components_web_elm$Material$Checkbox$rippleElt
				])));
};
var $aforemny$material_components_web_elm$Material$Checkbox$checked = 1;
var $aforemny$material_components_web_elm$Material$Checkbox$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Checkbox$config = {ce: _List_Nil, a6: false, c1: $elm$core$Maybe$Nothing, b7: $elm$core$Maybe$Nothing, bp: true};
var $aforemny$material_components_web_elm$Material$List$Item$meta = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-deprecated-list-item__meta'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$selected = 0;
var $aforemny$material_components_web_elm$Material$List$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Checkbox$setOnChange = F2(
	function (onChange, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c1: $elm$core$Maybe$Just(onChange)
			});
	});
var $aforemny$material_components_web_elm$Material$Checkbox$setState = F2(
	function (state, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{b7: state});
	});
var $aforemny$material_components_web_elm$Material$List$setTwoLine = F2(
	function (twoLine, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dF: twoLine});
	});
var $aforemny$material_components_web_elm$Material$List$Item$primaryTextElt = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-deprecated-list-item__primary-text'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$secondaryTextElt = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-deprecated-list-item__secondary-text'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$text = F2(
	function (additionalAttributes, _v0) {
		var primary = _v0.e6;
		var secondary = _v0.fs;
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-deprecated-list-item__text'),
				additionalAttributes),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$List$Item$primaryTextElt, _List_Nil, primary),
					A2($aforemny$material_components_web_elm$Material$List$Item$secondaryTextElt, _List_Nil, secondary)
				]));
	});
var $aforemny$material_components_web_elm$Material$Checkbox$Internal$Unchecked = 0;
var $aforemny$material_components_web_elm$Material$Checkbox$unchecked = 0;
var $author$project$App$Watcher$View$viewSupporterList = function (model) {
	var source = model.fA;
	var supporters = model.dA;
	var watchers = model.dK;
	var supporterUserIdSet = model.fG;
	var listConfig = A2(
		$aforemny$material_components_web_elm$Material$List$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', '0px'),
				A2($elm$html$Html$Attributes$style, 'padding', '0px')
			]),
		A2($aforemny$material_components_web_elm$Material$List$setTwoLine, true, $aforemny$material_components_web_elm$Material$List$config));
	var makeList = $aforemny$material_components_web_elm$Material$List$list(listConfig);
	var itemConfig = function (_v2) {
		var id = _v2.eC;
		var selected = A2($elm$core$Set$member, id, supporterUserIdSet) ? $elm$core$Maybe$Just($aforemny$material_components_web_elm$Material$List$Item$selected) : $elm$core$Maybe$Nothing;
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin', '0px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px')
				]),
			A2($aforemny$material_components_web_elm$Material$List$Item$setSelected, selected, $aforemny$material_components_web_elm$Material$List$Item$config));
	};
	var checkboxConfig = function (_v1) {
		var id = _v1.eC;
		var state = A2($elm$core$Set$member, id, supporterUserIdSet) ? $aforemny$material_components_web_elm$Material$Checkbox$checked : $aforemny$material_components_web_elm$Material$Checkbox$unchecked;
		return A2(
			$aforemny$material_components_web_elm$Material$Checkbox$setState,
			$elm$core$Maybe$Just(state),
			A2(
				$aforemny$material_components_web_elm$Material$Checkbox$setOnChange,
				$author$project$App$Watcher$Messages$ToggleSupporterChoice(id),
				$aforemny$material_components_web_elm$Material$Checkbox$config));
	};
	var makeItem = function (user) {
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$listItem,
			itemConfig(user),
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$List$Item$text,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$Typography$body1]),
					{
						e6: _List_fromArray(
							[
								$elm$html$Html$text(user.er)
							]),
						fs: _List_fromArray(
							[
								$elm$html$Html$text(user.gB)
							])
					}),
					A2(
					$aforemny$material_components_web_elm$Material$List$Item$meta,
					_List_Nil,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Checkbox$checkbox(
							checkboxConfig(user))
						]))
				]));
	};
	var _v0 = $author$project$App$Watcher$Model$getNonWatcherSupporters(model);
	if (!_v0.b) {
		return $author$project$Components$default;
	} else {
		if (!_v0.b.b) {
			var h = _v0.a;
			return A2(
				makeList,
				makeItem(h),
				_List_Nil);
		} else {
			var h = _v0.a;
			var t = _v0.b;
			return A2(
				makeList,
				makeItem(h),
				A2($elm$core$List$map, makeItem, t));
		}
	}
};
var $author$project$App$Watcher$View$viewForSource = function (model) {
	var content = function () {
		var _v0 = model.fA;
		if (!_v0) {
			return $author$project$App$Watcher$View$viewForm(model);
		} else {
			return $author$project$App$Watcher$View$viewSupporterList(model);
		}
	}();
	var cardConfig = A2(
		$aforemny$material_components_web_elm$Material$Card$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '0px 0px 0px 0px'),
				A2($elm$html$Html$Attributes$style, 'margin-bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'border', 'none')
			]),
		A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Card$card,
		cardConfig,
		{
			f4: $elm$core$Maybe$Nothing,
			gf: _Utils_Tuple2(
				$aforemny$material_components_web_elm$Material$Card$block(content),
				_List_Nil)
		});
};
var $author$project$App$Watcher$Messages$ChooseTab = function (a) {
	return {$: 9, a: a};
};
var $aforemny$material_components_web_elm$Material$Tab$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Tab$config = {
	dS: false,
	ce: _List_Nil,
	gn: {gQ: $elm$core$Maybe$Nothing, g_: ''},
	c2: $elm$core$Maybe$Nothing
};
var $aforemny$material_components_web_elm$Material$TabBar$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TabBar$config = {ce: _List_Nil, cf: $elm$core$Maybe$Nothing, bJ: false, cU: false, du: false};
var $aforemny$material_components_web_elm$Material$Tab$setActive = F2(
	function (active, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dS: active});
	});
var $aforemny$material_components_web_elm$Material$Tab$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TabBar$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Tab$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Tab$Internal$Tab = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Tab$tab = F2(
	function (_v0, content) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{gn: content});
	});
var $aforemny$material_components_web_elm$Material$TabBar$activatedHandler = function (tabs) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Events$on,
			'MDCTabBar:activated',
			A2(
				$elm$json$Json$Decode$andThen,
				function (activatedIndex) {
					var _v0 = A2(
						$elm$core$Maybe$andThen,
						function (_v1) {
							var onClick = _v1.c2;
							return onClick;
						},
						$elm$core$List$head(
							A2($elm$core$List$drop, activatedIndex, tabs)));
					if (!_v0.$) {
						var msg = _v0.a;
						return $elm$json$Json$Decode$succeed(msg);
					} else {
						return $elm$json$Json$Decode$fail('');
					}
				},
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['detail', 'index']),
					$elm$json$Json$Decode$int))));
};
var $aforemny$material_components_web_elm$Material$TabBar$activeTabIndexProp = function (tabs) {
	var activeTabIndex = A2(
		$elm$core$Maybe$map,
		$elm$core$Tuple$first,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var active = _v0.b.dS;
					return active;
				},
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, tabs))));
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$property('activeTabIndex'),
			$elm$json$Json$Encode$int),
		activeTabIndex);
};
var $aforemny$material_components_web_elm$Material$TabBar$anyActive = function (tabs) {
	if (!tabs.b) {
		return false;
	} else {
		var active = tabs.a.dS;
		var remainingTabs = tabs.b;
		return active || $aforemny$material_components_web_elm$Material$TabBar$anyActive(remainingTabs);
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$setActive = F2(
	function (active, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{dS: active});
	});
var $aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper = function (tabs) {
	if (!tabs.b) {
		return _List_Nil;
	} else {
		var tab = tabs.a;
		var active = tab.dS;
		var remainingTabs = tabs.b;
		return (!active) ? A2(
			$elm$core$List$cons,
			tab,
			$aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper(remainingTabs)) : A2(
			$elm$core$List$cons,
			tab,
			A2(
				$elm$core$List$map,
				$aforemny$material_components_web_elm$Material$TabBar$setActive(false),
				remainingTabs));
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$enforceActive = F2(
	function (firstTab, otherTabs) {
		var config_ = firstTab;
		return (!$aforemny$material_components_web_elm$Material$TabBar$anyActive(
			A2($elm$core$List$cons, firstTab, otherTabs))) ? A2(
			$elm$core$List$cons,
			A2($aforemny$material_components_web_elm$Material$TabBar$setActive, true, firstTab),
			otherTabs) : $aforemny$material_components_web_elm$Material$TabBar$enforceActiveHelper(
			A2($elm$core$List$cons, firstTab, otherTabs));
	});
var $aforemny$material_components_web_elm$Material$TabBar$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab-bar'));
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerAlignCs = function (align) {
	if (!align.$) {
		switch (align.a) {
			case 0:
				var _v1 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-start'));
			case 1:
				var _v2 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-end'));
			default:
				var _v3 = align.a;
				return $elm$core$Maybe$Just(
					$elm$html$Html$Attributes$class('mdc-tab-scroller--align-center'));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab-scroller'));
var $aforemny$material_components_web_elm$Material$TabBar$tabIconElt = function (_v0) {
	var icon = _v0.gQ;
	return A2(
		$elm$core$Maybe$map,
		$elm$html$Html$map($elm$core$Basics$never),
		function () {
			if (!icon.$) {
				if (!icon.a.$) {
					var node = icon.a.a.bS;
					var attributes = icon.a.a.bw;
					var nodes = icon.a.a.bT;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$class('mdc-tab__icon'),
								attributes),
							nodes));
				} else {
					var node = icon.a.a.bS;
					var attributes = icon.a.a.bw;
					var nodes = icon.a.a.bT;
					return $elm$core$Maybe$Just(
						A2(
							node,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('mdc-tab__icon'),
								attributes),
							nodes));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}());
};
var $aforemny$material_components_web_elm$Material$TabBar$tabIndicatorContentElt = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-tab-indicator__content'),
			$elm$html$Html$Attributes$class('mdc-tab-indicator__content--underline')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt = function (config_) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-indicator')
				]),
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorContentElt])));
};
var $aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt = function (_v0) {
	var label = _v0.g_;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab__text-label')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				])));
};
var $aforemny$material_components_web_elm$Material$TabBar$tabContentElt = F3(
	function (barConfig, config_, content) {
		var indicatorSpansContent = barConfig.bJ;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-tab__content')
					]),
				indicatorSpansContent ? A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabIconElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt(config_)
						])) : A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabIconElt(content),
							$aforemny$material_components_web_elm$Material$TabBar$tabTextLabelElt(content)
						]))));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-tab'));
var $aforemny$material_components_web_elm$Material$TabBar$tabMinWidthCs = function (_v0) {
	var minWidth = _v0.cU;
	return minWidth ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-tab--min-width')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TabBar$tabRippleElt = $elm$core$Maybe$Just(
	A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-tab__ripple')
			]),
		_List_Nil));
var $aforemny$material_components_web_elm$Material$TabBar$tabRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'tab'));
var $aforemny$material_components_web_elm$Material$TabBar$tabStackedCs = function (_v0) {
	var stacked = _v0.du;
	return stacked ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-tab--stacked')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TabBar$viewTab = F3(
	function (index, barConfig, tab) {
		var indicatorSpansContent = barConfig.bJ;
		var tabConfig = tab;
		var additionalAttributes = tabConfig.ce;
		var content = tabConfig.gn;
		return A3(
			$elm$html$Html$node,
			'mdc-tab',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$tabCs,
							$aforemny$material_components_web_elm$Material$TabBar$tabRoleAttr,
							$aforemny$material_components_web_elm$Material$TabBar$tabStackedCs(barConfig),
							$aforemny$material_components_web_elm$Material$TabBar$tabMinWidthCs(barConfig)
						])),
				additionalAttributes),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				indicatorSpansContent ? _List_fromArray(
					[
						A3($aforemny$material_components_web_elm$Material$TabBar$tabContentElt, barConfig, tabConfig, content),
						$aforemny$material_components_web_elm$Material$TabBar$tabRippleElt
					]) : _List_fromArray(
					[
						A3($aforemny$material_components_web_elm$Material$TabBar$tabContentElt, barConfig, tabConfig, content),
						$aforemny$material_components_web_elm$Material$TabBar$tabIndicatorElt(tabConfig),
						$aforemny$material_components_web_elm$Material$TabBar$tabRippleElt
					])));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollContentElt = F2(
	function (barConfig, tabs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-scroller__scroll-content')
				]),
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return A2($aforemny$material_components_web_elm$Material$TabBar$viewTab, index, barConfig);
				},
				tabs));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollAreaElt = F2(
	function (barConfig, tabs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-tab-scroller__scroll-area')
				]),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollContentElt, barConfig, tabs)
				]));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tabScroller = F3(
	function (config_, align, tabs) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$TabBar$tabScrollerCs,
						$aforemny$material_components_web_elm$Material$TabBar$tabScrollerAlignCs(align)
					])),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$TabBar$tabScrollerScrollAreaElt, config_, tabs)
				]));
	});
var $aforemny$material_components_web_elm$Material$TabBar$tablistRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'tablist'));
var $aforemny$material_components_web_elm$Material$TabBar$tabBar = F3(
	function (config_, tab_, tabs_) {
		var additionalAttributes = config_.ce;
		var align = config_.cf;
		var tabs = A2($aforemny$material_components_web_elm$Material$TabBar$enforceActive, tab_, tabs_);
		return A3(
			$elm$html$Html$node,
			'mdc-tab-bar',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TabBar$rootCs,
							$aforemny$material_components_web_elm$Material$TabBar$tablistRoleAttr,
							$aforemny$material_components_web_elm$Material$TabBar$activeTabIndexProp(tabs),
							$aforemny$material_components_web_elm$Material$TabBar$activatedHandler(tabs)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					A3($aforemny$material_components_web_elm$Material$TabBar$tabScroller, config_, align, tabs)
				]));
	});
var $author$project$App$Watcher$View$viewTab = function (model) {
	var tabConfig = function (src) {
		return A2(
			$aforemny$material_components_web_elm$Material$Tab$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '10px'),
					A2($elm$html$Html$Attributes$style, 'text-transform', 'capitalize')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$Tab$setOnClick,
				$author$project$App$Watcher$Messages$ChooseTab(src),
				A2(
					$aforemny$material_components_web_elm$Material$Tab$setActive,
					_Utils_eq(model.fA, src),
					$aforemny$material_components_web_elm$Material$Tab$config)));
	};
	var makeTab = function (src) {
		return A2(
			$aforemny$material_components_web_elm$Material$Tab$tab,
			tabConfig(src),
			{
				gQ: $elm$core$Maybe$Nothing,
				g_: function () {
					if (src === 1) {
						return 'Contacts';
					} else {
						return 'New';
					}
				}()
			});
	};
	var config = A2(
		$aforemny$material_components_web_elm$Material$TabBar$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '15px')
			]),
		$aforemny$material_components_web_elm$Material$TabBar$config);
	var choices = function () {
		var nonWSs = $author$project$App$Watcher$Model$getNonWatcherSupporters(model);
		return ($elm$core$List$length(nonWSs) > 0) ? _List_fromArray(
			[1, 0]) : _List_Nil;
	}();
	if (choices.b) {
		if (!choices.b.b) {
			var h = choices.a;
			return A3(
				$aforemny$material_components_web_elm$Material$TabBar$tabBar,
				config,
				makeTab(h),
				_List_Nil);
		} else {
			var h = choices.a;
			var t = choices.b;
			return A3(
				$aforemny$material_components_web_elm$Material$TabBar$tabBar,
				config,
				makeTab(h),
				A2($elm$core$List$map, makeTab, t));
		}
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$App$Watcher$Messages$ToDeleteWatcher = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $aforemny$material_components_web_elm$Material$Typography$subtitle2 = $elm$html$Html$Attributes$class('mdc-typography--subtitle2');
var $author$project$App$Watcher$View$viewWatchers = function (model) {
	var projectId = model.de;
	var payload = model.e2;
	var watcherStatus = function (_v4) {
		var userAgreed = _v4.h6;
		if (userAgreed.$ === 1) {
			return 'Pending';
		} else {
			if (userAgreed.a) {
				return 'Accepted';
			} else {
				return 'Declined';
			}
		}
	};
	var sortFnc = function (_v2) {
		var watcher = _v2.ia;
		return watcher.eC;
	};
	var listConfig = A2(
		$aforemny$material_components_web_elm$Material$List$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', '0px'),
				A2($elm$html$Html$Attributes$style, 'padding', '0px')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$List$setRipples,
			false,
			A2($aforemny$material_components_web_elm$Material$List$setTwoLine, true, $aforemny$material_components_web_elm$Material$List$config)));
	var makeList = $aforemny$material_components_web_elm$Material$List$list(listConfig);
	var itemConfig = A2(
		$aforemny$material_components_web_elm$Material$List$Item$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', '0px'),
				A2($elm$html$Html$Attributes$style, 'padding', '0px')
			]),
		$aforemny$material_components_web_elm$Material$List$Item$config);
	var header = A2(
		$elm$html$Html$h4,
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Typography$body1,
				A2($elm$html$Html$Attributes$style, 'font-weight', '500'),
				A2($elm$html$Html$Attributes$style, 'margin-bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'paddinn-bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'border-bottom-style', 'dotted'),
				A2($elm$html$Html$Attributes$style, 'border-bottom-width', '0.1px'),
				A2($elm$html$Html$Attributes$style, 'border-bottom-color', 'grey')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Currently shared with')
			]));
	var deleteMsg = function (uid) {
		return $author$project$App$Watcher$Messages$CmdReq(
			A2(
				$author$project$App$Watcher$Messages$ToDeleteWatcher,
				projectId,
				A2($author$project$App$Watcher$View$makeWatcherPayload, payload.dJ, uid)));
	};
	var makeItem = function (_v1) {
		var watcher = _v1.ia;
		var user = _v1.fT;
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$listItem,
			itemConfig,
			_List_fromArray(
				[
					A2(
					$aforemny$material_components_web_elm$Material$List$Item$text,
					_List_Nil,
					{
						e6: _List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[$aforemny$material_components_web_elm$Material$Typography$body1]),
								_List_fromArray(
									[
										$elm$html$Html$text(user.er),
										$elm$html$Html$text(' | '),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'color', 'grey'),
												$aforemny$material_components_web_elm$Material$Typography$subtitle2
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												watcherStatus(watcher))
											])),
										$elm$html$Html$text(' | '),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'color', 'grey'),
												$aforemny$material_components_web_elm$Material$Typography$subtitle2,
												A2($elm$html$Html$Attributes$style, 'font-width', '100')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Models$Social$visibilityToString(watcher.dJ) + ' Level')
											]))
									]))
							]),
						fs: _List_fromArray(
							[
								$elm$html$Html$text(user.gB)
							])
					}),
					A2(
					$aforemny$material_components_web_elm$Material$List$Item$meta,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Components$makeIconButton,
							_List_Nil,
							deleteMsg(user.eC),
							'delete_outline')
						]))
				]));
	};
	var attrs = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'padding-top', '20px')
		]);
	var _v0 = A2($elm$core$List$sortBy, sortFnc, model.dK);
	if (!_v0.b) {
		return $author$project$Components$default;
	} else {
		if (!_v0.b.b) {
			var h = _v0.a;
			return A2(
				$elm$html$Html$div,
				attrs,
				_List_fromArray(
					[
						header,
						A2(
						makeList,
						makeItem(h),
						_List_Nil)
					]));
		} else {
			var h = _v0.a;
			var t = _v0.b;
			return A2(
				$elm$html$Html$div,
				attrs,
				_List_fromArray(
					[
						header,
						A2(
						makeList,
						makeItem(h),
						A2($elm$core$List$map, makeItem, t))
					]));
		}
	}
};
var $author$project$App$Watcher$View$viewDialog = function (model) {
	var config = A2(
		$aforemny$material_components_web_elm$Material$Dialog$setOnClose,
		$author$project$App$Watcher$Messages$Close,
		A2($aforemny$material_components_web_elm$Material$Dialog$setOpen, true, $aforemny$material_components_web_elm$Material$Dialog$config));
	return A2(
		$aforemny$material_components_web_elm$Material$Dialog$confirmation,
		config,
		{
			f4: _List_fromArray(
				[
					A3($author$project$Components$makeTextButton, $author$project$App$Watcher$Messages$Close, 'Close', $elm$core$Maybe$Nothing)
				]),
			gn: _List_fromArray(
				[
					$author$project$App$Watcher$View$viewTab(model),
					$author$project$App$Watcher$View$viewForSource(model),
					$author$project$App$Watcher$View$viewActionRow(model),
					$author$project$App$Watcher$View$viewWatchers(model),
					A2($author$project$Components$viewSnackbar, $author$project$App$Watcher$Messages$CloseSnackbar, model)
				]),
			hS: 'Share your progress'
		});
};
var $author$project$App$Watcher$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$dir('ltr')
			]),
		_List_fromArray(
			[
				$author$project$App$Watcher$View$viewDialog(model)
			]));
};
var $author$project$App$Project$Messages$AddObjective = {$: 6};
var $author$project$App$Project$View$viewAddObjective = function (model) {
	var makeButton = function (_v0) {
		return A3($author$project$Components$makeTextButton, $author$project$App$Project$Messages$AddObjective, 'Add objective', $elm$core$Maybe$Nothing);
	};
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Components$default,
		A2(
			$elm$core$Maybe$map,
			makeButton,
			$author$project$App$Project$Model$getProjectId(model)));
};
var $author$project$App$Project$Messages$Add = {$: 0};
var $author$project$App$Project$View$viewAddProject = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A3(
			$author$project$Components$makeTextButton,
			$author$project$App$Project$Messages$ProjectMsg($author$project$App$Project$Messages$Add),
			'Add project',
			$elm$core$Maybe$Nothing)
		]));
var $author$project$App$Project$Messages$FlipDoing = {$: 1};
var $aforemny$material_components_web_elm$Material$Fab$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Fab$config = {ce: _List_Nil, cy: false, cV: false, c2: $elm$core$Maybe$Nothing, bp: true};
var $aforemny$material_components_web_elm$Material$Fab$clickHandler = function (_v0) {
	var onClick = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$Fab$exitedCs = function (_v0) {
	var exited = _v0.cy;
	return exited ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-fab--exited')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Fab$iconElt = function (icon_) {
	if (!icon_.$) {
		var node = icon_.a.bS;
		var attributes = icon_.a.bw;
		var nodes = icon_.a.bT;
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$never,
			A2(
				node,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('mdc-fab__icon'),
					attributes),
				nodes));
	} else {
		var node = icon_.a.bS;
		var attributes = icon_.a.bw;
		var nodes = icon_.a.bT;
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$never,
			A2(
				node,
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$class('mdc-fab__icon'),
					attributes),
				nodes));
	}
};
var $aforemny$material_components_web_elm$Material$Fab$miniCs = function (_v0) {
	var mini = _v0.cV;
	return mini ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-fab--mini')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Fab$rippleElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-fab__ripple')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Fab$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-fab'));
var $aforemny$material_components_web_elm$Material$Fab$tabIndexProp = function (tabIndex) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(tabIndex)));
};
var $aforemny$material_components_web_elm$Material$Fab$touchCs = function (_v0) {
	var touch = _v0.bp;
	return touch ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-fab--touch')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Fab$touchElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-fab__touch')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Fab$fab = F2(
	function (config_, icon_) {
		var additionalAttributes = config_.ce;
		var touch = config_.bp;
		var wrapTouch = function (node) {
			return touch ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-touch-target-wrapper')
					]),
				_List_fromArray(
					[node])) : node;
		};
		return wrapTouch(
			A3(
				$elm$html$Html$node,
				'mdc-fab',
				_Utils_ap(
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$aforemny$material_components_web_elm$Material$Fab$rootCs,
								$aforemny$material_components_web_elm$Material$Fab$miniCs(config_),
								$aforemny$material_components_web_elm$Material$Fab$touchCs(config_),
								$aforemny$material_components_web_elm$Material$Fab$exitedCs(config_),
								$aforemny$material_components_web_elm$Material$Fab$clickHandler(config_),
								$aforemny$material_components_web_elm$Material$Fab$tabIndexProp(0)
							])),
					additionalAttributes),
				_Utils_ap(
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Fab$rippleElt,
							$aforemny$material_components_web_elm$Material$Fab$iconElt(icon_)
						]),
					touch ? _List_fromArray(
						[$aforemny$material_components_web_elm$Material$Fab$touchElt]) : _List_Nil)));
	});
var $aforemny$material_components_web_elm$Material$Fab$Icon = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$Fab$customIcon = F3(
	function (node, attributes, nodes) {
		return $aforemny$material_components_web_elm$Material$Fab$Icon(
			{bw: attributes, bS: node, bT: nodes});
	});
var $aforemny$material_components_web_elm$Material$Fab$icon = function (iconName) {
	return A3(
		$aforemny$material_components_web_elm$Material$Fab$customIcon,
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(iconName)
			]));
};
var $aforemny$material_components_web_elm$Material$Theme$primaryBg = $elm$html$Html$Attributes$class('mdc-theme--primary-bg');
var $aforemny$material_components_web_elm$Material$Fab$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Fab$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$App$Doing$View$viewFab = function (_v0) {
	var doing = _v0.ed;
	var ongoingEfforts = _v0.e_;
	var watcher = _v0.ia;
	var cond = ($elm$core$Dict$size(ongoingEfforts) > 0) && _Utils_eq(watcher, $elm$core$Maybe$Nothing);
	var _v1 = _Utils_eq(doing, $elm$core$Maybe$Nothing) ? _Utils_Tuple2(
		'emoji_objects',
		A2($elm$html$Html$Attributes$style, 'background-color', 'green')) : _Utils_Tuple2('exit_to_app', $aforemny$material_components_web_elm$Material$Theme$primaryBg);
	var icon = _v1.a;
	var backgroundColorStyle = _v1.b;
	var config = A2(
		$aforemny$material_components_web_elm$Material$Fab$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'bottom', '1rem'),
				A2($elm$html$Html$Attributes$style, 'right', '1rem'),
				A2($elm$html$Html$Attributes$style, 'z-index', '20'),
				backgroundColorStyle
			]),
		A2(
			$aforemny$material_components_web_elm$Material$Fab$setOnClick,
			$author$project$App$Project$Messages$DoingMsg($author$project$App$Project$Messages$FlipDoing),
			$aforemny$material_components_web_elm$Material$Fab$config));
	return cond ? A2(
		$aforemny$material_components_web_elm$Material$Fab$fab,
		config,
		$aforemny$material_components_web_elm$Material$Fab$icon(icon)) : $author$project$Components$default;
};
var $author$project$App$Project$Messages$SetForm = {$: 8};
var $author$project$App$Form$Model$formIsProject = function (maybeModel) {
	var op = function (_v1) {
		var form = _v1.ba;
		if (!form.$) {
			return true;
		} else {
			return false;
		}
	};
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2($elm$core$Maybe$map, op, maybeModel));
};
var $author$project$App$Project$Messages$FormMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$App$Project$View$viewForm = F2(
	function (attrs, fModel) {
		var form = A2(
			$elm$html$Html$map,
			$author$project$App$Project$Messages$FormMsg,
			A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$default,
				A2($elm$core$Maybe$map, $author$project$App$Form$View$view, fModel)));
		return A2(
			$elm$html$Html$div,
			attrs,
			_List_fromArray(
				[form]));
	});
var $author$project$App$Project$Messages$InitWatcher = function (a) {
	return {$: 11, a: a};
};
var $aforemny$material_components_web_elm$Material$Theme$primary = $elm$html$Html$Attributes$class('mdc-theme--primary');
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$App$TaskedObjective$Model$taskCount = function (_v0) {
	var tasks = _v0.hL;
	return $elm$core$Dict$size(tasks);
};
var $author$project$App$Project$Model$projectObjectiveTaskCount = function (_v0) {
	var taskedObjectives = _v0.hK;
	return _Utils_Tuple2(
		$elm$core$Dict$size(taskedObjectives),
		$elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$author$project$App$TaskedObjective$Model$taskCount,
				$elm$core$Dict$values(taskedObjectives))));
};
var $author$project$App$Project$View$viewShareButton = function (model) {
	var makeForProject = function (_v2) {
		var id = _v2.eC;
		return A3(
			$author$project$Components$makeIconButton,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$Theme$primary]),
			$author$project$App$Project$Messages$InitWatcher(id),
			'group');
	};
	var _v0 = $author$project$App$Project$Model$projectObjectiveTaskCount(model);
	var oCount = _v0.a;
	var tCount = _v0.b;
	var _v1 = _Utils_Tuple3(model.fa, oCount > 0, tCount > 0);
	if ((((!_v1.a.$) && (!_v1.a.a.$)) && _v1.b) && _v1.c) {
		var proj = _v1.a.a.a;
		return makeForProject(proj);
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$App$Project$View$viewTitle = function (model) {
	var form = model.ba;
	var project = model.fa;
	var attrsBase = _List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$headline5,
			A2($elm$html$Html$Attributes$style, 'font-weight', '200'),
			A2($elm$html$Html$Attributes$style, 'width', '100%')
		]);
	var attrsAction = function () {
		if (!project.$) {
			var pP = project.a;
			return _List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$App$Project$Messages$SetForm)
				]);
		} else {
			return _List_Nil;
		}
	}();
	var attrs = A2($elm$core$List$append, attrsBase, attrsAction);
	var makeTitle = function (title) {
		return A2(
			$elm$html$Html$div,
			attrs,
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]));
	};
	var makeInvisibleTitle = function (title) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'left', '0'),
					A2($elm$html$Html$Attributes$style, 'visibility', 'hidden')
				]),
			_List_fromArray(
				[
					makeTitle(title)
				]));
	};
	var content = $author$project$App$Form$Model$formIsProject(form) ? A2(
		$elm$html$Html$div,
		attrsBase,
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$default,
				A2(
					$elm$core$Maybe$map,
					makeInvisibleTitle,
					$author$project$App$Project$Model$getProjectTitle(model))),
				A2($author$project$App$Project$View$viewForm, attrsBase, form)
			])) : A2(
		$elm$core$Maybe$withDefault,
		$author$project$Components$default,
		A2(
			$elm$core$Maybe$map,
			makeTitle,
			$author$project$App$Project$Model$getProjectTitle(model)));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'align-content', 'space-between'),
				A2($elm$html$Html$Attributes$style, 'margin-bottom', '15px'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'center')
			]),
		_List_fromArray(
			[
				content,
				$author$project$App$Project$View$viewShareButton(model)
			]));
};
var $author$project$App$Project$View$viewProject = function (model) {
	var doing = model.ed;
	var form = model.ba;
	var project = model.fa;
	var taskedObjectives = model.hK;
	var newObjective = model.eU;
	var watcherView = A2(
		$elm$html$Html$map,
		$author$project$App$Project$Messages$WatcherMsg,
		A2(
			$elm$core$Maybe$withDefault,
			$author$project$Components$default,
			A2($elm$core$Maybe$map, $author$project$App$Watcher$View$view, model.ia)));
	var viewTO = function (_v0) {
		var oId = _v0.a;
		var tOModel = _v0.b;
		return A2(
			$elm$html$Html$map,
			$author$project$App$Project$Messages$TaskedObjectiveMsg(
				$elm$core$Maybe$Just(oId)),
			$author$project$App$TaskedObjective$View$view(tOModel));
	};
	var shouldShowAddProject = _Utils_eq(
		A2($elm$core$Maybe$andThen, $author$project$Models$Project$potentialToMaybe, project),
		$elm$core$Maybe$Nothing) && (_Utils_eq(form, $elm$core$Maybe$Nothing) && (!$author$project$App$Project$Model$isInDoingMode(model)));
	var projectTitle = $author$project$App$Project$Model$getProjectTitle(model);
	var objectiveFormAttrs = _List_fromArray(
		[
			$aforemny$material_components_web_elm$Material$Typography$body1,
			A2($elm$html$Html$Attributes$style, 'font-weight', '500'),
			A2($elm$html$Html$Attributes$style, 'border-bottom-style', 'solid'),
			A2($elm$html$Html$Attributes$style, 'border-bottom-width', '0.1px'),
			A2($elm$html$Html$Attributes$style, 'border-bottom-color', 'grey'),
			A2($elm$html$Html$Attributes$style, 'min-height', '15px')
		]);
	var addObjective = function () {
		var viewFnc = function (tOModel) {
			return A2(
				$elm$html$Html$map,
				$author$project$App$Project$Messages$TaskedObjectiveMsg($elm$core$Maybe$Nothing),
				$author$project$App$TaskedObjective$View$view(tOModel));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$App$Project$View$viewAddObjective(model),
			A2($elm$core$Maybe$map, viewFnc, newObjective));
	}();
	return shouldShowAddProject ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'margin-left', '20px'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '20px')
			]),
		_List_fromArray(
			[$author$project$App$Project$View$viewAddProject])) : ((!$author$project$App$Project$Model$isInDoingMode(model)) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin-left', '10px'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '10px'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '20px')
			]),
		_List_fromArray(
			[
				$author$project$App$Project$View$viewTitle(model),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				A2(
					$elm$core$List$map,
					viewTO,
					$elm$core$Dict$toList(taskedObjectives))),
				addObjective,
				watcherView,
				$author$project$App$Doing$View$viewFab(model)
			])) : A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$App$Doing$View$view(model),
				$author$project$App$Doing$View$viewFab(model)
			])));
};
var $aforemny$material_components_web_elm$Material$Card$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$App$Project$Messages$MetricMsg = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Helpers$Attribute = $elm$core$Basics$identity;
var $terezka$elm_charts$Chart$Attributes$border = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{C: v});
	};
};
var $terezka$elm_charts$Internal$Coordinates$center = function (pos) {
	return {ig: pos.ih + ((pos.ii - pos.ih) / 2), f$: pos.ik + ((pos.il - pos.ik) / 2)};
};
var $terezka$elm_charts$Internal$Coordinates$pointToPosition = function (point) {
	return {ih: point.ig, ii: point.ig, ik: point.f$, il: point.f$};
};
var $terezka$elm_charts$Chart$Attributes$center = function (config) {
	return _Utils_update(
		config,
		{
			gG: $elm$core$Maybe$Just(
				A2($elm$core$Basics$composeR, $terezka$elm_charts$Internal$Coordinates$center, $terezka$elm_charts$Internal$Coordinates$pointToPosition))
		});
};
var $terezka$elm_charts$Internal$Svg$Event = F2(
	function (name, handler) {
		return {ev: handler, g7: name};
	});
var $terezka$elm_charts$Internal$Helpers$apply = F2(
	function (attrs, _default) {
		var apply_ = F2(
			function (_v0, a) {
				var f = _v0;
				return f(a);
			});
		return A3($elm$core$List$foldl, apply_, _default, attrs);
	});
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$json$Json$Decode$map3 = _Json_map3;
var $K_Adam$elm_dom$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $K_Adam$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$map4 = _Json_map4;
var $K_Adam$elm_dom$DOM$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $K_Adam$elm_dom$DOM$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $K_Adam$elm_dom$DOM$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $K_Adam$elm_dom$DOM$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $K_Adam$elm_dom$DOM$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $K_Adam$elm_dom$DOM$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$K_Adam$elm_dom$DOM$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($K_Adam$elm_dom$DOM$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeftP, scrollTopP, offsetLeftP, offsetTopP) {
						return _Utils_Tuple2((x + offsetLeftP) - scrollLeftP, (y + offsetTopP) - scrollTopP);
					}),
				$K_Adam$elm_dom$DOM$scrollLeft,
				$K_Adam$elm_dom$DOM$scrollTop,
				$K_Adam$elm_dom$DOM$offsetLeft,
				$K_Adam$elm_dom$DOM$offsetTop));
	});
var $K_Adam$elm_dom$DOM$boundingClientRect = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {ew: height, g0: x, h0: y, f_: width};
		}),
	A2($K_Adam$elm_dom$DOM$position, 0, 0),
	$K_Adam$elm_dom$DOM$offsetWidth,
	$K_Adam$elm_dom$DOM$offsetHeight);
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $K_Adam$elm_dom$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
function $terezka$elm_charts$Internal$Svg$cyclic$decodePosition() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$K_Adam$elm_dom$DOM$boundingClientRect,
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $K_Adam$elm_dom$DOM$parentElement(
						$terezka$elm_charts$Internal$Svg$cyclic$decodePosition());
				})
			]));
}
var $terezka$elm_charts$Internal$Svg$decodePosition = $terezka$elm_charts$Internal$Svg$cyclic$decodePosition();
$terezka$elm_charts$Internal$Svg$cyclic$decodePosition = function () {
	return $terezka$elm_charts$Internal$Svg$decodePosition;
};
var $terezka$elm_charts$Internal$Coordinates$innerLength = function (axis) {
	return A2($elm$core$Basics$max, 1, (axis.be - axis.g3) - axis.g2);
};
var $terezka$elm_charts$Internal$Coordinates$innerWidth = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.ig);
};
var $terezka$elm_charts$Internal$Coordinates$range = function (axis) {
	var diff = axis.au - axis.aw;
	return (diff > 0) ? diff : 1;
};
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.ig)) / $terezka$elm_charts$Internal$Coordinates$innerWidth(plane);
	});
var $terezka$elm_charts$Internal$Coordinates$toCartesianX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, value - plane.ig.g3) + plane.ig.aw;
	});
var $terezka$elm_charts$Internal$Coordinates$innerHeight = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.f$);
};
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.f$)) / $terezka$elm_charts$Internal$Coordinates$innerHeight(plane);
	});
var $terezka$elm_charts$Internal$Coordinates$toCartesianY = F2(
	function (plane, value) {
		return ($terezka$elm_charts$Internal$Coordinates$range(plane.f$) - A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, value - plane.f$.g3)) + plane.f$.aw;
	});
var $terezka$elm_charts$Internal$Svg$fromSvg = F2(
	function (plane, point) {
		return {
			ig: A2($terezka$elm_charts$Internal$Coordinates$toCartesianX, plane, point.ig),
			f$: A2($terezka$elm_charts$Internal$Coordinates$toCartesianY, plane, point.f$)
		};
	});
var $K_Adam$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $terezka$elm_charts$Internal$Svg$decoder = F2(
	function (plane, toMsg) {
		var handle = F3(
			function (mouseX, mouseY, box) {
				var yPrev = plane.f$;
				var xPrev = plane.ig;
				var widthPercent = box.f_ / plane.ig.be;
				var heightPercent = box.ew / plane.f$.be;
				var newPlane = _Utils_update(
					plane,
					{
						ig: _Utils_update(
							xPrev,
							{be: box.f_, g2: plane.ig.g2 * widthPercent, g3: plane.ig.g3 * widthPercent}),
						f$: _Utils_update(
							yPrev,
							{be: box.ew, g2: plane.f$.g2 * heightPercent, g3: plane.f$.g3 * heightPercent})
					});
				var searched = A2(
					$terezka$elm_charts$Internal$Svg$fromSvg,
					newPlane,
					{ig: mouseX - box.g0, f$: mouseY - box.h0});
				return A2(toMsg, newPlane, searched);
			});
		return A4(
			$elm$json$Json$Decode$map3,
			handle,
			A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float),
			$K_Adam$elm_dom$DOM$target($terezka$elm_charts$Internal$Svg$decodePosition));
	});
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Events$on = $elm$html$Html$Events$on;
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $terezka$elm_charts$Internal$Coordinates$toId = function (plane) {
	var numToStr = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromFloat,
		A2($elm$core$String$replace, '.', '-'));
	return A2(
		$elm$core$String$join,
		'_',
		_List_fromArray(
			[
				'elm-charts__id',
				numToStr(plane.ig.be),
				numToStr(plane.ig.aw),
				numToStr(plane.ig.au),
				numToStr(plane.ig.g3),
				numToStr(plane.ig.g2),
				numToStr(plane.f$.be),
				numToStr(plane.f$.aw),
				numToStr(plane.f$.au),
				numToStr(plane.f$.g3),
				numToStr(plane.f$.g2)
			]));
};
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $terezka$elm_charts$Internal$Svg$container = F5(
	function (plane, config, below, chartEls, above) {
		var toEvent = function (event) {
			return A2(
				$elm$svg$Svg$Events$on,
				event.g7,
				A2($terezka$elm_charts$Internal$Svg$decoder, plane, event.ev));
		};
		var svgAttrsSize = config.b2 ? _List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox(
				'0 0 ' + ($elm$core$String$fromFloat(plane.ig.be) + (' ' + $elm$core$String$fromFloat(plane.f$.be)))),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]) : _List_fromArray(
			[
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(plane.ig.be)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(plane.f$.be)),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]);
		var htmlAttrsSize = config.b2 ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%')
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'width',
				$elm$core$String$fromFloat(plane.ig.be) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'height',
				$elm$core$String$fromFloat(plane.f$.be) + 'px')
			]);
		var htmlAttrsDef = _List_fromArray(
			[
				$elm$html$Html$Attributes$class('elm-charts__container-inner')
			]);
		var htmlAttrs = _Utils_ap(
			config.bI,
			_Utils_ap(htmlAttrsDef, htmlAttrsSize));
		var chartPosition = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(plane.ig.g3)),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(plane.f$.g3)),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerWidth(plane))),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerHeight(plane))),
				$elm$svg$Svg$Attributes$fill('transparent')
			]);
		var clipPathDefs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id(
							$terezka$elm_charts$Internal$Coordinates$toId(plane))
						]),
					_List_fromArray(
						[
							A2($elm$svg$Svg$rect, chartPosition, _List_Nil)
						]))
				]));
		var catcher = A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				chartPosition,
				A2($elm$core$List$map, toEvent, config.bE)),
			_List_Nil);
		var chart = A2(
			$elm$svg$Svg$svg,
			_Utils_ap(svgAttrsSize, config.j),
			_Utils_ap(
				_List_fromArray(
					[clipPathDefs]),
				_Utils_ap(
					chartEls,
					_List_fromArray(
						[catcher]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('elm-charts__container'),
					A2($elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					htmlAttrs,
					_Utils_ap(
						below,
						_Utils_ap(
							_List_fromArray(
								[chart]),
							above)))
				]));
	});
var $terezka$elm_charts$Internal$Coordinates$Position = F4(
	function (x1, x2, y1, y2) {
		return {ih: x1, ii: x2, ik: y1, il: y2};
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $terezka$elm_charts$Internal$Coordinates$foldPosition = F2(
	function (func, data) {
		var fold = F2(
			function (datum, posM) {
				if (!posM.$) {
					var pos = posM.a;
					return $elm$core$Maybe$Just(
						{
							ih: A2(
								$elm$core$Basics$min,
								func(datum).ih,
								pos.ih),
							ii: A2(
								$elm$core$Basics$max,
								func(datum).ii,
								pos.ii),
							ik: A2(
								$elm$core$Basics$min,
								func(datum).ik,
								pos.ik),
							il: A2(
								$elm$core$Basics$max,
								func(datum).il,
								pos.il)
						});
				} else {
					return $elm$core$Maybe$Just(
						func(datum));
				}
			});
		return A2(
			$elm$core$Maybe$withDefault,
			A4($terezka$elm_charts$Internal$Coordinates$Position, 0, 0, 0, 0),
			A3($elm$core$List$foldl, fold, $elm$core$Maybe$Nothing, data));
	});
var $terezka$elm_charts$Internal$Item$getLimits = function (_v0) {
	var item = _v0.b;
	return item.z;
};
var $terezka$elm_charts$Chart$Attributes$lowest = F2(
	function (v, edit) {
		return function (b) {
			return _Utils_update(
				b,
				{
					aw: A3(edit, v, b.aw, b.gr)
				});
		};
	});
var $terezka$elm_charts$Chart$Attributes$orLower = F3(
	function (least, real, _v0) {
		return (_Utils_cmp(real, least) > 0) ? least : real;
	});
var $terezka$elm_charts$Chart$definePlane = F2(
	function (config, elements) {
		var width = A2($elm$core$Basics$max, 1, (config.f_ - config.W.g0) - config.W.hp);
		var toLimit = F5(
			function (length, marginMin, marginMax, min, max) {
				return {gq: max, gr: min, be: length, g2: marginMax, g3: marginMin, au: max, aw: min};
			});
		var height = A2($elm$core$Basics$max, 1, (config.ew - config.W.gg) - config.W.h0);
		var fixSingles = function (bs) {
			return _Utils_eq(bs.aw, bs.au) ? _Utils_update(
				bs,
				{au: bs.aw + 10}) : bs;
		};
		var collectLimits = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 2:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 3:
						var item = el.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[
									$terezka$elm_charts$Internal$Item$getLimits(item)
								]));
					case 4:
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, collectLimits, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		var limits_ = function (pos) {
			return function (_v3) {
				var x = _v3.ig;
				var y = _v3.f$;
				return {
					ig: fixSingles(x),
					f$: fixSingles(y)
				};
			}(
				{
					ig: A5(toLimit, width, config.at.g0, config.at.hp, pos.ih, pos.ii),
					f$: A5(toLimit, height, config.at.h0, config.at.gg, pos.ik, pos.il)
				});
		}(
			A2(
				$terezka$elm_charts$Internal$Coordinates$foldPosition,
				$elm$core$Basics$identity,
				A3($elm$core$List$foldl, collectLimits, _List_Nil, elements)));
		var calcRange = function () {
			var _v2 = config.dh;
			if (!_v2.b) {
				return limits_.ig;
			} else {
				var some = _v2;
				return A2($terezka$elm_charts$Internal$Helpers$apply, some, limits_.ig);
			}
		}();
		var calcDomain = function () {
			var _v1 = config.cv;
			if (!_v1.b) {
				return A2(
					$terezka$elm_charts$Internal$Helpers$apply,
					_List_fromArray(
						[
							A2($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$orLower)
						]),
					limits_.f$);
			} else {
				var some = _v1;
				return A2($terezka$elm_charts$Internal$Helpers$apply, some, limits_.f$);
			}
		}();
		var unpadded = {ig: calcRange, f$: calcDomain};
		var scalePadX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX(unpadded);
		var xMax = calcRange.au + scalePadX(config.W.hp);
		var xMin = calcRange.aw - scalePadX(config.W.g0);
		var scalePadY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY(unpadded);
		var yMax = calcDomain.au + scalePadY(config.W.h0);
		var yMin = calcDomain.aw - scalePadY(config.W.gg);
		return {
			ig: _Utils_update(
				calcRange,
				{
					be: config.f_,
					au: A2($elm$core$Basics$max, xMin, xMax),
					aw: A2($elm$core$Basics$min, xMin, xMax)
				}),
			f$: _Utils_update(
				calcDomain,
				{
					be: config.ew,
					au: A2($elm$core$Basics$max, yMin, yMax),
					aw: A2($elm$core$Basics$min, yMin, yMax)
				})
		};
	});
var $terezka$elm_charts$Chart$getItems = F2(
	function (plane, elements) {
		var toItems = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 2:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 3:
						var item = el.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[item]));
					case 4:
						var func = el.a;
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toItems, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3($elm$core$List$foldl, toItems, _List_Nil, elements);
	});
var $terezka$elm_charts$Chart$getLegends = function (elements) {
	var toLegends = F2(
		function (el, acc) {
			switch (el.$) {
				case 0:
					return acc;
				case 1:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 2:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 3:
					return acc;
				case 4:
					return acc;
				case 5:
					return acc;
				case 6:
					return acc;
				case 7:
					return acc;
				case 8:
					return acc;
				case 9:
					return acc;
				case 10:
					return acc;
				case 11:
					var subs = el.a;
					return A3($elm$core$List$foldl, toLegends, acc, subs);
				case 12:
					return acc;
				default:
					return acc;
			}
		});
	return A3($elm$core$List$foldl, toLegends, _List_Nil, elements);
};
var $terezka$elm_charts$Chart$TickValues = F4(
	function (xAxis, yAxis, xs, ys) {
		return {bt: xAxis, F: xs, bu: yAxis, P: ys};
	});
var $terezka$elm_charts$Chart$getTickValues = F3(
	function (plane, items, elements) {
		var toValues = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						return acc;
					case 2:
						var func = el.d;
						return A2(func, plane, acc);
					case 3:
						return acc;
					case 4:
						var func = el.a;
						return A2(func, plane, acc);
					case 5:
						var func = el.a;
						return A2(func, plane, acc);
					case 6:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 7:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 8:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldl,
							toValues,
							acc,
							A2(func, plane, items));
					case 9:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toValues, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3(
			$elm$core$List$foldl,
			toValues,
			A4($terezka$elm_charts$Chart$TickValues, _List_Nil, _List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$GridElement = function (a) {
	return {$: 9, a: a};
};
var $terezka$elm_charts$Internal$Svg$Circle = 0;
var $terezka$elm_charts$Chart$Attributes$circle = function (config) {
	return _Utils_update(
		config,
		{
			aU: $elm$core$Maybe$Just(0)
		});
};
var $terezka$elm_charts$Chart$Attributes$color = function (v) {
	return function (config) {
		return (v === '') ? config : _Utils_update(
			config,
			{gk: v});
	};
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $terezka$elm_charts$Internal$Helpers$darkGray = 'rgb(200 200 200)';
var $terezka$elm_charts$Chart$Attributes$dashed = function (value) {
	return function (config) {
		return _Utils_update(
			config,
			{bz: value});
	};
};
var $terezka$elm_charts$Internal$Helpers$pink = '#ea60df';
var $terezka$elm_charts$Internal$Svg$defaultDot = {C: '', H: 0, gk: $terezka$elm_charts$Internal$Helpers$pink, r: false, gM: 0, gN: '', gO: 5, U: 1, aU: $elm$core$Maybe$Nothing, O: 6};
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$fillOpacity = _VirtualDom_attribute('fill-opacity');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $terezka$elm_charts$Internal$Svg$isWithinPlane = F3(
	function (plane, x, y) {
		return _Utils_eq(
			A3($elm$core$Basics$clamp, plane.ig.aw, plane.ig.au, x),
			x) && _Utils_eq(
			A3($elm$core$Basics$clamp, plane.f$.aw, plane.f$.au, y),
			y);
	});
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $terezka$elm_charts$Internal$Svg$plusPath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(area_ / 4) + off;
		var r6 = side / 2;
		var r3 = side;
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_ - r6) + (' ' + $elm$core$String$fromFloat(((y_ - r3) - r6) + off))),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat(r3),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat(r3),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(-r3),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(-r3),
					'v' + $elm$core$String$fromFloat(r3 - off)
				]));
	});
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$strokeOpacity = _VirtualDom_attribute('stroke-opacity');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $terezka$elm_charts$Internal$Coordinates$scaleSVGX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerWidth(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.ig);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGX, plane, value - plane.ig.aw) + plane.ig.g3;
	});
var $terezka$elm_charts$Internal$Coordinates$scaleSVGY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerHeight(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.f$);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGY = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGY, plane, plane.f$.au - value) + plane.f$.g3;
	});
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$tan = _Basics_tan;
var $terezka$elm_charts$Internal$Svg$trianglePath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(
			(area_ * 4) / $elm$core$Basics$sqrt(3)) + (off * $elm$core$Basics$sqrt(3));
		var height = ($elm$core$Basics$sqrt(3) * side) / 2;
		var fromMiddle = height - (($elm$core$Basics$tan(
			$elm$core$Basics$degrees(30)) * side) / 2);
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_) + (' ' + $elm$core$String$fromFloat(y_ - fromMiddle))),
					'l' + ($elm$core$String$fromFloat((-side) / 2) + (' ' + $elm$core$String$fromFloat(height))),
					'h' + $elm$core$String$fromFloat(side),
					'z'
				]));
	});
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $terezka$elm_charts$Internal$Svg$withinChartArea = function (plane) {
	return $elm$svg$Svg$Attributes$clipPath(
		'url(#' + ($terezka$elm_charts$Internal$Coordinates$toId(plane) + ')'));
};
var $terezka$elm_charts$Internal$Svg$dot = F5(
	function (plane, toX, toY, config, datum_) {
		var yOrg = toY(datum_);
		var y_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, yOrg);
		var xOrg = toX(datum_);
		var x_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, xOrg);
		var styleAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(
				(config.C === '') ? config.gk : config.C),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.H)),
				$elm$svg$Svg$Attributes$fillOpacity(
				$elm$core$String$fromFloat(config.U)),
				$elm$svg$Svg$Attributes$fill(config.gk),
				$elm$svg$Svg$Attributes$class('elm-charts__dot'),
				config.r ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
			]);
		var showDot = A3($terezka$elm_charts$Internal$Svg$isWithinPlane, plane, xOrg, yOrg) || config.r;
		var highlightColor = (config.gN === '') ? config.gk : config.gN;
		var highlightAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(highlightColor),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.gO)),
				$elm$svg$Svg$Attributes$strokeOpacity(
				$elm$core$String$fromFloat(config.gM)),
				$elm$svg$Svg$Attributes$fill('transparent'),
				$elm$svg$Svg$Attributes$class('elm-charts__dot-highlight')
			]);
		var view = F3(
			function (toEl, highlightOff, toAttrs) {
				return (config.gM > 0) ? A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__dot-container')
						]),
					_List_fromArray(
						[
							A2(
							toEl,
							_Utils_ap(
								toAttrs(highlightOff),
								highlightAttrs),
							_List_Nil),
							A2(
							toEl,
							_Utils_ap(
								toAttrs(0),
								styleAttrs),
							_List_Nil)
						])) : A2(
					toEl,
					_Utils_ap(
						toAttrs(0),
						styleAttrs),
					_List_Nil);
			});
		var area_ = (2 * $elm$core$Basics$pi) * config.O;
		if (!showDot) {
			return $elm$svg$Svg$text('');
		} else {
			var _v0 = config.aU;
			if (_v0.$ === 1) {
				return $elm$svg$Svg$text('');
			} else {
				switch (_v0.a) {
					case 0:
						var _v1 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$circle,
							config.gO / 2,
							function (off) {
								var radius = $elm$core$Basics$sqrt(area_ / $elm$core$Basics$pi);
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$cx(
										$elm$core$String$fromFloat(x_)),
										$elm$svg$Svg$Attributes$cy(
										$elm$core$String$fromFloat(y_)),
										$elm$svg$Svg$Attributes$r(
										$elm$core$String$fromFloat(radius + off))
									]);
							});
					case 1:
						var _v2 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gO,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$trianglePath, area_, off, x_, y_))
									]);
							});
					case 2:
						var _v3 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.gO,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff))
									]);
							});
					case 3:
						var _v4 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.gO,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					case 4:
						var _v5 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gO,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					default:
						var _v6 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.gO,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_))
									]);
							});
				}
			}
		}
	});
var $terezka$elm_charts$Chart$Svg$dot = F4(
	function (plane, toX, toY, edits) {
		return A4(
			$terezka$elm_charts$Internal$Svg$dot,
			plane,
			toX,
			toY,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultDot));
	});
var $terezka$elm_charts$Internal$Helpers$gray = '#EFF2FA';
var $terezka$elm_charts$Internal$Svg$defaultLine = {j: _List_Nil, gh: false, gk: 'rgb(210, 210, 210)', bz: _List_Nil, k: false, r: false, U: 1, hO: -90, hP: 0, f_: 1, ih: $elm$core$Maybe$Nothing, ii: $elm$core$Maybe$Nothing, ij: $elm$core$Maybe$Nothing, m: 0, ik: $elm$core$Maybe$Nothing, il: $elm$core$Maybe$Nothing, im: $elm$core$Maybe$Nothing, n: 0};
var $terezka$elm_charts$Internal$Commands$Line = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Commands$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$cos = _Basics_cos;
var $terezka$elm_charts$Internal$Commands$joinCommands = function (commands) {
	return A2($elm$core$String$join, ' ', commands);
};
var $terezka$elm_charts$Internal$Commands$stringBoolInt = function (bool) {
	return bool ? '1' : '0';
};
var $terezka$elm_charts$Internal$Commands$stringPoint = function (_v0) {
	var x = _v0.a;
	var y = _v0.b;
	return $elm$core$String$fromFloat(x) + (' ' + $elm$core$String$fromFloat(y));
};
var $terezka$elm_charts$Internal$Commands$stringPoints = function (points) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $terezka$elm_charts$Internal$Commands$stringPoint, points));
};
var $terezka$elm_charts$Internal$Commands$stringCommand = function (command) {
	switch (command.$) {
		case 0:
			var x = command.a;
			var y = command.b;
			return 'M' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 1:
			var x = command.a;
			var y = command.b;
			return 'L' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 2:
			var cx1 = command.a;
			var cy1 = command.b;
			var cx2 = command.c;
			var cy2 = command.d;
			var x = command.e;
			var y = command.f;
			return 'C' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(cx2, cy2),
						_Utils_Tuple2(x, y)
					]));
		case 3:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 4:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 5:
			var x = command.a;
			var y = command.b;
			return 'T' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 6:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var x = command.f;
			var y = command.g;
			return 'A ' + $terezka$elm_charts$Internal$Commands$joinCommands(
				_List_fromArray(
					[
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(rx, ry)),
						$elm$core$String$fromInt(xAxisRotation),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(largeArcFlag),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(sweepFlag),
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(x, y))
					]));
		default:
			return 'Z';
	}
};
var $terezka$elm_charts$Internal$Commands$Arc = F7(
	function (a, b, c, d, e, f, g) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e, f: f, g: g};
	});
var $terezka$elm_charts$Internal$Commands$Close = {$: 7};
var $terezka$elm_charts$Internal$Commands$CubicBeziers = F6(
	function (a, b, c, d, e, f) {
		return {$: 2, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $terezka$elm_charts$Internal$Commands$CubicBeziersShort = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziers = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziersShort = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Commands$translate = F2(
	function (plane, command) {
		switch (command.$) {
			case 0:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Move,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 1:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Line,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 2:
				var cx1 = command.a;
				var cy1 = command.b;
				var cx2 = command.c;
				var cy2 = command.d;
				var x = command.e;
				var y = command.f;
				return A6(
					$terezka$elm_charts$Internal$Commands$CubicBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 3:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$CubicBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 4:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 5:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 6:
				var rx = command.a;
				var ry = command.b;
				var xAxisRotation = command.c;
				var largeArcFlag = command.d;
				var sweepFlag = command.e;
				var x = command.f;
				var y = command.g;
				return A7(
					$terezka$elm_charts$Internal$Commands$Arc,
					rx,
					ry,
					xAxisRotation,
					largeArcFlag,
					sweepFlag,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			default:
				return $terezka$elm_charts$Internal$Commands$Close;
		}
	});
var $terezka$elm_charts$Internal$Commands$description = F2(
	function (plane, commands) {
		return $terezka$elm_charts$Internal$Commands$joinCommands(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$terezka$elm_charts$Internal$Commands$translate(plane),
					$terezka$elm_charts$Internal$Commands$stringCommand),
				commands));
	});
var $terezka$elm_charts$Internal$Svg$lengthInCartesianX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX;
var $terezka$elm_charts$Internal$Svg$lengthInCartesianY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY;
var $elm$core$Basics$sin = _Basics_sin;
var $elm$svg$Svg$Attributes$strokeDasharray = _VirtualDom_attribute('stroke-dasharray');
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $terezka$elm_charts$Internal$Svg$withAttrs = F3(
	function (attrs, toEl, defaultAttrs) {
		return toEl(
			_Utils_ap(
				defaultAttrs,
				A2(
					$elm$core$List$map,
					$elm$html$Html$Attributes$map($elm$core$Basics$never),
					attrs)));
	});
var $terezka$elm_charts$Internal$Svg$line = F2(
	function (plane, config) {
		var angle = $elm$core$Basics$degrees(config.hO);
		var _v0 = function () {
			var _v3 = _Utils_Tuple3(
				_Utils_Tuple2(config.ih, config.ii),
				_Utils_Tuple2(config.ik, config.il),
				_Utils_Tuple2(config.ij, config.im));
			if (!_v3.a.a.$) {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v4 = _v3.a;
							var a = _v4.a.a;
							var b = _v4.b.a;
							var _v5 = _v3.b;
							var _v6 = _v5.a;
							var _v7 = _v5.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(plane.f$.aw, plane.f$.aw));
						} else {
							var _v38 = _v3.a;
							var a = _v38.a.a;
							var b = _v38.b.a;
							var _v39 = _v3.b;
							var _v40 = _v39.a;
							var c = _v39.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						}
					} else {
						if (_v3.b.b.$ === 1) {
							var _v41 = _v3.a;
							var a = _v41.a.a;
							var b = _v41.b.a;
							var _v42 = _v3.b;
							var c = _v42.a.a;
							var _v43 = _v42.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						} else {
							return _Utils_Tuple2(
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.ig.aw, config.ih),
									A2($elm$core$Maybe$withDefault, plane.ig.au, config.ii)),
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.f$.aw, config.ik),
									A2($elm$core$Maybe$withDefault, plane.f$.au, config.il)));
						}
					}
				} else {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v8 = _v3.a;
							var a = _v8.a.a;
							var _v9 = _v8.b;
							var _v10 = _v3.b;
							var _v11 = _v10.a;
							var _v12 = _v10.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, a),
								_Utils_Tuple2(plane.f$.aw, plane.f$.au));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v51 = _v3.a;
									var a = _v51.a.a;
									var _v52 = _v51.b;
									var _v53 = _v3.b;
									var _v54 = _v53.a;
									var b = _v53.b.a;
									var _v55 = _v3.c;
									var xOff = _v55.a.a;
									var yOff = _v55.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v56 = _v3.a;
									var a = _v56.a.a;
									var _v57 = _v56.b;
									var _v58 = _v3.b;
									var _v59 = _v58.a;
									var b = _v58.b.a;
									var _v60 = _v3.c;
									var xOff = _v60.a.a;
									var _v61 = _v60.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v44 = _v3.a;
									var a = _v44.a.a;
									var _v45 = _v44.b;
									var _v46 = _v3.b;
									var _v47 = _v46.a;
									var b = _v46.b.a;
									var _v48 = _v3.c;
									var _v49 = _v48.a;
									var _v50 = _v48.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ig.au),
										_Utils_Tuple2(b, b));
								} else {
									var _v62 = _v3.a;
									var a = _v62.a.a;
									var _v63 = _v62.b;
									var _v64 = _v3.b;
									var _v65 = _v64.a;
									var b = _v64.b.a;
									var _v66 = _v3.c;
									var _v67 = _v66.a;
									var yOff = _v66.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v35 = _v3.a;
							var c = _v35.a.a;
							var _v36 = _v35.b;
							var _v37 = _v3.b;
							var a = _v37.a.a;
							var b = _v37.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v75 = _v3.a;
									var a = _v75.a.a;
									var _v76 = _v75.b;
									var _v77 = _v3.b;
									var b = _v77.a.a;
									var _v78 = _v77.b;
									var _v79 = _v3.c;
									var xOff = _v79.a.a;
									var yOff = _v79.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v80 = _v3.a;
									var a = _v80.a.a;
									var _v81 = _v80.b;
									var _v82 = _v3.b;
									var b = _v82.a.a;
									var _v83 = _v82.b;
									var _v84 = _v3.c;
									var xOff = _v84.a.a;
									var _v85 = _v84.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v68 = _v3.a;
									var a = _v68.a.a;
									var _v69 = _v68.b;
									var _v70 = _v3.b;
									var b = _v70.a.a;
									var _v71 = _v70.b;
									var _v72 = _v3.c;
									var _v73 = _v72.a;
									var _v74 = _v72.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ig.au),
										_Utils_Tuple2(b, b));
								} else {
									var _v86 = _v3.a;
									var a = _v86.a.a;
									var _v87 = _v86.b;
									var _v88 = _v3.b;
									var b = _v88.a.a;
									var _v89 = _v88.b;
									var _v90 = _v3.c;
									var _v91 = _v90.a;
									var yOff = _v90.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				}
			} else {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v13 = _v3.a;
							var _v14 = _v13.a;
							var b = _v13.b.a;
							var _v15 = _v3.b;
							var _v16 = _v15.a;
							var _v17 = _v15.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(b, b),
								_Utils_Tuple2(plane.f$.aw, plane.f$.au));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v99 = _v3.a;
									var _v100 = _v99.a;
									var a = _v99.b.a;
									var _v101 = _v3.b;
									var _v102 = _v101.a;
									var b = _v101.b.a;
									var _v103 = _v3.c;
									var xOff = _v103.a.a;
									var yOff = _v103.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v104 = _v3.a;
									var _v105 = _v104.a;
									var a = _v104.b.a;
									var _v106 = _v3.b;
									var _v107 = _v106.a;
									var b = _v106.b.a;
									var _v108 = _v3.c;
									var xOff = _v108.a.a;
									var _v109 = _v108.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v92 = _v3.a;
									var _v93 = _v92.a;
									var a = _v92.b.a;
									var _v94 = _v3.b;
									var _v95 = _v94.a;
									var b = _v94.b.a;
									var _v96 = _v3.c;
									var _v97 = _v96.a;
									var _v98 = _v96.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ig.au),
										_Utils_Tuple2(b, b));
								} else {
									var _v110 = _v3.a;
									var _v111 = _v110.a;
									var a = _v110.b.a;
									var _v112 = _v3.b;
									var _v113 = _v112.a;
									var b = _v112.b.a;
									var _v114 = _v3.c;
									var _v115 = _v114.a;
									var yOff = _v114.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v32 = _v3.a;
							var _v33 = _v32.a;
							var c = _v32.b.a;
							var _v34 = _v3.b;
							var a = _v34.a.a;
							var b = _v34.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v123 = _v3.a;
									var _v124 = _v123.a;
									var a = _v123.b.a;
									var _v125 = _v3.b;
									var b = _v125.a.a;
									var _v126 = _v125.b;
									var _v127 = _v3.c;
									var xOff = _v127.a.a;
									var yOff = _v127.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v128 = _v3.a;
									var _v129 = _v128.a;
									var a = _v128.b.a;
									var _v130 = _v3.b;
									var b = _v130.a.a;
									var _v131 = _v130.b;
									var _v132 = _v3.c;
									var xOff = _v132.a.a;
									var _v133 = _v132.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v116 = _v3.a;
									var _v117 = _v116.a;
									var a = _v116.b.a;
									var _v118 = _v3.b;
									var b = _v118.a.a;
									var _v119 = _v118.b;
									var _v120 = _v3.c;
									var _v121 = _v120.a;
									var _v122 = _v120.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.ig.au),
										_Utils_Tuple2(b, b));
								} else {
									var _v134 = _v3.a;
									var _v135 = _v134.a;
									var a = _v134.b.a;
									var _v136 = _v3.b;
									var b = _v136.a.a;
									var _v137 = _v136.b;
									var _v138 = _v3.c;
									var _v139 = _v138.a;
									var yOff = _v138.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				} else {
					if (!_v3.b.a.$) {
						if (!_v3.b.b.$) {
							var _v18 = _v3.a;
							var _v19 = _v18.a;
							var _v20 = _v18.b;
							var _v21 = _v3.b;
							var a = _v21.a.a;
							var b = _v21.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ig.aw, plane.ig.aw),
								_Utils_Tuple2(a, b));
						} else {
							var _v22 = _v3.a;
							var _v23 = _v22.a;
							var _v24 = _v22.b;
							var _v25 = _v3.b;
							var a = _v25.a.a;
							var _v26 = _v25.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ig.aw, plane.ig.au),
								_Utils_Tuple2(a, a));
						}
					} else {
						if (!_v3.b.b.$) {
							var _v27 = _v3.a;
							var _v28 = _v27.a;
							var _v29 = _v27.b;
							var _v30 = _v3.b;
							var _v31 = _v30.a;
							var b = _v30.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ig.aw, plane.ig.au),
								_Utils_Tuple2(b, b));
						} else {
							var _v140 = _v3.a;
							var _v141 = _v140.a;
							var _v142 = _v140.b;
							var _v143 = _v3.b;
							var _v144 = _v143.a;
							var _v145 = _v143.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.ig.aw, plane.ig.au),
								_Utils_Tuple2(plane.f$.aw, plane.f$.au));
						}
					}
				}
			}
		}();
		var _v1 = _v0.a;
		var x1 = _v1.a;
		var x2 = _v1.b;
		var _v2 = _v0.b;
		var y1 = _v2.a;
		var y2 = _v2.b;
		var x1_ = x1 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.m);
		var x2_ = x2 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.m);
		var y1_ = y1 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.n);
		var y2_ = y2 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.n);
		var _v146 = (config.hP > 0) ? _Utils_Tuple2(
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianX,
				plane,
				$elm$core$Basics$cos(angle) * config.hP),
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianY,
				plane,
				$elm$core$Basics$sin(angle) * config.hP)) : _Utils_Tuple2(0, 0);
		var tickOffsetX = _v146.a;
		var tickOffsetY = _v146.b;
		var cmds = config.k ? _Utils_ap(
			(config.hP > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_ + tickOffsetX, y2_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_, y2_)
				]),
			_Utils_ap(
				config.gh ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y1_),
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]),
				(config.hP > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_ + tickOffsetX, y1_ + tickOffsetY)
					]) : _List_Nil)) : _Utils_ap(
			(config.hP > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_ + tickOffsetX, y1_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_, y1_)
				]),
			_Utils_ap(
				config.gh ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y2_),
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]),
				(config.hP > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_ + tickOffsetX, y2_ + tickOffsetY)
					]) : _List_Nil));
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.j,
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__line'),
					$elm$svg$Svg$Attributes$fill('transparent'),
					$elm$svg$Svg$Attributes$stroke(config.gk),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.f_)),
					$elm$svg$Svg$Attributes$strokeOpacity(
					$elm$core$String$fromFloat(config.U)),
					$elm$svg$Svg$Attributes$strokeDasharray(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, $elm$core$String$fromFloat, config.bz))),
					$elm$svg$Svg$Attributes$d(
					A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
					config.r ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Chart$Svg$line = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$line,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultLine));
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $terezka$elm_charts$Chart$Attributes$size = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{O: v});
	};
};
var $terezka$elm_charts$Chart$Attributes$width = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{f_: v});
	};
};
var $terezka$elm_charts$Chart$Attributes$x1 = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{
				ih: $elm$core$Maybe$Just(v)
			});
	};
};
var $terezka$elm_charts$Chart$Attributes$y1 = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{
				ik: $elm$core$Maybe$Just(v)
			});
	};
};
var $terezka$elm_charts$Chart$grid = function (edits) {
	var config = A2(
		$terezka$elm_charts$Internal$Helpers$apply,
		edits,
		{gk: '', bz: _List_Nil, a7: false, f_: 0});
	var width = (!config.f_) ? (config.a7 ? 0.5 : 1) : config.f_;
	var color = $elm$core$String$isEmpty(config.gk) ? (config.a7 ? $terezka$elm_charts$Internal$Helpers$darkGray : $terezka$elm_charts$Internal$Helpers$gray) : config.gk;
	var toDot = F4(
		function (vs, p, x, y) {
			return (A2($elm$core$List$member, x, vs.bt) || A2($elm$core$List$member, y, vs.bu)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A5(
					$terezka$elm_charts$Chart$Svg$dot,
					p,
					function ($) {
						return $.ig;
					},
					function ($) {
						return $.f$;
					},
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$size(width),
							$terezka$elm_charts$Chart$Attributes$circle
						]),
					{ig: x, f$: y}));
		});
	var toXGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.bt) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$x1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.bz)
						])));
		});
	var toYGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.bu) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$y1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.bz)
						])));
		});
	return $terezka$elm_charts$Chart$GridElement(
		F2(
			function (p, vs) {
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__grid')
						]),
					config.a7 ? A2(
						$elm$core$List$concatMap,
						function (x) {
							return A2(
								$elm$core$List$filterMap,
								A3(toDot, vs, p, x),
								vs.P);
						},
						vs.F) : _List_fromArray(
						[
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__x-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toXGrid, vs, p),
								vs.F)),
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__y-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toYGrid, vs, p),
								vs.P))
						]));
			}));
};
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $terezka$elm_charts$Chart$viewElements = F6(
	function (config, plane, tickValues, allItems, allLegends, elements) {
		var viewOne = F2(
			function (el, _v0) {
				var before = _v0.a;
				var chart_ = _v0.b;
				var after = _v0.c;
				switch (el.$) {
					case 0:
						return _Utils_Tuple3(before, chart_, after);
					case 1:
						var view = el.d;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 2:
						var view = el.e;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 3:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 4:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 5:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 6:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 7:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 8:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 9:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(view, plane, tickValues),
								chart_),
							after);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							A2(func, plane, allItems));
					case 11:
						var els = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							els);
					case 12:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					default:
						var view = el.a;
						return _Utils_Tuple3(
							($elm$core$List$length(chart_) > 0) ? A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								before) : before,
							chart_,
							($elm$core$List$length(chart_) > 0) ? after : A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								after));
				}
			});
		return A3(
			$elm$core$List$foldr,
			viewOne,
			_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$chart = F2(
	function (edits, unindexedElements) {
		var indexedElements = function () {
			var toIndexedEl = F2(
				function (el, _v4) {
					var acc = _v4.a;
					var index = _v4.b;
					switch (el.$) {
						case 0:
							var toElAndIndex = el.a;
							var _v6 = toElAndIndex(index);
							var newEl = _v6.a;
							var newIndex = _v6.b;
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[newEl])),
								newIndex);
						case 11:
							var els = el.a;
							return A3(
								$elm$core$List$foldl,
								toIndexedEl,
								_Utils_Tuple2(acc, index),
								els);
						default:
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[el])),
								index);
					}
				});
			return A3(
				$elm$core$List$foldl,
				toIndexedEl,
				_Utils_Tuple2(_List_Nil, 0),
				unindexedElements).a;
		}();
		var elements = function () {
			var isGrid = function (el) {
				if (el.$ === 9) {
					return true;
				} else {
					return false;
				}
			};
			return A2($elm$core$List$any, isGrid, indexedElements) ? indexedElements : A2(
				$elm$core$List$cons,
				$terezka$elm_charts$Chart$grid(_List_Nil),
				indexedElements);
		}();
		var legends_ = $terezka$elm_charts$Chart$getLegends(elements);
		var config = A2(
			$terezka$elm_charts$Internal$Helpers$apply,
			edits,
			{
				j: _List_fromArray(
					[
						$elm$svg$Svg$Attributes$style('overflow: visible;')
					]),
				cv: _List_Nil,
				bE: _List_Nil,
				ew: 300,
				bI: _List_Nil,
				at: {gg: 0, g0: 0, hp: 0, h0: 0},
				W: {gg: 0, g0: 0, hp: 0, h0: 0},
				dh: _List_Nil,
				b2: true,
				f_: 300
			});
		var plane = A2($terezka$elm_charts$Chart$definePlane, config, elements);
		var items = A2($terezka$elm_charts$Chart$getItems, plane, elements);
		var toEvent = function (_v2) {
			var event_ = _v2;
			var _v1 = event_.gv;
			var decoder = _v1;
			return A2(
				$terezka$elm_charts$Internal$Svg$Event,
				event_.g7,
				decoder(items));
		};
		var tickValues = A3($terezka$elm_charts$Chart$getTickValues, plane, items, elements);
		var _v0 = A6($terezka$elm_charts$Chart$viewElements, config, plane, tickValues, items, legends_, elements);
		var beforeEls = _v0.a;
		var chartEls = _v0.b;
		var afterEls = _v0.c;
		return A5(
			$terezka$elm_charts$Internal$Svg$container,
			plane,
			{
				j: config.j,
				bE: A2($elm$core$List$map, toEvent, config.bE),
				bI: config.bI,
				b2: config.b2
			},
			beforeEls,
			chartEls,
			afterEls);
	});
var $terezka$elm_charts$Internal$Item$Custom = {$: 2};
var $terezka$elm_charts$Chart$CustomElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $terezka$elm_charts$Chart$Indexed = function (a) {
	return {$: 0, a: a};
};
var $terezka$elm_charts$Internal$Item$Rendered = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $terezka$elm_charts$Internal$Item$generalize = function (_v0) {
	var meta = _v0.a;
	var item = _v0.b;
	return A2(
		$terezka$elm_charts$Internal$Item$Rendered,
		{
			gk: meta.gk,
			gs: meta.gs,
			gR: meta.gR,
			gY: meta.gY,
			g7: meta.g7,
			hh: meta.hU(meta.hh),
			hU: $elm$core$Basics$identity,
			h$: meta.h$,
			ih: meta.ih,
			ii: meta.ii,
			f$: meta.f$
		},
		item);
};
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $terezka$elm_charts$Internal$Item$render = F2(
	function (plane, _v0) {
		var item = _v0.b;
		return A2(
			item.hn,
			plane,
			item.hX(plane));
	});
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $terezka$elm_charts$Internal$Produce$tooltipRow = F3(
	function (color, title, text) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '3px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title + ':')
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'right'),
							A2($elm$html$Html$Attributes$style, 'padding', '0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text)
						]))
				]));
	});
var $terezka$elm_charts$Chart$custom = function (config) {
	return $terezka$elm_charts$Chart$Indexed(
		function (elIndex) {
			var item = A2(
				$terezka$elm_charts$Internal$Item$Rendered,
				{
					gk: config.gk,
					gs: config.gp,
					gR: {f2: -1, d9: -1, gA: elIndex, hw: -1, hy: -1},
					gY: true,
					g7: $elm$core$Maybe$Just(config.g7),
					hh: 0,
					hU: $elm$core$Basics$always($terezka$elm_charts$Internal$Item$Custom),
					h$: config.gH(config.gp),
					ih: config.hg.ih,
					ii: config.hg.ii,
					f$: config.hg.il
				},
				{
					z: config.hg,
					hn: F2(
						function (plane, position) {
							return config.hn(plane);
						}),
					hX: function (_v0) {
						return config.hg;
					},
					h_: function (_v1) {
						return _List_fromArray(
							[
								A3(
								$terezka$elm_charts$Internal$Produce$tooltipRow,
								config.gk,
								config.g7,
								config.gH(config.gp))
							]);
					}
				});
			return _Utils_Tuple2(
				A2(
					$terezka$elm_charts$Chart$CustomElement,
					$terezka$elm_charts$Internal$Item$generalize(item),
					function (p) {
						return A2(
							$elm$svg$Svg$map,
							$elm$core$Basics$never,
							A2($terezka$elm_charts$Internal$Item$render, p, item));
					}),
				elIndex + 1);
		});
};
var $terezka$elm_charts$Chart$Attributes$domain = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{cv: v});
	};
};
var $terezka$elm_charts$Chart$SubElements = function (a) {
	return {$: 10, a: a};
};
var $terezka$elm_charts$Chart$each = F2(
	function (items, func) {
		return $terezka$elm_charts$Chart$SubElements(
			F2(
				function (p, _v0) {
					return A2(
						$elm$core$List$concatMap,
						func(p),
						items);
				}));
	});
var $terezka$elm_charts$Chart$Attributes$exactly = F3(
	function (exact, _v0, _v1) {
		return exact;
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Metric$countToPercentileRange = F2(
	function (percentiles, count) {
		var fn = function (_v1) {
			var percent = _v1.a;
			var percentile = _v1.b;
			return (_Utils_cmp(count, percentile) > -1) ? $elm$core$Maybe$Just(percent) : $elm$core$Maybe$Nothing;
		};
		var ps = A2($elm$core$List$filterMap, fn, percentiles);
		var _v0 = $elm$core$List$maximum(ps);
		if (!_v0.$) {
			var p = _v0.a;
			return p;
		} else {
			return 0;
		}
	});
var $author$project$Models$Metric$getPercentileRangeForMetrics = function (_v0) {
	var counts = _v0.d8;
	var percentiles = _v0.e3;
	var fn = function (_v1) {
		var count = _v1.d7;
		var relativeTimeAgo = _v1.fj;
		return _Utils_Tuple2(
			relativeTimeAgo,
			A2($author$project$Models$Metric$countToPercentileRange, percentiles, count));
	};
	return A2($elm$core$List$map, fn, counts);
};
var $terezka$elm_charts$Chart$Attributes$height = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{ew: v});
	};
};
var $terezka$elm_charts$Chart$Attributes$highest = F2(
	function (v, edit) {
		return function (b) {
			return _Utils_update(
				b,
				{
					au: A3(edit, v, b.au, b.gq)
				});
		};
	});
var $terezka$elm_charts$Chart$ListOfElements = function (a) {
	return {$: 11, a: a};
};
var $terezka$elm_charts$Chart$list = $terezka$elm_charts$Chart$ListOfElements;
var $terezka$elm_charts$Chart$Attributes$margin = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{at: v});
	};
};
var $terezka$elm_charts$Chart$Attributes$offset = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{b: v});
	};
};
var $terezka$elm_charts$Internal$Svg$TopOrBottom = 5;
var $terezka$elm_charts$Chart$Attributes$onTopOrBottom = function (config) {
	return _Utils_update(
		config,
		{
			gw: $elm$core$Maybe$Just(5)
		});
};
var $author$project$App$Metrics$View$percentileToColor = function (p) {
	return (p >= 90) ? '#62bb6e' : ((p >= 80) ? '#79c679' : ((p >= 70) ? '#8ed082' : ((p >= 60) ? '#a2d88a' : ((p >= 50) ? '#b6e192' : ((p >= 40) ? '#c8e99b' : ((p >= 30) ? '#daf0a4' : ((p >= 20) ? '#e5f5ac' : ((p >= 10) ? '#f1fab5' : '#f8fddd'))))))));
};
var $terezka$elm_charts$Chart$Attributes$range = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{dh: v});
	};
};
var $terezka$elm_charts$Internal$Svg$defaultRect = {j: _List_Nil, C: 'rgba(210, 210, 210, 1)', H: 1, gk: 'rgba(210, 210, 210, 0.5)', r: false, U: 1, ih: $elm$core$Maybe$Nothing, ii: $elm$core$Maybe$Nothing, ik: $elm$core$Maybe$Nothing, il: $elm$core$Maybe$Nothing};
var $terezka$elm_charts$Internal$Svg$rect = F2(
	function (plane, config) {
		var _v0 = function () {
			var _v3 = _Utils_Tuple2(
				_Utils_Tuple2(config.ih, config.ii),
				_Utils_Tuple2(config.ik, config.il));
			_v3$11:
			while (true) {
				if (!_v3.a.a.$) {
					if (_v3.a.b.$ === 1) {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v8 = _v3.a;
								var a = _v8.a.a;
								var _v9 = _v8.b;
								var _v10 = _v3.b;
								var _v11 = _v10.a;
								var _v12 = _v10.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, a),
									_Utils_Tuple2(plane.f$.aw, plane.f$.au));
							} else {
								break _v3$11;
							}
						} else {
							if (!_v3.b.b.$) {
								var _v35 = _v3.a;
								var c = _v35.a.a;
								var _v36 = _v35.b;
								var _v37 = _v3.b;
								var a = _v37.a.a;
								var b = _v37.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(c, c),
									_Utils_Tuple2(a, b));
							} else {
								break _v3$11;
							}
						}
					} else {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v4 = _v3.a;
								var a = _v4.a.a;
								var b = _v4.b.a;
								var _v5 = _v3.b;
								var _v6 = _v5.a;
								var _v7 = _v5.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(plane.f$.aw, plane.f$.au));
							} else {
								var _v38 = _v3.a;
								var a = _v38.a.a;
								var b = _v38.b.a;
								var _v39 = _v3.b;
								var _v40 = _v39.a;
								var c = _v39.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(c, c));
							}
						} else {
							if (_v3.b.b.$ === 1) {
								var _v41 = _v3.a;
								var a = _v41.a.a;
								var b = _v41.b.a;
								var _v42 = _v3.b;
								var c = _v42.a.a;
								var _v43 = _v42.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(a, b),
									_Utils_Tuple2(c, c));
							} else {
								break _v3$11;
							}
						}
					}
				} else {
					if (!_v3.a.b.$) {
						if (_v3.b.a.$ === 1) {
							if (_v3.b.b.$ === 1) {
								var _v13 = _v3.a;
								var _v14 = _v13.a;
								var b = _v13.b.a;
								var _v15 = _v3.b;
								var _v16 = _v15.a;
								var _v17 = _v15.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(b, b),
									_Utils_Tuple2(plane.f$.aw, plane.f$.au));
							} else {
								break _v3$11;
							}
						} else {
							if (!_v3.b.b.$) {
								var _v32 = _v3.a;
								var _v33 = _v32.a;
								var c = _v32.b.a;
								var _v34 = _v3.b;
								var a = _v34.a.a;
								var b = _v34.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(c, c),
									_Utils_Tuple2(a, b));
							} else {
								break _v3$11;
							}
						}
					} else {
						if (!_v3.b.a.$) {
							if (!_v3.b.b.$) {
								var _v18 = _v3.a;
								var _v19 = _v18.a;
								var _v20 = _v18.b;
								var _v21 = _v3.b;
								var a = _v21.a.a;
								var b = _v21.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ig.aw, plane.ig.aw),
									_Utils_Tuple2(a, b));
							} else {
								var _v22 = _v3.a;
								var _v23 = _v22.a;
								var _v24 = _v22.b;
								var _v25 = _v3.b;
								var a = _v25.a.a;
								var _v26 = _v25.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ig.aw, plane.ig.au),
									_Utils_Tuple2(a, a));
							}
						} else {
							if (!_v3.b.b.$) {
								var _v27 = _v3.a;
								var _v28 = _v27.a;
								var _v29 = _v27.b;
								var _v30 = _v3.b;
								var _v31 = _v30.a;
								var b = _v30.b.a;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ig.aw, plane.ig.au),
									_Utils_Tuple2(b, b));
							} else {
								var _v44 = _v3.a;
								var _v45 = _v44.a;
								var _v46 = _v44.b;
								var _v47 = _v3.b;
								var _v48 = _v47.a;
								var _v49 = _v47.b;
								return _Utils_Tuple2(
									_Utils_Tuple2(plane.ig.aw, plane.ig.au),
									_Utils_Tuple2(plane.f$.aw, plane.f$.au));
							}
						}
					}
				}
			}
			return _Utils_Tuple2(
				_Utils_Tuple2(
					A2($elm$core$Maybe$withDefault, plane.ig.aw, config.ih),
					A2($elm$core$Maybe$withDefault, plane.ig.au, config.ii)),
				_Utils_Tuple2(
					A2($elm$core$Maybe$withDefault, plane.f$.aw, config.ik),
					A2($elm$core$Maybe$withDefault, plane.f$.au, config.il)));
		}();
		var _v1 = _v0.a;
		var x1_ = _v1.a;
		var x2_ = _v1.b;
		var _v2 = _v0.b;
		var y1_ = _v2.a;
		var y2_ = _v2.b;
		var cmds = _List_fromArray(
			[
				A2($terezka$elm_charts$Internal$Commands$Move, x1_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x2_, y1_),
				A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y2_),
				A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
			]);
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.j,
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__rect'),
					$elm$svg$Svg$Attributes$fill(config.gk),
					$elm$svg$Svg$Attributes$fillOpacity(
					$elm$core$String$fromFloat(config.U)),
					$elm$svg$Svg$Attributes$stroke(config.C),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.H)),
					$elm$svg$Svg$Attributes$d(
					A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
					config.r ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Chart$Svg$rect = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$rect,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultRect));
	});
var $terezka$elm_charts$Internal$Item$getPosition = F2(
	function (plane, _v0) {
		var item = _v0.b;
		return item.hX(plane);
	});
var $terezka$elm_charts$Chart$HtmlElement = function (a) {
	return {$: 13, a: a};
};
var $terezka$elm_charts$Chart$html = function (func) {
	return $terezka$elm_charts$Chart$HtmlElement(
		F2(
			function (p, _v0) {
				return func(p);
			}));
};
var $terezka$elm_charts$Internal$Svg$defaultTooltip = {aF: true, gd: 'white', C: '#D8D8D8', gw: $elm$core$Maybe$Nothing, gG: $elm$core$Maybe$Nothing, ew: 0, b: 8, f_: 0};
var $terezka$elm_charts$Internal$Svg$Bottom = 3;
var $terezka$elm_charts$Internal$Svg$Left = 1;
var $terezka$elm_charts$Internal$Svg$Right = 2;
var $terezka$elm_charts$Internal$Svg$Top = 0;
var $terezka$elm_charts$Internal$Coordinates$bottom = function (pos) {
	return {ig: pos.ih + ((pos.ii - pos.ih) / 2), f$: pos.ik};
};
var $terezka$elm_charts$Internal$Coordinates$left = function (pos) {
	return {ig: pos.ih, f$: pos.ik + ((pos.il - pos.ik) / 2)};
};
var $terezka$elm_charts$Internal$Svg$positionHtml = F7(
	function (plane, x, y, xOff, yOff, attrs, content) {
		var yPercentage = ((A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y) - yOff) * 100) / plane.f$.be;
		var xPercentage = ((A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x) + xOff) * 100) / plane.ig.be;
		var posititonStyles = _List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'left',
				$elm$core$String$fromFloat(xPercentage) + '%'),
				A2(
				$elm$html$Html$Attributes$style,
				'top',
				$elm$core$String$fromFloat(yPercentage) + '%'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '-400px'),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute')
			]);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(posititonStyles, attrs),
			content);
	});
var $terezka$elm_charts$Internal$Coordinates$right = function (pos) {
	return {ig: pos.ii, f$: pos.ik + ((pos.il - pos.ik) / 2)};
};
var $terezka$elm_charts$Internal$Svg$tooltipPointerStyle = F4(
	function (direction, className, background, borderColor) {
		var config = function () {
			switch (direction) {
				case 0:
					return {ak: 'right', R: 'top', Z: 'left'};
				case 3:
					return {ak: 'right', R: 'bottom', Z: 'left'};
				case 1:
					return {ak: 'bottom', R: 'left', Z: 'top'};
				case 2:
					return {ak: 'bottom', R: 'right', Z: 'top'};
				case 4:
					return {ak: 'bottom', R: 'left', Z: 'top'};
				default:
					return {ak: 'right', R: 'top', Z: 'left'};
			}
		}();
		return '\n  .' + (className + (':before, .' + (className + (':after {\n    content: "";\n    position: absolute;\n    border-' + (config.Z + (': 5px solid transparent;\n    border-' + (config.ak + (': 5px solid transparent;\n    ' + (config.R + (': 100%;\n    ' + (config.Z + (': 50%;\n    margin-' + (config.Z + (': -5px;\n  }\n\n  .' + (className + (':after {\n    border-' + (config.R + (': 5px solid ' + (background + (';\n    margin-' + (config.R + (': -1px;\n    z-index: 1;\n    height: 0px;\n  }\n\n  .' + (className + (':before {\n    border-' + (config.R + (': 5px solid ' + (borderColor + ';\n    height: 0px;\n  }\n  ')))))))))))))))))))))))))));
	});
var $terezka$elm_charts$Internal$Coordinates$top = function (pos) {
	return {ig: pos.ih + ((pos.ii - pos.ih) / 2), f$: pos.il};
};
var $terezka$elm_charts$Internal$Svg$tooltip = F5(
	function (plane, pos, config, htmlAttrs, content) {
		var distanceTop = A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, pos.il);
		var distanceRight = plane.ig.be - A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, pos.ih);
		var distanceLeft = A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, pos.ii);
		var distanceBottom = plane.f$.be - A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, pos.ik);
		var direction = function () {
			var _v5 = config.gw;
			if (!_v5.$) {
				switch (_v5.a) {
					case 4:
						var _v6 = _v5.a;
						return (config.f_ > 0) ? ((_Utils_cmp(distanceLeft, config.f_ + config.b) > 0) ? 1 : 2) : ((_Utils_cmp(distanceLeft, distanceRight) > 0) ? 1 : 2);
					case 5:
						var _v7 = _v5.a;
						return (config.ew > 0) ? ((_Utils_cmp(distanceTop, config.ew + config.b) > 0) ? 0 : 3) : ((_Utils_cmp(distanceTop, distanceBottom) > 0) ? 0 : 3);
					default:
						var dir = _v5.a;
						return dir;
				}
			} else {
				var isLargest = function (a) {
					return $elm$core$List$all(
						function (b) {
							return _Utils_cmp(a, b) > -1;
						});
				};
				return A2(
					isLargest,
					distanceTop,
					_List_fromArray(
						[distanceBottom, distanceLeft, distanceRight])) ? 0 : (A2(
					isLargest,
					distanceBottom,
					_List_fromArray(
						[distanceTop, distanceLeft, distanceRight])) ? 3 : (A2(
					isLargest,
					distanceLeft,
					_List_fromArray(
						[distanceTop, distanceBottom, distanceRight])) ? 1 : 2));
			}
		}();
		var focalPoint = function () {
			var _v2 = config.gG;
			if (!_v2.$) {
				var focal = _v2.a;
				switch (direction) {
					case 0:
						return $terezka$elm_charts$Internal$Coordinates$top(
							focal(pos));
					case 3:
						return $terezka$elm_charts$Internal$Coordinates$bottom(
							focal(pos));
					case 1:
						return $terezka$elm_charts$Internal$Coordinates$left(
							focal(pos));
					case 2:
						return $terezka$elm_charts$Internal$Coordinates$right(
							focal(pos));
					case 4:
						return $terezka$elm_charts$Internal$Coordinates$left(
							focal(pos));
					default:
						return $terezka$elm_charts$Internal$Coordinates$right(
							focal(pos));
				}
			} else {
				switch (direction) {
					case 0:
						return $terezka$elm_charts$Internal$Coordinates$top(pos);
					case 3:
						return $terezka$elm_charts$Internal$Coordinates$bottom(pos);
					case 1:
						return $terezka$elm_charts$Internal$Coordinates$left(pos);
					case 2:
						return $terezka$elm_charts$Internal$Coordinates$right(pos);
					case 4:
						return $terezka$elm_charts$Internal$Coordinates$left(pos);
					default:
						return $terezka$elm_charts$Internal$Coordinates$right(pos);
				}
			}
		}();
		var arrowWidth = config.aF ? 4 : 0;
		var _v0 = function () {
			switch (direction) {
				case 0:
					return {al: 'elm-charts__tooltip-top', aB: 'translate(-50%, -100%)', m: 0, n: config.b + arrowWidth};
				case 3:
					return {al: 'elm-charts__tooltip-bottom', aB: 'translate(-50%, 0%)', m: 0, n: (-config.b) - arrowWidth};
				case 1:
					return {al: 'elm-charts__tooltip-left', aB: 'translate(-100%, -50%)', m: (-config.b) - arrowWidth, n: 0};
				case 2:
					return {al: 'elm-charts__tooltip-right', aB: 'translate(0, -50%)', m: config.b + arrowWidth, n: 0};
				case 4:
					return {al: 'elm-charts__tooltip-leftOrRight', aB: 'translate(0, -50%)', m: (-config.b) - arrowWidth, n: 0};
				default:
					return {al: 'elm-charts__tooltip-topOrBottom', aB: 'translate(-50%, -100%)', m: 0, n: config.b + arrowWidth};
			}
		}();
		var xOff = _v0.m;
		var yOff = _v0.n;
		var transformation = _v0.aB;
		var className = _v0.al;
		var children = config.aF ? A2(
			$elm$core$List$cons,
			A3(
				$elm$html$Html$node,
				'style',
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A4($terezka$elm_charts$Internal$Svg$tooltipPointerStyle, direction, className, config.gd, config.C))
					])),
			content) : content;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(className),
					A2($elm$html$Html$Attributes$style, 'transform', transformation),
					A2($elm$html$Html$Attributes$style, 'padding', '5px 8px'),
					A2($elm$html$Html$Attributes$style, 'background', config.gd),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + config.C),
					A2($elm$html$Html$Attributes$style, 'border-radius', '3px'),
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
				]),
			htmlAttrs);
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$never,
			A7($terezka$elm_charts$Internal$Svg$positionHtml, plane, focalPoint.ig, focalPoint.f$, xOff, yOff, attributes, children));
	});
var $terezka$elm_charts$Chart$Svg$tooltip = F3(
	function (plane, pos, edits) {
		return A3(
			$terezka$elm_charts$Internal$Svg$tooltip,
			plane,
			pos,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultTooltip));
	});
var $terezka$elm_charts$Internal$Item$tooltip = function (_v0) {
	var item = _v0.b;
	return item.h_(0);
};
var $terezka$elm_charts$Chart$tooltip = F4(
	function (i, edits, attrs_, content) {
		return $terezka$elm_charts$Chart$html(
			function (p) {
				var pos = $terezka$elm_charts$Internal$Item$getLimits(i);
				var content_ = _Utils_eq(content, _List_Nil) ? $terezka$elm_charts$Internal$Item$tooltip(i) : content;
				return A3($terezka$elm_charts$Internal$Svg$isWithinPlane, p, pos.ih, pos.il) ? A5(
					$terezka$elm_charts$Chart$Svg$tooltip,
					p,
					A2($terezka$elm_charts$Internal$Item$getPosition, p, i),
					edits,
					attrs_,
					content_) : $elm$html$Html$text('');
			});
	});
var $terezka$elm_charts$Chart$Attributes$x2 = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{
				ii: $elm$core$Maybe$Just(v)
			});
	};
};
var $terezka$elm_charts$Chart$Attributes$y2 = function (v) {
	return function (config) {
		return _Utils_update(
			config,
			{
				il: $elm$core$Maybe$Just(v)
			});
	};
};
var $author$project$App$Metrics$View$viewChart = function (model) {
	var dayPerc = function () {
		var _v2 = model.fK;
		if (!_v2.$) {
			var tCs = _v2.a;
			return A2(
				$elm$core$List$filter,
				function (_v3) {
					var d = _v3.a;
					return d <= 13;
				},
				$author$project$Models$Metric$getPercentileRangeForMetrics(tCs));
		} else {
			return _List_Nil;
		}
	}();
	var count = $elm$core$List$length(dayPerc);
	return A2(
		$terezka$elm_charts$Chart$chart,
		_List_fromArray(
			[
				$terezka$elm_charts$Chart$Attributes$height(20),
				$terezka$elm_charts$Chart$Attributes$width(280),
				$terezka$elm_charts$Chart$Attributes$domain(
				_List_fromArray(
					[
						A2($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$exactly),
						A2($terezka$elm_charts$Chart$Attributes$highest, 2, $terezka$elm_charts$Chart$Attributes$exactly)
					])),
				$terezka$elm_charts$Chart$Attributes$range(
				_List_fromArray(
					[
						A2($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$exactly),
						A2($terezka$elm_charts$Chart$Attributes$highest, 28, $terezka$elm_charts$Chart$Attributes$exactly)
					])),
				$terezka$elm_charts$Chart$Attributes$margin(
				{gg: 0, g0: 0, hp: 0, h0: 0})
			]),
		_List_fromArray(
			[
				$terezka$elm_charts$Chart$list(
				function () {
					var heatmapItem = function (_v0) {
						var day = _v0.a;
						var perc = _v0.b;
						var y = 0;
						var offset = count * 2;
						var x = offset - (day * 2);
						var color = $author$project$App$Metrics$View$percentileToColor(perc);
						var makeRectangle = function (p) {
							return A2(
								$terezka$elm_charts$Chart$Svg$rect,
								p,
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$x1(x),
										$terezka$elm_charts$Chart$Attributes$x2(x + 2),
										$terezka$elm_charts$Chart$Attributes$y1(y),
										$terezka$elm_charts$Chart$Attributes$y2(y + 2),
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$border('white')
									]));
						};
						return $terezka$elm_charts$Chart$custom(
							{
								gk: color,
								gp: {ig: x, f$: y},
								gH: A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.f$;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$fromFloat,
										function (v) {
											return v + ' C°';
										})),
								g7: 'Completed Tasks',
								hg: {ih: x, ii: x + 2, ik: y, il: y + 2},
								hn: makeRectangle
							});
					};
					return A2($elm$core$List$map, heatmapItem, dayPerc);
				}()),
				A2(
				$terezka$elm_charts$Chart$each,
				model.eA,
				F2(
					function (_v1, item) {
						return _List_fromArray(
							[
								A4(
								$terezka$elm_charts$Chart$tooltip,
								item,
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$center,
										$terezka$elm_charts$Chart$Attributes$offset(0),
										$terezka$elm_charts$Chart$Attributes$onTopOrBottom
									]),
								_List_Nil,
								_List_Nil)
							]);
					}))
			]));
};
var $author$project$App$Metrics$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '280px'),
				A2($elm$html$Html$Attributes$style, 'padding-top', '10px'),
				A2($elm$html$Html$Attributes$style, 'padding-bottom', '5px')
			]),
		_List_fromArray(
			[
				$author$project$App$Metrics$View$viewChart(model)
			]));
};
var $author$project$App$Project$View$viewStat = F2(
	function (sType, sValue) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$body2,
					A2($elm$html$Html$Attributes$style, 'margin-right', '3px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', 'grey')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(sType + ': ')
						])),
					$elm$html$Html$text(sValue)
				]));
	});
var $author$project$App$Project$View$viewStats = F2(
	function (model, projectId) {
		var viewS = function (_v0) {
			var taskCount = _v0.hF;
			var taskDoneCount = _v0.hH;
			return A2(
				$author$project$App$Project$View$viewStat,
				'remaining tasks',
				$elm$core$String$fromInt(taskCount - taskDoneCount));
		};
		var viewM = function (mM) {
			return A2(
				$elm$html$Html$map,
				$author$project$App$Project$Messages$MetricMsg(projectId),
				$author$project$App$Metrics$View$view(mM));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$body2,
					A2($elm$html$Html$Attributes$style, 'font-weight', '400')
				]),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						viewS,
						A2($elm$core$Dict$get, projectId, model.fb)),
						A2(
						$elm$core$Maybe$map,
						viewM,
						A2($elm$core$Dict$get, projectId, model.eP))
					])));
	});
var $author$project$App$Project$View$viewProjectCard = F2(
	function (model, project) {
		var config = A2(
			$aforemny$material_components_web_elm$Material$Card$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '10px 10px 10px 10px'),
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '10px'),
					A2($elm$html$Html$Attributes$style, 'margin-right', 'auto'),
					A2($elm$html$Html$Attributes$style, 'margin-left', 'auto'),
					A2($elm$html$Html$Attributes$style, 'max-width', '340px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '340px')
				]),
			A2(
				$aforemny$material_components_web_elm$Material$Card$setOnClick,
				$author$project$App$Project$Messages$Chose(project),
				A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config)));
		var cardTitle = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Typography$body1,
					A2($elm$html$Html$Attributes$style, 'font-weight', '500')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(project.hS)
				]));
		return A2(
			$aforemny$material_components_web_elm$Material$Card$card,
			config,
			{
				f4: $elm$core$Maybe$Nothing,
				gf: _Utils_Tuple2(
					$aforemny$material_components_web_elm$Material$Card$block(cardTitle),
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Card$block(
							A2($author$project$App$Project$View$viewStats, model, project.eC))
						]))
			});
	});
var $author$project$App$Project$View$viewProjectCards = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$App$Project$View$viewProjectCard(model),
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.eC;
				},
				model.fd)));
};
var $author$project$App$Project$View$view = function (model) {
	var content = function () {
		var _v0 = model.fa;
		if (_v0.$ === 1) {
			return $author$project$App$Project$View$viewProjectCards(model);
		} else {
			return $author$project$App$Project$View$viewProject(model);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$dir('auto')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Models$Social$PUs = function (a) {
	return {$: 0, a: a};
};
var $author$project$Models$Social$Supporter = 0;
var $author$project$App$Social$Messages$PostReaction = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $aforemny$material_components_web_elm$Material$Card$actions = function (_v0) {
	var buttons = _v0.d1;
	var icons = _v0.eB;
	return {d1: buttons, cF: false, eB: icons};
};
var $author$project$Models$Social$getUserProject = F3(
	function (uId, pId, ups) {
		var uPFilter = function (_v7) {
			var user = _v7.fT;
			return _Utils_eq(user.eC, uId);
		};
		var uFilter = function (_v6) {
			var id = _v6.eC;
			return _Utils_eq(id, uId);
		};
		var pFilter = function (_v5) {
			var id = _v5.eC;
			return _Utils_eq(id, pId);
		};
		var getTheOne = F2(
			function (filterFunc, values) {
				return $elm$core$List$head(
					A2($elm$core$List$filter, filterFunc, values));
			});
		if (!ups.$) {
			var projectedUsers = ups.a;
			var _v1 = A2(getTheOne, uPFilter, projectedUsers);
			if (!_v1.$) {
				var user = _v1.a.fT;
				var projects = _v1.a.fd;
				var _v2 = A2(getTheOne, pFilter, projects);
				if (!_v2.$) {
					var project = _v2.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(user, project));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			var _v3 = ups.a;
			var users = _v3.a;
			var projects = _v3.b;
			var _v4 = _Utils_Tuple2(
				A2(getTheOne, uFilter, users),
				A2(getTheOne, pFilter, projects));
			if ((!_v4.a.$) && (!_v4.b.$)) {
				var user = _v4.a.a;
				var project = _v4.b.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(user, project));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $aforemny$material_components_web_elm$Material$Card$Icon = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Card$icon = F2(
	function (_v0, icon_) {
		var iconButtonConfig = _v0;
		return A2(
			$aforemny$material_components_web_elm$Material$IconButton$iconButton,
			_Utils_update(
				iconButtonConfig,
				{
					ce: A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('mdc-card__action'),
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class('mdc-card__action--icon'),
							iconButtonConfig.ce))
				}),
			icon_);
	});
var $author$project$App$Social$View$boldedSpan = function (txt) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'font-weight', '500')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(txt)
			]));
};
var $author$project$App$Social$View$singularOrPlural = F2(
	function (count, _v0) {
		var article = _v0.a;
		var singular = _v0.b;
		var plural = _v0.c;
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			(count === 1) ? _List_fromArray(
				[
					$elm$html$Html$text(article + (' ' + singular))
				]) : _List_fromArray(
				[
					$author$project$App$Social$View$boldedSpan(
					$elm$core$String$fromInt(count)),
					$elm$html$Html$text(' ' + plural)
				]));
	});
var $author$project$App$Social$View$nTasks = function (count) {
	return A2(
		$author$project$App$Social$View$singularOrPlural,
		count,
		_Utils_Tuple3('a', 'task', 'tasks'));
};
var $author$project$App$Social$View$viewReactionMsgCore = F7(
	function (projectStats, projectId, addressee, action, firstName, title, tCount) {
		var viewStats = function (_v13) {
			var taskCount = _v13.hF;
			var taskDoneCount = _v13.hH;
			var toGoPart = function () {
				var toGo = taskCount - taskDoneCount;
				return (toGo > 3) ? ($elm$core$String$fromInt(toGo) + ' more to go.)') : ((toGo > 0) ? ('only ' + ($elm$core$String$fromInt(toGo) + ' more to go!)')) : 'zero to go!)');
			}();
			return ' (' + ($elm$core$String$fromInt(taskDoneCount) + (' done, ' + toGoPart));
		};
		var proj = $author$project$App$Social$View$boldedSpan(title);
		var name = $author$project$App$Social$View$boldedSpan(firstName);
		var count = $author$project$App$Social$View$boldedSpan(
			$elm$core$String$fromInt(tCount));
		var _v0 = _Utils_Tuple2(action, addressee);
		if (!_v0.b) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has started working on '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in project '),
							proj,
							$elm$html$Html$text('!')
						]);
				case 1:
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has stoppeed working on '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in project '),
							proj,
							$elm$html$Html$text('!')
						]);
				default:
					var _v9 = _v0.a;
					var _v10 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has completed '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in project '),
							proj,
							$elm$html$Html$text('!')
						]);
			}
		} else {
			switch (_v0.a) {
				case 0:
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has given you kudos for starting working on '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in '),
							proj,
							$elm$html$Html$text('!')
						]);
				case 1:
					var _v7 = _v0.a;
					var _v8 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has wished you a restful pause, as your have stopped working on '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in '),
							proj,
							$elm$html$Html$text('!')
						]);
				default:
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					return _List_fromArray(
						[
							name,
							$elm$html$Html$text(' has given you kudos for completing '),
							$author$project$App$Social$View$nTasks(tCount),
							$elm$html$Html$text(' in '),
							proj,
							$elm$html$Html$text('!')
						]);
			}
		}
	});
var $author$project$App$Social$View$viewReactionCard = F4(
	function (projectStats, addr, uPSource, _v0) {
		var logIds = _v0.eM;
		var taskIds = _v0.hJ;
		var ownerId = _v0.e0;
		var projectId = _v0.de;
		var action = _v0.f3;
		var watcherId = _v0.ib;
		var userId = function () {
			if (!addr) {
				return ownerId;
			} else {
				return watcherId;
			}
		}();
		var iconButtonConfig = function (reaction) {
			return A2(
				$aforemny$material_components_web_elm$Material$IconButton$setOnClick,
				A2($author$project$App$Social$Messages$PostReaction, logIds, reaction),
				$aforemny$material_components_web_elm$Material$IconButton$config);
		};
		var makeIcon = function (reaction) {
			return A2(
				$aforemny$material_components_web_elm$Material$Card$icon,
				iconButtonConfig(reaction),
				$aforemny$material_components_web_elm$Material$IconButton$icon(reaction));
		};
		var count = $elm$core$Set$size(taskIds);
		var content = F2(
			function (_v3, _v4) {
				var firstName = _v3.er;
				var title = _v4.hS;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$Typography$body2]),
					A7($author$project$App$Social$View$viewReactionMsgCore, projectStats, projectId, addr, action, firstName, title, count));
			});
		var cardConfig = A2(
			$aforemny$material_components_web_elm$Material$Card$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '5px 15px 5px 15px'),
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '5px')
				]),
			A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
		var actions = $aforemny$material_components_web_elm$Material$Card$actions(
			{
				d1: _List_Nil,
				eB: _List_fromArray(
					[
						makeIcon('thumb_up')
					])
			});
		var makeCard = function (_v2) {
			var user = _v2.a;
			var project = _v2.b;
			return A2(
				$aforemny$material_components_web_elm$Material$Card$card,
				cardConfig,
				{
					f4: function () {
						if (!addr) {
							return $elm$core$Maybe$Just(actions);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}(),
					gf: _Utils_Tuple2(
						$aforemny$material_components_web_elm$Material$Card$block(
							A2(content, user, project)),
						_List_Nil)
				});
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Components$default,
			A2(
				$elm$core$Maybe$map,
				makeCard,
				A3($author$project$Models$Social$getUserProject, userId, projectId, uPSource)));
	});
var $author$project$App$Social$View$viewReactionOpportunities = function (_v0) {
	var supporterSocial = _v0.dz;
	var supporterSummaries = _v0.fF;
	if (!supporterSocial.$) {
		var projectedUsers = supporterSocial.a.fc;
		var projectStats = supporterSocial.a.fb;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A3(
					$author$project$App$Social$View$viewReactionCard,
					projectStats,
					0,
					$author$project$Models$Social$PUs(projectedUsers)),
				supporterSummaries));
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$Models$Social$Supportee = 1;
var $author$project$Models$Social$UsPs = function (a) {
	return {$: 1, a: a};
};
var $author$project$App$Social$View$viewSupporteeSocial = function (_v0) {
	var supporteeSocial = _v0.dy;
	var supporteeSummaries = _v0.fE;
	if (!supporteeSocial.$) {
		var watcherUsers = supporteeSocial.a.id;
		var projects = supporteeSocial.a.fd;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A3(
					$author$project$App$Social$View$viewReactionCard,
					_List_Nil,
					1,
					$author$project$Models$Social$UsPs(
						_Utils_Tuple2(watcherUsers, projects))),
				supporteeSummaries));
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$App$Social$Messages$PutWatcherRSVP = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$App$Social$View$viewInviteCard = F2(
	function (pUs, _v0) {
		var id = _v0.eC;
		var ownerId = _v0.e0;
		var projectId = _v0.de;
		var cardConfig = A2(
			$aforemny$material_components_web_elm$Material$Card$setAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '15px 15px 5px 15px'),
					A2($elm$html$Html$Attributes$style, 'margin-bottom', '5px')
				]),
			A2($aforemny$material_components_web_elm$Material$Card$setOutlined, true, $aforemny$material_components_web_elm$Material$Card$config));
		var buttonConfig = function (rsvp) {
			return A2(
				$aforemny$material_components_web_elm$Material$Button$setOnClick,
				A2($author$project$App$Social$Messages$PutWatcherRSVP, id, rsvp),
				$aforemny$material_components_web_elm$Material$Button$config);
		};
		var boldStyle = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'font-weight', '500')
			]);
		var content = F2(
			function (_v2, _v3) {
				var firstName = _v2.er;
				var title = _v3.hS;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[$aforemny$material_components_web_elm$Material$Typography$body2]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							boldStyle,
							_List_fromArray(
								[
									$elm$html$Html$text(firstName)
								])),
							$elm$html$Html$text(' has invited you to cheer them for their progress'),
							$elm$html$Html$text(' with the project '),
							A2(
							$elm$html$Html$span,
							boldStyle,
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							$elm$html$Html$text('.')
						]));
			});
		var actions = $elm$core$Maybe$Just(
			$aforemny$material_components_web_elm$Material$Card$actions(
				{
					d1: _List_fromArray(
						[
							A2(
							$aforemny$material_components_web_elm$Material$Card$button,
							buttonConfig(true),
							'Accept'),
							A2(
							$aforemny$material_components_web_elm$Material$Card$button,
							buttonConfig(false),
							'Decline')
						]),
					eB: _List_Nil
				}));
		var makeCard = function (_v1) {
			var user = _v1.a;
			var project = _v1.b;
			return A2(
				$aforemny$material_components_web_elm$Material$Card$card,
				cardConfig,
				{
					f4: actions,
					gf: _Utils_Tuple2(
						$aforemny$material_components_web_elm$Material$Card$block(
							A2(content, user, project)),
						_List_Nil)
				});
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Components$default,
			A2(
				$elm$core$Maybe$map,
				makeCard,
				A3(
					$author$project$Models$Social$getUserProject,
					ownerId,
					projectId,
					$author$project$Models$Social$PUs(pUs))));
	});
var $author$project$App$Social$View$viewWatcherInvites = function (_v0) {
	var supporterSocial = _v0.dz;
	if (!supporterSocial.$) {
		var projectedUsers = supporterSocial.a.fc;
		var watcherProjects = supporterSocial.a.ic;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$App$Social$View$viewInviteCard(projectedUsers),
				watcherProjects));
	} else {
		return $author$project$Components$default;
	}
};
var $author$project$App$Social$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin-left', '35px'),
				A2($elm$html$Html$Attributes$style, 'margin-right', '35px'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '20px')
			]),
		_List_fromArray(
			[
				$author$project$App$Social$View$viewWatcherInvites(model),
				$author$project$App$Social$View$viewReactionOpportunities(model),
				$author$project$App$Social$View$viewSupporteeSocial(model)
			]));
};
var $author$project$App$Messages$ToggleDrawer = {$: 2};
var $aforemny$material_components_web_elm$Material$TopAppBar$alignStart = $elm$html$Html$Attributes$class('mdc-top-app-bar__section--align-start');
var $aforemny$material_components_web_elm$Material$TopAppBar$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$TopAppBar$config = {ce: _List_Nil, ct: false, cA: false};
var $aforemny$material_components_web_elm$Material$TopAppBar$navigationIcon = $elm$html$Html$Attributes$class('mdc-top-app-bar__navigation-icon');
var $aforemny$material_components_web_elm$Material$TopAppBar$Regular = 0;
var $aforemny$material_components_web_elm$Material$TopAppBar$denseCs = function (_v0) {
	var dense = _v0.ct;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-top-app-bar--dense')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TopAppBar$fixedCs = function (_v0) {
	var fixed = _v0.cA;
	return fixed ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-top-app-bar--fixed')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$TopAppBar$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-top-app-bar'));
var $aforemny$material_components_web_elm$Material$TopAppBar$variantCs = function (variant) {
	switch (variant) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--short'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--short mdc-top-app-bar--short-collapsed'));
		default:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-top-app-bar--prominent'));
	}
};
var $aforemny$material_components_web_elm$Material$TopAppBar$genericTopAppBar = F3(
	function (variant, config_, nodes) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-top-app-bar',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$TopAppBar$rootCs,
							$aforemny$material_components_web_elm$Material$TopAppBar$variantCs(variant),
							$aforemny$material_components_web_elm$Material$TopAppBar$denseCs(config_),
							$aforemny$material_components_web_elm$Material$TopAppBar$fixedCs(config_)
						])),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$regular = F2(
	function (config_, nodes) {
		return A3($aforemny$material_components_web_elm$Material$TopAppBar$genericTopAppBar, 0, config_, nodes);
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $aforemny$material_components_web_elm$Material$TopAppBar$row = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$section,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-top-app-bar__row')
					]),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$section = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$section,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-top-app-bar__section')
					]),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$IconButton$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$setDense = F2(
	function (dense, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ct: dense});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$setFixed = F2(
	function (fixed, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{cA: fixed});
	});
var $aforemny$material_components_web_elm$Material$TopAppBar$title = $elm$html$Html$Attributes$class('mdc-top-app-bar__title');
var $author$project$App$View$viewAppBar = function () {
	var menuIconConfig = A2(
		$aforemny$material_components_web_elm$Material$IconButton$setOnClick,
		$author$project$App$Messages$ToggleDrawer,
		A2(
			$aforemny$material_components_web_elm$Material$IconButton$setAttributes,
			_List_fromArray(
				[$aforemny$material_components_web_elm$Material$TopAppBar$navigationIcon]),
			$aforemny$material_components_web_elm$Material$IconButton$config));
	var barConfig = A2(
		$aforemny$material_components_web_elm$Material$TopAppBar$setAttributes,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('appbar')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$TopAppBar$setFixed,
			true,
			A2($aforemny$material_components_web_elm$Material$TopAppBar$setDense, true, $aforemny$material_components_web_elm$Material$TopAppBar$config)));
	return A2(
		$aforemny$material_components_web_elm$Material$TopAppBar$regular,
		barConfig,
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$TopAppBar$row,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$TopAppBar$section,
						_List_fromArray(
							[$aforemny$material_components_web_elm$Material$TopAppBar$alignStart]),
						_List_fromArray(
							[
								A2(
								$aforemny$material_components_web_elm$Material$IconButton$iconButton,
								menuIconConfig,
								$aforemny$material_components_web_elm$Material$IconButton$icon('menu')),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$aforemny$material_components_web_elm$Material$TopAppBar$title,
										A2($elm$html$Html$Attributes$style, 'font-weight', '600')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('taskity')
									]))
							]))
					]))
			]));
}();
var $author$project$App$Messages$SetUrl = function (a) {
	return {$: 1, a: a};
};
var $aforemny$material_components_web_elm$Material$Drawer$Modal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Drawer$Modal$config = {ce: _List_Nil, bX: $elm$core$Maybe$Nothing, c4: false};
var $aforemny$material_components_web_elm$Material$Drawer$Modal$content = F2(
	function (attributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-drawer__content'),
				attributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$Drawer$Modal$closeHandler = function (_v0) {
	var onClose = _v0.bX;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCDrawer:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $aforemny$material_components_web_elm$Material$Drawer$Modal$modalCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-drawer--modal'));
var $aforemny$material_components_web_elm$Material$Drawer$Modal$openProp = function (_v0) {
	var open = _v0.c4;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Drawer$Modal$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-drawer'));
var $aforemny$material_components_web_elm$Material$Drawer$Modal$drawer = F2(
	function (config_, nodes) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-drawer',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Drawer$Modal$rootCs,
							$aforemny$material_components_web_elm$Material$Drawer$Modal$modalCs,
							$aforemny$material_components_web_elm$Material$Drawer$Modal$openProp(config_),
							$aforemny$material_components_web_elm$Material$Drawer$Modal$closeHandler(config_)
						])),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$Drawer$Modal$header = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-drawer__header'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$Drawer$Modal$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Drawer$Modal$setOnClose = F2(
	function (onClose, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bX: $elm$core$Maybe$Just(onClose)
			});
	});
var $aforemny$material_components_web_elm$Material$Drawer$Modal$setOpen = F2(
	function (open, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{c4: open});
	});
var $aforemny$material_components_web_elm$Material$Typography$caption = $elm$html$Html$Attributes$class('mdc-typography--caption');
var $author$project$App$View$viewDrawerHeader = function (model) {
	var _v0 = model.fT.fT;
	if (!_v0.$) {
		var firstName = _v0.a.er;
		var email = _v0.a.gB;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$aforemny$material_components_web_elm$Material$Typography$body1]),
				_List_fromArray(
					[
						$elm$html$Html$text(firstName)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$aforemny$material_components_web_elm$Material$Typography$caption]),
				_List_fromArray(
					[
						$elm$html$Html$text(email)
					]))
			]);
	} else {
		return _List_Nil;
	}
};
var $aforemny$material_components_web_elm$Material$List$listGroupCs = $elm$html$Html$Attributes$class('mdc-deprecated-list-group');
var $aforemny$material_components_web_elm$Material$List$group = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2($elm$core$List$cons, $aforemny$material_components_web_elm$Material$List$listGroupCs, additionalAttributes),
			nodes);
	});
var $author$project$Models$App$Projects = function (a) {
	return {$: 2, a: a};
};
var $author$project$App$View$projectToItem = function (project) {
	var title = project.hS;
	return {
		gQ: $elm$core$Maybe$Nothing,
		fR: title,
		h5: $author$project$Models$App$Projects(
			$elm$core$Maybe$Just(project))
	};
};
var $aforemny$material_components_web_elm$Material$List$setDense = F2(
	function (dense, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ct: dense});
	});
var $aforemny$material_components_web_elm$Material$List$listGroupSubheaderCs = $elm$html$Html$Attributes$class('mdc-deprecated-list-group__subheader');
var $aforemny$material_components_web_elm$Material$List$subheader = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$span,
			A2($elm$core$List$cons, $aforemny$material_components_web_elm$Material$List$listGroupSubheaderCs, additionalAttributes),
			nodes);
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$App$View$viewAddProject = function () {
	var msg = $author$project$App$Messages$ProjectMsg(
		$author$project$App$Project$Messages$ProjectMsg($author$project$App$Project$Messages$Add));
	var icon = $aforemny$material_components_web_elm$Material$IconButton$icon('add');
	var config = A2(
		$aforemny$material_components_web_elm$Material$IconButton$setAttributes,
		_List_fromArray(
			[$aforemny$material_components_web_elm$Material$Theme$primary]),
		A2($aforemny$material_components_web_elm$Material$IconButton$setOnClick, msg, $aforemny$material_components_web_elm$Material$IconButton$config));
	return A2($aforemny$material_components_web_elm$Material$IconButton$iconButton, config, icon);
}();
var $author$project$App$View$viewProjectsInDrawer = function (model) {
	var listItemDefault = A2($aforemny$material_components_web_elm$Material$List$Item$listItem, $aforemny$material_components_web_elm$Material$List$Item$config, _List_Nil);
	var listConfig = A2(
		$aforemny$material_components_web_elm$Material$List$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding-left', '10px')
			]),
		A2($aforemny$material_components_web_elm$Material$List$setDense, true, $aforemny$material_components_web_elm$Material$List$config));
	var items = A2(
		$elm$core$List$map,
		$author$project$App$View$projectToItem,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.eC;
			},
			model.fa.fd));
	var itemConfig = function (url) {
		var setSelected = _Utils_eq(model.h5, url) ? $elm$core$Maybe$Just($aforemny$material_components_web_elm$Material$List$Item$activated) : $elm$core$Maybe$Nothing;
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$setOnClick,
			$author$project$App$Messages$SetUrl(url),
			A2($aforemny$material_components_web_elm$Material$List$Item$setSelected, setSelected, $aforemny$material_components_web_elm$Material$List$Item$config));
	};
	var listItem = function (_v0) {
		var url = _v0.h5;
		var txt = _v0.fR;
		return A2(
			$aforemny$material_components_web_elm$Material$List$Item$listItem,
			itemConfig(url),
			_List_fromArray(
				[
					$elm$html$Html$text(txt)
				]));
	};
	var projectTitles = A3(
		$aforemny$material_components_web_elm$Material$List$list,
		listConfig,
		A2(
			$elm$core$Maybe$withDefault,
			listItemDefault,
			A2(
				$elm$core$Maybe$map,
				listItem,
				$elm$core$List$head(items))),
		A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[listItemDefault]),
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$map(listItem),
				$elm$core$List$tail(items))));
	return A2(
		$aforemny$material_components_web_elm$Material$List$group,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$aforemny$material_components_web_elm$Material$List$subheader,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '90%'),
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
						A2($elm$html$Html$Attributes$style, 'justify-content', 'space-between')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Projects'),
						A2(
						$aforemny$material_components_web_elm$Material$List$Item$meta,
						_List_Nil,
						_List_fromArray(
							[$author$project$App$View$viewAddProject]))
					])),
				projectTitles
			]));
};
var $author$project$App$Messages$FlipSocialSwitch = {$: 3};
var $aforemny$material_components_web_elm$Material$FormField$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$FormField$config = {ce: _List_Nil, cg: false, cC: $elm$core$Maybe$Nothing, g_: $elm$core$Maybe$Nothing, c2: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$Switch$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Switch$config = {ce: _List_Nil, by: false, a6: false, c1: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$FormField$alignEndCs = function (_v0) {
	var alignEnd = _v0.cg;
	return alignEnd ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-form-field--align-end')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$FormField$clickHandler = function (_v0) {
	var onClick = _v0.c2;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $aforemny$material_components_web_elm$Material$FormField$forAttr = function (_v0) {
	var _for = _v0.cC;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$for, _for);
};
var $aforemny$material_components_web_elm$Material$FormField$labelElt = function (config_) {
	var label = config_.g_;
	return A2(
		$elm$html$Html$label,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$FormField$forAttr(config_),
					$aforemny$material_components_web_elm$Material$FormField$clickHandler(config_)
				])),
		_List_fromArray(
			[
				$elm$html$Html$text(
				A2($elm$core$Maybe$withDefault, '', label))
			]));
};
var $aforemny$material_components_web_elm$Material$FormField$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-form-field'));
var $aforemny$material_components_web_elm$Material$FormField$formField = F2(
	function (config_, nodes) {
		var additionalAttributes = config_.ce;
		return A3(
			$elm$html$Html$node,
			'mdc-form-field',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$FormField$rootCs,
							$aforemny$material_components_web_elm$Material$FormField$alignEndCs(config_)
						])),
				additionalAttributes),
			_Utils_ap(
				nodes,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$FormField$labelElt(config_)
					])));
	});
var $aforemny$material_components_web_elm$Material$FormField$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ce: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$Switch$setChecked = F2(
	function (checked, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{by: checked});
	});
var $aforemny$material_components_web_elm$Material$FormField$setLabel = F2(
	function (label, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{g_: label});
	});
var $aforemny$material_components_web_elm$Material$Switch$setOnChange = F2(
	function (onChange, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c1: $elm$core$Maybe$Just(onChange)
			});
	});
var $aforemny$material_components_web_elm$Material$FormField$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				c2: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Switch$checkedProp = function (_v0) {
	var checked = _v0.by;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'checked',
			$elm$json$Json$Encode$bool(checked)));
};
var $aforemny$material_components_web_elm$Material$Switch$disabledProp = function (_v0) {
	var disabled = _v0.a6;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $aforemny$material_components_web_elm$Material$Switch$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-switch'));
var $aforemny$material_components_web_elm$Material$Switch$changeHandler = function (_v0) {
	var onChange = _v0.c1;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('change'),
			$elm$json$Json$Decode$succeed),
		onChange);
};
var $aforemny$material_components_web_elm$Material$Switch$checkboxTypeAttr = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$type_('checkbox'));
var $aforemny$material_components_web_elm$Material$Switch$nativeControlCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-switch__native-control'));
var $aforemny$material_components_web_elm$Material$Switch$switchRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'switch'));
var $aforemny$material_components_web_elm$Material$Switch$nativeControlElt = function (config_) {
	return A2(
		$elm$html$Html$input,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Switch$nativeControlCs,
					$aforemny$material_components_web_elm$Material$Switch$checkboxTypeAttr,
					$aforemny$material_components_web_elm$Material$Switch$switchRoleAttr,
					$aforemny$material_components_web_elm$Material$Switch$checkedProp(config_),
					$aforemny$material_components_web_elm$Material$Switch$changeHandler(config_)
				])),
		_List_Nil);
};
var $aforemny$material_components_web_elm$Material$Switch$thumbElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-switch__thumb')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Switch$thumbUnderlayElt = function (config_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-switch__thumb-underlay')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Switch$thumbElt,
				$aforemny$material_components_web_elm$Material$Switch$nativeControlElt(config_)
			]));
};
var $aforemny$material_components_web_elm$Material$Switch$trackElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-switch__track')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Switch$switch = function (config_) {
	var additionalAttributes = config_.ce;
	return A3(
		$elm$html$Html$node,
		'mdc-switch',
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Switch$rootCs,
						$aforemny$material_components_web_elm$Material$Switch$checkedProp(config_),
						$aforemny$material_components_web_elm$Material$Switch$disabledProp(config_)
					])),
			additionalAttributes),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Switch$trackElt,
				$aforemny$material_components_web_elm$Material$Switch$thumbUnderlayElt(config_)
			]));
};
var $author$project$App$View$viewSocialSwitch = function (socialMode) {
	var switchConfig = function (sM) {
		return A2(
			$aforemny$material_components_web_elm$Material$Switch$setOnChange,
			$author$project$App$Messages$FlipSocialSwitch,
			A2($aforemny$material_components_web_elm$Material$Switch$setChecked, sM, $aforemny$material_components_web_elm$Material$Switch$config));
	};
	var formFieldConfig = A2(
		$aforemny$material_components_web_elm$Material$FormField$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'bottom', '1rem'),
				A2($elm$html$Html$Attributes$style, 'left', '1rem')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$FormField$setOnClick,
			$author$project$App$Messages$FlipSocialSwitch,
			A2(
				$aforemny$material_components_web_elm$Material$FormField$setLabel,
				$elm$core$Maybe$Just('Social mode'),
				$aforemny$material_components_web_elm$Material$FormField$config)));
	var makeSwitch = function (sM) {
		return A2(
			$aforemny$material_components_web_elm$Material$FormField$formField,
			formFieldConfig,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Switch$switch(
					switchConfig(sM))
				]));
	};
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Components$default,
		A2($elm$core$Maybe$map, makeSwitch, socialMode));
};
var $author$project$App$View$viewDrawer = function (model) {
	var itemConfig = A2(
		$aforemny$material_components_web_elm$Material$List$Item$setOnClick,
		$author$project$App$Messages$SetUrl($author$project$Models$App$Auth),
		A2(
			$aforemny$material_components_web_elm$Material$List$Item$setSelected,
			$elm$core$Maybe$Just($aforemny$material_components_web_elm$Material$List$Item$activated),
			$aforemny$material_components_web_elm$Material$List$Item$config));
	var content = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				_Utils_eq(
				model.fz,
				$elm$core$Maybe$Just(true)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				$author$project$App$View$viewProjectsInDrawer(model)),
				$elm$core$Maybe$Just(
				$author$project$App$View$viewSocialSwitch(model.fz))
			]));
	var config = A2(
		$aforemny$material_components_web_elm$Material$Drawer$Modal$setAttributes,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'z-index', '100'),
				$elm$html$Html$Attributes$id('drawer')
			]),
		A2(
			$aforemny$material_components_web_elm$Material$Drawer$Modal$setOnClose,
			$author$project$App$Messages$ToggleDrawer,
			A2($aforemny$material_components_web_elm$Material$Drawer$Modal$setOpen, model.eg, $aforemny$material_components_web_elm$Material$Drawer$Modal$config)));
	var body = _List_fromArray(
		[
			A2(
			$aforemny$material_components_web_elm$Material$Drawer$Modal$header,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin-top', '15px')
				]),
			$author$project$App$View$viewDrawerHeader(model)),
			A2($aforemny$material_components_web_elm$Material$Drawer$Modal$content, _List_Nil, content)
		]);
	return A2($aforemny$material_components_web_elm$Material$Drawer$Modal$drawer, config, body);
};
var $author$project$App$View$viewLoggedIn = function (model) {
	var contentPicker = _Utils_eq(
		model.fz,
		$elm$core$Maybe$Just(true)) ? A2(
		$elm$html$Html$map,
		$author$project$App$Messages$SocialMsg,
		$author$project$App$Social$View$view(model.fy)) : A2(
		$elm$html$Html$map,
		$author$project$App$Messages$ProjectMsg,
		$author$project$App$Project$View$view(model.fa));
	var viewContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				$elm$html$Html$Attributes$id('main')
			]),
		_List_fromArray(
			[contentPicker]));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$App$View$viewAppBar,
				A2(
				$elm$html$Html$div,
				$author$project$App$View$belowBarAttrs,
				_List_fromArray(
					[
						$author$project$App$View$viewDrawer(model),
						A2($aforemny$material_components_web_elm$Material$Drawer$Modal$scrim, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$div,
						$author$project$App$View$contentAttrs,
						_List_fromArray(
							[viewContent]))
					]))
			]));
};
var $author$project$App$View$view = function (model) {
	var _v0 = model.h5;
	if (_v0.$ === 1) {
		return A2(
			$elm$html$Html$map,
			$author$project$App$Messages$UserMsg,
			$author$project$App$User$View$view(model.fT));
	} else {
		return $author$project$App$View$viewLoggedIn(model);
	}
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{
		gV: function (_v0) {
			return $author$project$App$Update$init;
		},
		hD: $author$project$App$Subscriptions$subscriptions,
		h3: $author$project$App$Update$update,
		h9: $author$project$App$View$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));